import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Text from "../utilities/Text";
import Avatar from "@material-ui/core/Avatar";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Grid from "@material-ui/core/Grid";
import Badge from "@material-ui/core/Badge";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
//import { getUnreadNotificationsCount } from "../utilities/api";
import { useHistory } from "react-router-dom";
import { AccountsData, GlobalData, UserData } from "../Contexts";
import { ReactComponent as KMeneuIcon } from "../img/menunav.svg";
import { ReactComponent as LogoIcon } from "../img/logoSmall.svg";
import LangSwitch from "./LangSwitch";
import { ReactComponent as ExpandIcon } from "../img/arrowExpand.svg";
import { ReactComponent as CloseIcon } from "../img/arrowClose.svg";
import { Dialog } from "@material-ui/core";
import MultiDialog from "./MultiDialog";
import useNotificationCount from "../hooks/useNotification";
import { useSnapshot } from "valtio";
import { globalState } from "../App";

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
    // display: "flex",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    width: "100%",
    backgroundColor: "#004E5E",
    color: "#FFF",
  },
  drawerText: {
    textAlign: "center",
  },
  bigBadge: {
    left: "-35px",
    width: "32px",
    height: "32px",
    borderRadius: "45px",
    marginLeft: "16px",
    fontSize: "16px",
    fontWeight: "400",
  },
  dialogPaper: {
    borderRadius: "16px",
    position: "fixed",
    top: 50,
    // insetInlineStart: 30,
  },
  desktop: {
    [theme.breakpoints.up("md")]: {
      marginInlineStart: "300px",
      marginInlineEnd: "300px",
      //maxWidth: "1140px",
      marginTop: "72px",
    },
  },
}));

const captionStyle = {
  // margin: "20px 0px auto",
  fontWeight: 400,
  fontSize: "16px",
};
export default function ButtonAppBar() {
  const [userCtx, setUserCtx] = React.useContext(UserData);
  const [userAccounts, setUserAccounts] = React.useContext(AccountsData);
  const { language, setLanguage } = React.useContext(GlobalData);
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [multi, setMulti] = React.useState(false);
  const snapshot = useSnapshot(globalState);
  useNotificationCount();

  const handleLanguage = (val) => {
    const choice = val ? "en" : "he";
    setLanguage(choice);
    window.localStorage.setItem("keshetLang", choice);
  };
  let fullName;

  const firstLetters = React.useCallback(() => {
    let avatarLetters;
    if (language === "en") {
      if (!userCtx?.user?.firstNameENG) {
        return;
      }
      avatarLetters =
        userCtx?.user?.firstNameENG.charAt(0) +
        userCtx?.user?.lastNameENG.charAt(0);
    }
    if (language === "he") {
      if (!userCtx?.user?.firstName) {
        return;
      }
      avatarLetters =
        userCtx?.user?.firstName.charAt(0) + userCtx?.user?.lastName.charAt(0);
    }

    return avatarLetters;
  });
  if (language === "he") {
    fullName = userCtx?.user?.firstName + " " + userCtx?.user?.lastName;
  } else {
    fullName = userCtx?.user?.firstNameENG + " " + userCtx?.user?.lastNameENG;
  }
  const ImgUser = userCtx?.user?.userimg;

  // React.useEffect(() => {
  //   async function fetchNotificationsCount() {
  //     const response = await getUnreadNotificationsCount();
  //     setNotificationCount(response.data);
  //   }

  //   fetchNotificationsCount();
  // }, []);

  const history = useHistory();
  // const handleClick = () => {
  //   history.push("/notifications");
  // };
  const goHome = () => {
    history.push("/");
  };

  const greeting = React.useCallback(() => {
    if (language === "he" && userCtx.user?.firstName) {
      //hebrew
      return "שלום, " + userCtx.user.firstName;
    }
    if (language === "en" && userCtx.user?.firstNameENG) {
      //english
      return "Hello, " + userCtx.user.firstNameENG;
    }
    if (language === "en" && userCtx.user?.firstName) {
      //hebrew fallback if no english name
      return "שלום, " + userCtx.user.firstName;
    }
    return "no name";
  }, [language, userCtx]);

  const handleLogout = () => {
    localStorage.getItem("userJWT") && localStorage.removeItem("userJWT");
    localStorage.getItem("userAccount") &&
      localStorage.removeItem("userAccount");
    setUserCtx(null);
    setUserAccounts(null);
    history.push("/login");
  };

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const classes = useStyles();
  // const StyledBadge = withStyles((theme) => ({
  //   badge: {
  //     right: 10,
  //     top: 13,
  //     border: `2px solid ${theme.palette.background.paper}`,
  //     padding: "0 4px",
  //   },
  // }))(Badge);

  return (
    <div className={classes.root} style={{ direction: "rtl" }}>
      <AppBar
        position="static"
        color="transparent"
        elevation={1}
        style={{
          boxShadow:
            "0px 2px 1px -1px rgba(229, 229, 229, 0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(255, 255, 255, 0.12)",
        }}
      >
        <Toolbar>
          <Grid
            container
            justify="space-between"
            direction="row"
            alignItems="center"
            wrap="nowrap"
          >
            <Grid item xs={2}>
              <LogoIcon
                style={{ cursor: "pointer" }}
                onClick={() => {
                  history.push("/");
                }}
              />
            </Grid>
            <Grid
              item
              xs={8}
              wrap="nowrap"
              style={{ opacity: greeting() === "no name" ? 0 : 1 }}
            >
              {userAccounts ? (
                <Button
                  onClick={() => setMulti((prev) => !prev)}
                  style={{ border: "0px" }}
                  variant="text"
                >
                  {/* <Avatar
                  alt="{avatarLetters}"
                  src={ImgUser}
                  style={{ backgroundColor: "#F8F8F8", color: "#004E5E" }}
                >
                  {firstLetters()}
                </Avatar> */}
                  <div
                    style={{
                      backgroundColor: "#F8F8F8",
                      height: "48px",
                      width: "80px",
                      borderRadius: "34.5px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-around",
                    }}
                  >
                    {/* <Button
                      onClick={() => setMulti((prev) => !prev)}
                      style={{
                        border: "0px",
                        padding: "0px",
                        margin: "0px",
                        minWidth: "0px",
                      }}
                      variant="text"
                    > */}
                    {multi ? <CloseIcon /> : <ExpandIcon />}
                    {/* </Button> */}
                    <Avatar
                      alt="{avatarLetters}"
                      src={ImgUser}
                      style={{
                        backgroundColor: "#FFFFFF",
                        color: "#004E5E",
                        height: "40px",
                      }}
                    >
                      {firstLetters()}
                    </Avatar>
                  </div>
                </Button>
              ) : (
                <Button
                  //onClick={handleClick}
                  style={{ border: "0px" }}
                  variant="text"
                >
                  <Avatar
                    alt="{avatarLetters}"
                    src={ImgUser}
                    style={{ backgroundColor: "#F8F8F8", color: "#004E5E" }}
                  >
                    {firstLetters()}
                  </Avatar>
                </Button>
              )}
              <Dialog
                open={multi}
                PaperProps={{ classes: { root: classes.dialogPaper } }}
                onClose={() => {
                  setMulti((prev) => !prev);
                }}
              >
                <MultiDialog
                  close={(val) => {
                    setMulti(val);
                  }}
                ></MultiDialog>
              </Dialog>
              {/* </StyledBadge> */}
              <Typography
                color="primary"
                display="inline"
                noWrap="true"
                style={captionStyle}
              >
                {greeting()}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <IconButton
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
                onClick={handleDrawerToggle}
                edge="end"
                alignSelf="flex-end"
              >
                <KMeneuIcon id="MenuIcon" />
                <Drawer
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                  open={isDrawerOpen}
                  onClose={() => setIsDrawerOpen(false)}
                >
                  <div className={classes.desktop}>
                    <List className={classes.drawer}>
                      <div
                        style={{
                          backgroundColor: "#F8F8F8",
                          width: "88px",
                          height: "88px",
                          borderRadius: "45px",
                          margin: "4vh auto 8px auto",

                          textAlign: "center",
                        }}
                      >
                        <Typography
                          variant="h2"
                          color="primary"
                          style={{ paddingTop: "22%" }}
                        >
                          {firstLetters()}
                        </Typography>
                      </div>
                      <Typography
                        variant="h5"
                        style={{ textAlign: "center", marginBottom: "32px" }}
                      >
                        {fullName}
                      </Typography>
                      <div style={{ marginBottom: "16px" }}>
                        <LangSwitch
                          switchTextWhenOn={"עב"}
                          switchTextWhenOff="EN"
                          switchWidth={75}
                          switchState={language}
                          switchValue={(val) => {
                            handleLanguage(val);
                            setIsDrawerOpen(true);
                          }}
                          style={{ direction: "ltr" }}
                        />
                      </div>
                      <ListItem id="dashboard" button onClick={goHome}>
                        <ListItemText
                          className={classes.drawerText}
                          disableTypography
                          primary={
                            <Typography
                              variant="h4"
                              style={{ color: "#FFFFFF" }}
                            >
                              <Text objectName="dashboard" page="navbar" />
                            </Typography>
                          }
                        />
                      </ListItem>
                      <div
                        style={{
                          border: "0.5px dashed #FFF",
                          margin: "16px 32px",
                          color: "#FFF",
                        }}
                      ></div>

                      <ListItem
                        button
                        onClick={() => {
                          history.push("/itrot");
                        }}
                        id="itrot"
                      >
                        {" "}
                        <ListItemText
                          className={classes.drawerText}
                          disableTypography
                          primary={
                            <Typography
                              variant="h4"
                              style={{ color: "#FFFFFF" }}
                            >
                              <Text objectName="itrot" page="navbar" />
                            </Typography>
                          }
                        />
                      </ListItem>
                      <div
                        style={{
                          border: "0.5px dashed #FFF",
                          margin: "16px 32px",
                          color: "#FFF",
                        }}
                      ></div>
                      <ListItem
                        button
                        onClick={() => {
                          history.push("/status");
                        }}
                        id="status"
                      >
                        <ListItemText
                          className={classes.drawerText}
                          disableTypography
                          primary={
                            <Typography
                              variant="h4"
                              style={{ color: "#FFFFFF" }}
                            >
                              <Text objectName="requests" page="navbar" />
                            </Typography>
                          }
                        />
                      </ListItem>
                      <div
                        style={{
                          border: "0.5px dashed #FFF",
                          margin: "16px 32px",
                          color: "#FFF",
                        }}
                      ></div>
                      <ListItem
                        button
                        onClick={() => {
                          history.push("/ngos");
                        }}
                        id="ngos"
                      >
                        <ListItemText
                          className={classes.drawerText}
                          disableTypography
                          primary={
                            <Typography
                              variant="h4"
                              style={{ color: "#FFFFFF" }}
                            >
                              <Text objectName="ngos" page="navbar" />
                            </Typography>
                          }
                        />
                      </ListItem>
                      <div
                        style={{
                          border: "0.5px dashed #FFF",
                          margin: "16px 32px",
                          color: "#FFF",
                        }}
                      ></div>
                      <ListItem
                        button
                        onClick={() => {
                          history.push("/granthistory");
                        }}
                        id="granthistory"
                      >
                        <ListItemText
                          className={classes.drawerText}
                          disableTypography
                          primary={
                            <Typography
                              variant="h4"
                              style={{ color: "#FFFFFF" }}
                            >
                              <Text objectName="history" page="navbar" />
                            </Typography>
                          }
                        />
                      </ListItem>
                      <div
                        style={{
                          border: "0.5px dashed #FFF",
                          margin: "16px 32px",
                          color: "#FFF",
                        }}
                      ></div>
                      <ListItem
                        button
                        onClick={() => {
                          history.push("/files");
                        }}
                        id="files"
                      >
                        <ListItemText
                          className={classes.drawerText}
                          disableTypography
                          primary={
                            <Typography
                              variant="h4"
                              style={{ color: "#FFFFFF" }}
                            >
                              <Text objectName="files" page="navbar" />
                            </Typography>
                          }
                        />
                      </ListItem>
                      <div
                        style={{
                          border: "0.3px dashed #FFF",
                          margin: "16px 32px",
                          color: "#FFF",
                        }}
                      ></div>
                      <ListItem
                        button
                        onClick={() => {
                          history.push("/notifications");
                        }}
                        id="notifications"
                      >
                        <ListItemText
                          className={classes.drawerText}
                          disableTypography
                          primary={
                            <Typography
                              variant="h4"
                              style={{ color: "#FFFFFF" }}
                            >
                              <Text objectName="messages" page="navbar" />
                              <Badge
                                badgeContent={
                                  snapshot.notifications?.filter(
                                    (notification) => {
                                      return !notification.isRead;
                                    }
                                  ).length || 0
                                }
                                //badgeContent={notification_count}
                                //color="secondary"
                                max={10}
                                style={{
                                  left: "-160px",
                                }}
                                classes={{ badge: classes.bigBadge }}
                              ></Badge>
                            </Typography>
                          }
                        />
                      </ListItem>
                      <div
                        style={{
                          border: "0.5px dashed #E4E4E4",
                          margin: "16px 32px",
                        }}
                      ></div>
                      <ListItem button onClick={handleLogout}>
                        <ListItemText
                          disableTypography
                          className={classes.drawerText}
                          primary={
                            <Typography
                              variant="h4"
                              style={{ color: "#FFFFFF" }}
                            >
                              <Text objectName="logout" page="navbar" />
                            </Typography>
                          }
                        />
                      </ListItem>
                      <div
                        onClick={() => {
                          setIsDrawerOpen(false);
                        }}
                        style={{
                          backgroundColor: "#004E5E",
                          width: "56px",
                          height: "56px",
                          borderRadius: "45px",
                          margin: "2vh auto",
                          // textAlign: "center",
                          color: "#FFF",
                          border: "1px solid #FFFFFF",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="h3" style={{}}>
                          X
                        </Typography>
                      </div>
                    </List>
                  </div>
                </Drawer>
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
}
