import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import Collapse from "@material-ui/core/Collapse";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { UserData } from "../Contexts";
import { postNewGrant } from "../utilities/api";
import { ReactComponent as SelectIcon } from "../img/arrowDown.svg";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Text from "../utilities/Text";

const useStyles = makeStyles({
  root: {
    width: "25ch",
  },
});

const currencies = [
  {
    value: "NIS",
    label: "₪",
  },
  {
    value: "USD",
    label: "$",
  },

  {
    value: "BTC",
    label: "฿",
  },
];

const goals = [
  {
    value: 1,
    label: "מטרה 1",
  },

  {
    value: 2,
    label: "מטרה 2",
  },
  {
    value: 3,
    label: "מטרה 3",
  },
];

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

// Wanted props: ngoData, userData (userData is context for now)
// Send back to API: formData object (userData, ngoData, grantSum, grantCurrency, grantGoal, checkFounder, checkSecondFounder, checkAnon, contactName, contactPhone, contactMail, contactNotes)

export default function NewGrantDialog(props) {
  const [userCtx, setUserCtx] = React.useContext(UserData);
  const [formData, setFormData] = React.useState({
    userData: userCtx?.user,
    ngoData: props?.ngoData,
    grantSum: "",
    grantCurrency: "NIS",
    grantGoal: "",
    checkFounder: false,
    checkSecondFounder: false,
    checkAnon: false,
    contactName: "",
    contactPhone: "",
    contactMail: "",
    contactNotes: "",
  });
  const [formError, setFormError] = React.useState({
    grantSum: false,
    grantCurrency: false,
    grantGoal: false,
    checkFounder: false,
    checkSecondFounder: false,
    checkAnon: false,
    contactName: false,
    contactPhone: false,
    contactMail: false,
    contactNotes: false,
  });
  const [open, setOpen] = React.useState(false);
  //console.log(props.userData);
  const [step, setStep] = React.useState(0);
  const [stepComplete, setstepComplete] = React.useState({
    stepOne: false,
    stepTwo: false,
  });
  const [checked, setChecked] = React.useState(false);

  const handleSwitch = () => {
    setChecked((prev) => !prev);
    if (!checked) {
      setFormData({
        ...formData,
        contactName: "",
        contactPhone: "",
        contactMail: "",
        contactNotes: "",
      });
    }
  };
  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.checked });
  };

  const handleStepChange = (event) => {
    setstepComplete({ ...stepComplete, [event.target.name]: true });
  };
  // const [feedback, setFeedback] = React.useState("");

  const avatarLetters =
    userCtx?.user?.firstName.charAt(0) + userCtx?.user?.lastName.charAt(0);

  const fullName = userCtx?.user.firstName + " " + userCtx?.user.lastName;

  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleNext = () => {
    if (formData.grantSum === "") {
      setFormError({ ...formError, grantSum: true });
      return;
    }
    if (step === 0) {
      setStep(1);
      setstepComplete({ ...stepComplete, stepOne: true });
    }
    if (step === 1) {
      setStep(2);
      setstepComplete({ ...stepComplete, stepTwo: true });
    }
    if (step === 2) {
      setFormData({ ...formData, userData: props?.userData });
      console.log(props?.userData);
      console.log(formData.userData);
      console.log(formData);
      setStep(0);
      setstepComplete({ stepTwo: false, stepOne: false });
      sendNewGrant();
      handleClose();
    }
  };

  async function sendNewGrant() {
    const data = await postNewGrant(formData);
    //console.log(value);
    console.log(data);
  }
  let myForm;
  if (step === 0) {
    myForm = (
      <div>
        <div
          style={{
            justifyContent: "space-between",
            display: "flex",
          }}
        >
          <TextField
            autoFocus
            margin="dense"
            id="grantSum"
            type="text"
            value={formData.grantSum}
            placeholder="סכום המענק"
            variant="outlined"
            required
            error={formError.grantSum}
            helperText={formError.grantSum ? "לא הכנסת סכום" : ""}
            onChange={(e) => {
              setFormData((prev) => {
                return { ...prev, [e.target.id]: e.target.value };
              });
            }}
          />

          <TextField
            id="grantCurrency"
            placeholder="מטבע"
            select
            margin="dense"
            value={formData.grantCurrency}
            onChange={(e) => {
              setFormData((prev) => {
                return { ...prev, [e.target.id]: e.target.value };
              });
            }}
            SelectProps={{
              native: true,
              IconComponent: KeyboardArrowDownIcon,
            }}
            variant="outlined"
          >
            {currencies.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </TextField>
        </div>
        <div dir="ltr">
          <TextField
            placeholder={Text({ page: "donation", objectName: "otherGoal" })}
            id="grantGoal"
            select
            fullWidth
            margin="dense"
            value={formData.grantGoal}
            error={formError.grantGoal}
            helperText={formError.grantGoal ? "לא הכנסת סכום" : ""}
            onChange={(e) => {
              setFormData((prev) => {
                return { ...prev, [e.target.id]: e.target.value };
              });
            }}
            SelectProps={{
              native: true,
            }}
            variant="outlined"
          >
            <option key="" value="" disabled>
              {Text({
                page: "donation",
                objectName: "goal",
              })}{" "}
            </option>
            {goals.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </TextField>
        </div>
      </div>
    );
  }
  if (step === 1) {
    myForm = (
      <div>
        <Typography>GRANT ACKNOWLEDGEMENT</Typography>
        <Typography>*מכתב נשלח לארגון עם שם החשבון שלך, כולל:</Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.checkFounder}
              onChange={handleChange}
              id="checkFounder"
              name="checkFounder"
              color="primary"
              fullWidth
            />
          }
          label="שם המייסד עיקרי"
        />
        <Divider />
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.checkSecondFounder}
              onChange={handleChange}
              name="checkSecondFounder"
              color="primary"
              fullWidth
            />
          }
          label="שם המייסד משני"
        />
        <Divider />
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.checkAnon}
              onChange={handleChange}
              name="checkAnon"
              color="primary"
              fullWidth
            />
          }
          label={Text({ page: "donation", objectName: "anonymous" })}
        />
        <Divider />

        <Typography component="div">
          האם יש לך פרטי איש קשר בעמותה?
          <Grid component="label" container alignItems="center" spacing={1}>
            <Grid item>כן</Grid>
            <Grid item>
              <AntSwitch
                checked={checked}
                onChange={handleSwitch}
                name="checkedC"
              />
            </Grid>
            <Grid item>לא</Grid>
          </Grid>
        </Typography>
        <Collapse in={checked}>
          <Typography variant="caption">פרטי איש\אשת קשר</Typography>
          <br />
          <TextField
            margin="dense"
            id="contactName"
            type="text"
            placeholder="שם"
            variant="outlined"
            value={formData.contactName}
            onChange={(e) => {
              setFormData((prev) => {
                return { ...prev, [e.target.id]: e.target.value };
              });
            }}
          />
          <TextField
            margin="dense"
            id="contactPhone"
            type="text"
            placeholder="מספר נייד"
            variant="outlined"
            value={formData.contactPhone}
            required
            onChange={(e) => {
              setFormData((prev) => {
                return { ...prev, [e.target.id]: e.target.value };
              });
            }}
          />
          <TextField
            margin="dense"
            id="contactMail"
            type="email"
            placeholder="כתובת מייל"
            variant="outlined"
            value={formData.contactMail}
            required
            onChange={(e) => {
              setFormData((prev) => {
                return { ...prev, [e.target.id]: e.target.value };
              });
            }}
          />
          <TextField
            margin="dense"
            id="contactNotes"
            type="text"
            value={formData.contactNotes}
            placeholder="הערות לצוות קשת"
            rows="2"
            multiline
            variant="outlined"
            required
            onChange={(e) => {
              setFormData((prev) => {
                return { ...prev, [e.target.id]: e.target.value };
              });
            }}
          />
        </Collapse>
      </div>
    );
  }
  if (step === 2) {
    myForm = (
      <div>
        <div
          style={{
            justifyContent: "space-between",
            display: "flex",
          }}
        >
          <Typography>המלצת נשלחה, תודה</Typography>
        </div>
      </div>
    );
  }
  return (
    <div>
      <Button variant="contained" color="secondary" onClick={handleClickOpen}>
        לביצוע תרומה
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">לביצוע המלצה למענק</DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{ justifyContent: "center", display: "flex" }}
          >
            {" "}
          </DialogContentText>
          <Stepper activeStep={step}>
            <Step key={0} completed={stepComplete.stepOne}>
              <StepLabel>1</StepLabel>
            </Step>
            <Step key={1} completed={stepComplete.stepTwo}>
              <StepLabel>2</StepLabel>
            </Step>
          </Stepper>
          {myForm}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            ביטול
          </Button>
          <Button onClick={handleNext} color="primary">
            {step === 2 ? "סיום" : "הבא"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
