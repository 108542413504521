import React from "react";
import { Grid, Typography, Paper } from "@material-ui/core";
import Text from "../utilities/Text";

export default function HistoryCardInfo(props) {
  console.log(props.cardData);
  return (
    <Paper
      elevation={0}
      style={{
        border: "1px solid #D1E4EC",
        borderRadius: "16px",
        boxSizing: "border-box",
        padding: "24px",
        margin: "16px 8px",
      }}
    >
      <Typography variant="h4" color="primary" style={{ marginBottom: "12px" }}>
        {/* <Text objectName="grantSummary" page="donation" /> */}
        <Text objectName="moreInfo" page="history" />
      </Typography>
      {/* <div className={classes.finishCard}> */}
      <Grid container>
        <Grid item xs={5}>
          <Typography variant="body2" color="primary">
            {/* <Text objectName="organization" page="donation" /> */}
            <Text objectName="donor" page="donation" />
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography variant="h5" style={{ color: "#004E5E" }}>
            {props?.cardData.grant_acknowledgement || (
              <Text objectName="noInfo" page="history" />
            )}
          </Typography>
        </Grid>
      </Grid>
      {/* </div> */}
      <div
        style={{ borderTop: "1px dashed #E4E4E4", margin: "12px auto" }}
      ></div>

      <Grid container>
        <Grid item xs={5}>
          <Typography variant="body2" color="primary">
            <Text objectName="summaryGoal" page="donation" />
          </Typography>
        </Grid>
        <Grid item xs={7}>
          {props?.cardData.DonationGoal || (
            <Text objectName="noInfo" page="history" />
          )}
        </Grid>
      </Grid>
      <div
        style={{ borderTop: "1px dashed #E4E4E4", margin: "12px auto" }}
      ></div>
    </Paper>
  );
}
