import React from "react";
import { useLanguage } from "../../hooks";
import { ReactComponent as LogoIcon } from "../../img/IntroLogo.svg";
import { ReactComponent as LogoIconEng } from "../../img/IntroLogoEng.svg";

export default function FixedLogo() {
  const language = useLanguage();
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "35vw",
        bottom: 0,
        background: "#004e5e",
        paddingTop: "19%",
      }}
    >
      {language === "he" ? <LogoIcon /> : <LogoIconEng />}
    </div>
  );
}
