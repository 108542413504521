export function numFormatter(num) {
    if (num > 999 && num < 1000000) {
        return (num / 1000).toFixed() + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num >= 1000000) {
        return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
    } else if (num <= 999) {
        return num; // if value < 1000, nothing to do
    }
}

const colorPallet = ["#004E5E", "#58C7CE", "#EA7B7E", "#FBAF3C", "#FFB4AB", "#E9C0A8", "#65B1EC", "#F88450", "#9EA767", "#B883DB"]

export default colorPallet