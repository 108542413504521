import { initializeApp } from "firebase/app";
import {
  getAuth,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from "firebase/auth";
import React from "react";
import { globalState } from "../App";
import { getFavsNgos, getUserAccounts, getUserData } from "./api";
import axios from "axios";
const firebaseConfig = {
  apiKey: "AIzaSyCL69aLZ2F3c_YsLFoY79-VW71lcMRYRRQ",
  authDomain: "keshet2020-2d705.firebaseapp.com",
  projectId: "keshet2020-2d705",
  storageBucket: "keshet2020-2d705.appspot.com",
  messagingSenderId: "953701538970",
  appId: "1:953701538970:web:db61d6a156325e08425323",
  measurementId: "G-1Y5NBMX95N",
};

export default function initFb() {
  initializeApp(firebaseConfig);
}
