import {
  Container,
  Grid,
  Button,
  Box,
  TextField,
  Dialog,
} from "@material-ui/core";
import React from "react";
import KRadioButtons from "./KRadioButtons";
import KStepper from "./KStepper";
import NewGrantDialog from "./NewGrantDialog";
import { Card, CardContent, Typography } from "@material-ui/core";
import DonationProcess from "./DonationProcess";
import LanSwitch from "./Switch";
import KSwitch from "./KSwitch";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as KeshetApproved } from "../img/keshetApproved.svg";
import LangSwitch from "./LangSwitch";
import KRadioButtonsMultiPick from "./KRadioButtonsMultiPick";
import MultiDialog from "./MultiDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "blue",

    [theme.breakpoints.up("md")]: {
      height: "90%",
      backgroundColor: "red",
      columns: "385px 3",
      columnGap: "24px",
      margin: "auto 12vh",
    },
  },
  simple: {
    height: "350px",
    width: "384px",
    backgroundColor: "blue",
    border: "1px solid black",
    //flexGrow: 1,
    //flexBasis: 0,
  },
  dialogPaper: {
    borderRadius: "16px",
    position: "fixed",
    top: 50,
    insetInlineStart: 30,
  },
}));

export default function Testin() {
  const handleValue = (newvalue) => {
    console.log(newvalue);
  };
  const [dialog, setDialog] = React.useState(false);
  const classes = useStyles();

  return (
    <Box mx={1}>
      <Button
        onClick={() => {
          setDialog((prev) => !prev);
        }}
      >
        BLAH
      </Button>
      <Dialog
        open={dialog}
        PaperProps={{ classes: { root: classes.dialogPaper } }}
        onClose={() => {
          setDialog((prev) => !prev);
        }}
      >
        <MultiDialog open={dialog} />
      </Dialog>
      {/*<div style={{ backgroundColor: "#004E5E" }}>*/}
      <div
        style={{
          display: "flex",

          //  marginInlineStart: "auto",
        }}
      >
        <div style={{ minWidth: "10%" }}>1</div>
        <div
          style={{
            display: "flex",
            flexWrap: "nowrap",
          }}
        >
          <div
            style={{
              //maxWidth: "1040px",
              display: "flex",
              flexDirection: "column",
              //flexGrow: 2,
              height: "150vh",
              flexWrap: "wrap",

              //justifyContent: "center",
              gap: "5px",
              //alignContent: "center",
              //flexBasis: "90%",
              //maxWidth: "800px",
            }}
          >
            <div
              className={classes.simple}
              //style={{ height: "800px", flexGrow: 1 }}
            >
              <Card>
                <CardContent>
                  <Typography variant="h4">--1-כרטיס עמותה פה---</Typography>
                  <div style={{ border: "1px dashed #161616" }}></div>
                  <div>
                    <Typography
                      variant="body2"
                      style={{ color: "#8C8C8C", fontSize: "12px" }}
                    >
                      תרומה אחרונה בוצעה ב-24 למרץ 2021, בסך
                    </Typography>
                    <Typography variant="h2">₪150,000</Typography>
                  </div>
                  <Grid container>
                    <Grid item xs={7}></Grid>
                    <Grid item xs={5}>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ height: "44px" }}
                      >
                        ביצוע מענק חוזר
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ height: "44px" }}
                      >
                        ביצוע מענק חוזר
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </div>
            <div className={classes.simple}>
              {" "}
              <Card>
                <CardContent>
                  <Typography variant="h4">--1-כרטיס עמותה פה---</Typography>
                  <div style={{ border: "1px dashed #161616" }}></div>
                  <div>
                    <Typography
                      variant="body2"
                      style={{ color: "#8C8C8C", fontSize: "12px" }}
                    >
                      תרומה אחרונה בוצעה ב-24 למרץ 2021, בסך
                    </Typography>
                    <Typography variant="h2">₪150,000</Typography>
                  </div>
                  <Grid container>
                    <Grid item xs={7}></Grid>
                    <Grid item xs={5}>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ height: "44px" }}
                      >
                        ביצוע מענק חוזר
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </div>
            <div className={classes.simple}>
              {" "}
              <Card>
                <CardContent>
                  <Typography variant="h4">--1-כרטיס עמותה פה---</Typography>
                  <div style={{ border: "1px dashed #161616" }}></div>
                  <div>
                    <Typography
                      variant="body2"
                      style={{ color: "#8C8C8C", fontSize: "12px" }}
                    >
                      תרומה אחרונה בוצעה ב-24 למרץ 2021, בסך
                    </Typography>
                    <Typography variant="h2">₪150,000</Typography>
                  </div>
                  <Grid container>
                    <Grid item xs={7}></Grid>
                    <Grid item xs={5}>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ height: "44px" }}
                      >
                        ביצוע מענק חוזר
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </div>

            <div className={classes.simple}>
              {" "}
              <Card>
                <CardContent>
                  <Typography variant="h4">--1-כרטיס עמותה פה---</Typography>
                  <div style={{ border: "1px dashed #161616" }}></div>
                  <div>
                    <Typography
                      variant="body2"
                      style={{ color: "#8C8C8C", fontSize: "12px" }}
                    >
                      תרומה אחרונה בוצעה ב-24 למרץ 2021, בסך
                    </Typography>
                    <Typography variant="h2">₪150,000</Typography>
                  </div>
                  <Grid container>
                    <Grid item xs={7}></Grid>
                    <Grid item xs={5}>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ height: "44px" }}
                      >
                        ביצוע מענק חוזר
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </div>
            <div
              className={classes.simple}
              //style={{ height: "350px", flexGrow: 9 }}
            >
              <Card>
                <CardContent>
                  <Typography variant="h4">--1-כרטיס עמותה פה---</Typography>
                  <div style={{ border: "1px dashed #161616" }}></div>
                  <div>
                    <Typography
                      variant="body2"
                      style={{ color: "#8C8C8C", fontSize: "12px" }}
                    >
                      תרומה אחרונה בוצעה ב-24 למרץ 2021, בסך
                    </Typography>
                    <Typography variant="h2">₪150,000</Typography>
                  </div>
                  <Grid container>
                    <Grid item xs={7}></Grid>
                    <Grid item xs={5}>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ height: "44px" }}
                      >
                        ביצוע מענק חוזר
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </div>
            <div
              className={classes.simple}
              //style={{ height: "230px", flexGrow: 9 }}
            >
              <Card>
                <CardContent>
                  <Typography variant="h4">--1-כרטיס עמותה פה---</Typography>
                  <div style={{ border: "1px dashed #161616" }}></div>
                  <div>
                    <Typography
                      variant="body2"
                      style={{ color: "#8C8C8C", fontSize: "12px" }}
                    >
                      תרומה אחרונה בוצעה ב-24 למרץ 2021, בסך
                    </Typography>
                    <Typography variant="h2">₪150,000</Typography>
                  </div>
                  <Grid container>
                    <Grid item xs={7}></Grid>
                    <Grid item xs={5}>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ height: "44px" }}
                      >
                        ביצוע מענק חוזר
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </div>
            <div className={classes.simple}>
              <Card>
                <CardContent>
                  <Typography variant="h4">--1-כרטיס עמותה פה---</Typography>
                  <div style={{ border: "1px dashed #161616" }}></div>
                  <div>
                    <Typography
                      variant="body2"
                      style={{ color: "#8C8C8C", fontSize: "12px" }}
                    >
                      תרומה אחרונה בוצעה ב-24 למרץ 2021, בסך
                    </Typography>
                    <Typography variant="h2">₪150,000</Typography>
                  </div>
                  <Grid container>
                    <Grid item xs={7}></Grid>
                    <Grid item xs={5}>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ height: "44px" }}
                      >
                        ביצוע מענק חוזר
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </div>
            <div className={classes.simple}>
              {" "}
              <Card>
                <CardContent>
                  <Typography variant="h4">--1-כרטיס עמותה פה---</Typography>
                  <div style={{ border: "1px dashed #161616" }}></div>
                  <div>
                    <Typography
                      variant="body2"
                      style={{ color: "#8C8C8C", fontSize: "12px" }}
                    >
                      תרומה אחרונה בוצעה ב-24 למרץ 2021, בסך
                    </Typography>
                    <Typography variant="h2">₪150,000</Typography>
                  </div>
                  <Grid container>
                    <Grid item xs={7}></Grid>
                    <Grid item xs={5}>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ height: "44px" }}
                      >
                        ביצוע מענק חוזר
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </div>
            <div className={classes.simple}>
              {" "}
              <Card>
                <CardContent>
                  <Typography variant="h4">--1-כרטיס עמותה פה---</Typography>
                  <div style={{ border: "1px dashed #161616" }}></div>
                  <div>
                    <Typography
                      variant="body2"
                      style={{ color: "#8C8C8C", fontSize: "12px" }}
                    >
                      תרומה אחרונה בוצעה ב-24 למרץ 2021, בסך
                    </Typography>
                    <Typography variant="h2">₪150,000</Typography>
                  </div>
                  <Grid container>
                    <Grid item xs={7}></Grid>
                    <Grid item xs={5}>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ height: "44px" }}
                      >
                        ביצוע מענק חוזר
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
        <div style={{ minWidth: "50px" }}>2</div>
      </div>
      {/* <div className={classes.root} style={{ direction: "rtl" }}>
        <Card>
          <CardContent>
            <Typography variant="h4">--1-כרטיס עמותה פה---</Typography>
            <div style={{ border: "1px dashed #161616" }}></div>
            <div>
              <Typography
                variant="body2"
                style={{ color: "#8C8C8C", fontSize: "12px" }}
              >
                תרומה אחרונה בוצעה ב-24 למרץ 2021, בסך
              </Typography>
              <Typography variant="h2">₪150,000</Typography>
            </div>
            <Grid container>
              <Grid item xs={7}></Grid>
              <Grid item xs={5}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ height: "44px" }}
                >
                  ביצוע מענק חוזר
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Typography variant="h4">-2--כרטיס עמותה פה---</Typography>
            <div style={{ border: "1px dashed #161616" }}></div>
            <div>
              <Typography
                variant="body2"
                style={{ color: "#8C8C8C", fontSize: "12px" }}
              >
                תרומה אחרונה בוצעה ב-24 למרץ 2021, בסך
              </Typography>
              <Typography variant="h2">₪150,000</Typography>
            </div>
            <Grid container>
              <Grid item xs={7}></Grid>
              <Grid item xs={5}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ height: "44px" }}
                >
                  ביצוע מענק חוזר
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Typography variant="h4">-3--כרטיס עמותה פה---</Typography>
            <div style={{ border: "1px dashed #161616" }}></div>
            <div>
              <Typography
                variant="body2"
                style={{ color: "#8C8C8C", fontSize: "12px" }}
              >
                תרומה אחרונה בוצעה ב-24 למרץ 2021, בסך
              </Typography>
              <Typography variant="h2">₪150,000</Typography>
            </div>
            <Grid container>
              <Grid item xs={7}></Grid>
              <Grid item xs={5}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ height: "44px" }}
                >
                  ביצוע מענק חוזר
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Typography variant="h4">--4-כרטיס עמותה פה---</Typography>
            <div style={{ border: "1px dashed #161616" }}></div>
            <div>
              <Typography
                variant="body2"
                style={{ color: "#8C8C8C", fontSize: "12px" }}
              >
                תרומה אחרונה בוצעה ב-24 למרץ 2021, בסך
              </Typography>
              <Typography variant="h2">₪150,000</Typography>
            </div>
            <Grid container>
              <Grid item xs={7}></Grid>
              <Grid item xs={5}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ height: "44px" }}
                >
                  ביצוע מענק חוזר
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Typography variant="h4">--5z -כרטיס עמותה פה---</Typography>
            <div style={{ border: "1px dashed #161616" }}></div>
            <div>
              <Typography
                variant="body2"
                style={{ color: "#8C8C8C", fontSize: "12px" }}
              >
                תרומה אחרונה בוצעה ב-24 למרץ 2021, בסך
              </Typography>
              <Typography variant="h2">₪150,000</Typography>
            </div>
            <Grid container>
              <Grid item xs={7}></Grid>
              <Grid item xs={5}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ height: "44px" }}
                >
                  ביצוע מענק חוזר
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div> */}

      {/* <KRadioButtonsMultiPick
          buttonsData={[
            { text: "OK", value: "OK" },
            { text: "BO", value: "BO" },

            { text: "BO5", value: "BO5" },
            { text: "ALON", value: "ALON" },
          ]}
          handleValue={handleValue}
        /> */}

      {/* <div style={{ direction: "ltr" }}>
          <KSwitch
            switchTextWhenOn={"מאושר"}
            switchTextWhenOff="הכל"
            switchWidth={146}
            switchValue={(val) => console.log(val)}
            style={{ direction: "ltr" }}
          />
        </div> */}
      {/* <NewGrantDialog></NewGrantDialog> */}
    </Box>
  );
}
