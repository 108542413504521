import React from "react";
import { Grid, Typography, Paper } from "@material-ui/core";
import Text from "../utilities/Text";

export default function NotificationCardInfoGeneral(props) {
  let grantsGoal;
  if (props.cardData.grantGoal === "In Support of a Program") {
    grantsGoal = props?.cardData.programName;
  } else if (props.cardData.grantGoal === "Other") {
    grantsGoal = props?.cardData.otherGoal;
  } else if (props.cardData.grantGoal === "General Support") {
    grantsGoal = <Text objectName="generalSupport" page="donation" />;
  } else {
    <Text objectName="noInfo" page="history" />;
  }

  return (
    <Paper
      elevation={0}
      style={{
        border: "1px solid #D1E4EC",
        borderRadius: "16px",
        boxSizing: "border-box",
        padding: "24px",
        margin: "16px 8px",
      }}
    >
      <Typography variant="h4" color="primary" style={{ marginBottom: "14px" }}>
        <Text objectName="moreInfo" page="history" />
      </Typography>

      <Grid container>
        <Grid item xs={12}>
          <Typography
            variant="body2"
            color="primary"
            style={{ marginBottom: "8px" }}
          >
            <Text objectName="details" page="status" />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" style={{ wordWrap: "break-word" }}>
            {props.cardData.details || (
              <Text objectName="noInfo" page="history" />
            )}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
