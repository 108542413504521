import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Button, Paper } from "@material-ui/core";

import Grid from "@material-ui/core/Grid";

import KStepper from "./KStepper";

import { ReactComponent as RadioB } from "../img/radio-checked.svg";
import { ReactComponent as SideArrow } from "../img/side-arrow.svg";
import { ReactComponent as Fireworks } from "../img/fireworks.svg";
import { ReactComponent as EditIcon } from "../img/EditIcon.svg";
import { useHistory } from "react-router-dom";

import FormStep1 from "./DonationProcessForm/step1";
import FormStep2 from "./DonationProcessForm/step2";
import FormStep3 from "./DonationProcessForm/step3";
import { openNewDonation } from "../utilities/api";
import { GlobalData, UserData } from "../Contexts";
import Text, { dictionary } from "../utilities/Text";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    margin: "12px 2px",
    borderRadius: "16px",
  },

  finishCard: {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "space-between",
  },
  selectA: {
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      paddingRight: "0px",
    },
  },
  cardfield: {
    "& .MuiOutlinedInput-root": {
      marginBottom: "24px",
      backgroundColor: "#FFFFFF",
    },
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      paddingRight: "10px",
    },
    "& .MuiSelect-select": {
      "&:focus": {
        backgroundColor: "#FFF",
      },
    },
  },

  currency: {
    backgroundColor: "#FFF",
    borderRadius: "16px",
    width: "56px",
    height: "32px",
    color: "#8C8C8C",
    fontWeight: 400,
    fontSize: 14,
  },
  currencyActive: {
    backgroundColor: "#C2EBED",
    borderRadius: "16px",
    width: "20%",
    border: "1px solid #161616",
    height: "32px",
    fontWeight: 400,
    fontSize: 14,
  },
}));

function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function DonationProcess(props) {
  const { language } = React.useContext(GlobalData);
  const [userCtx, setUserCtx] = React.useContext(UserData);
  const ngoName = props?.ngoData?.name;
  const ngoEngName = props?.ngoData?.eng_name;
  const ngoId = props?.ngoData?.id;
  const skipStep = props?.skipStep;

  const fillFormData = props?.formData;

  const [formData, setFormData] = React.useState({
    userData: userCtx?.user,
    ngoData: props?.ngoData,
    ticketType: "Grant Recommendation",
    grantSum: "",
    grantCurrency: "NIS",
    grantGoal: "",
    //checkFounder: false,
    founder: "",
    //secondFounder: "",
    keshetName: "",
    //checkSecondFounder: false,
    checkNotAnon: true,
    contactDetails: false,
    // addAcountName: true,
    contactName: "",
    contactPhone: "",
    contactMail: "",
    contactNotes: "",
  });

  const purpose = React.useCallback(() => {
    const donation = dictionary.donation;
    const rightTerm = Object.keys(donation).filter((item) => {
      const object = donation[item]["he"];
      return object === formData.grantGoal;
    })[0];
    return donation[rightTerm]?.[language];
  }, [language, formData]);

  //const [value, setValue] = React.useState(true);
  const [step, setStep] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();

  React.useEffect(() => {
    fillFormData &&
      setFormData((prev) => {
        return {
          ...prev,
          userData: userCtx?.user,
          grantCurrency: fillFormData?.grantCurrency,
          grantSum: fillFormData?.grantSum,
          grantGoal: fillFormData?.grantGoal,
          otherGoal: fillFormData?.otherGoal,
          founder: fillFormData?.founder,
          //grantGoal: fillFormData?.grantGoal,
          //checkFounder: fillFormData?.checkFounder,
          //checkSecondFounder: fillFormData?.checkSecondFounder,
          //checkNotAnon: fillFormData?.checkNotAnon,
          //addAcountName: fillFormData?.addAcountName,
          //contactName: fillFormData?.contactName,
          //contactPhone: fillFormData?.contactPhone,
          //contactMail: fillFormData?.contactDetails,
          //contactNotes: fillFormData?.contactNotes,
          grant_acknowledgement: fillFormData?.founder,
          keshetName: fillFormData?.keshetName,
          originalDonation: fillFormData?.originalDonation,
        };
      });
  }, [fillFormData]);

  React.useEffect(() => {
    skipStep && setStep(4);
  }, [skipStep]);
  const history = useHistory();

  function handleStep(stepCount) {
    if (step === 3) {
      let donor;
      if (formData.keshetName === "אחר") {
        donor = formData.founder;
      } else {
        donor = formData.keshetName;
      }
      //const data = formToText({ ...formData, grant_acknowledgement: donor });
      setFormData((prev) => {
        return {
          ...prev,
          //    ...data,
          grant_acknowledgement: donor,
          userData: userCtx?.user,
        };
      });
    }
    setStep((prev) => prev + 1);
  }

  let screen;
  if (step === 1) {
    screen = (
      <FormStep1
        sendData={(data) => setFormData({ ...formData, ...data })}
        data={formData}
      />
    );
  }

  if (step === 2) {
    screen = (
      <FormStep2
        sendData={(data) => setFormData({ ...formData, ...data })}
        data={formData}
      />
    );
  }

  if (step === 3) {
    screen = <FormStep3 sendData={() => handleStep(step)} data={formData} />;
  }

  if (step === 4) {
    let aaa = null;
    if (formData.grantGoal === "אחר" || formData.grantGoal === "Other") {
      aaa = <Typography variant="body2">{formData.otherGoal}</Typography>;
    } else if (
      formData.grantGoal === "תמיכה בתוכנית" ||
      formData.grantGoal === "In Support of a Program"
    ) {
      aaa = <Typography variant="body2">{formData.programName}</Typography>;
    } else if (
      formData.grantGoal === "תמיכה כללית" ||
      formData.grantGoal === "General Support"
    ) {
      aaa = (
        <Typography variant="body2">
          <Text objectName="generalSupport" page="donation" />
        </Typography>
      );
    } else {
      aaa = (
        <Typography variant="body2">
          {formData.grantGoal || <Text objectName="noInfo" page="history" />}
        </Typography>
      );
    }
    screen = (
      <React.Fragment>
        <Paper
          elevation={0}
          style={{
            border: "1px solid #D1E4EC",
            borderRadius: "16px",
            boxSizing: "border-box",
            padding: "30px",
            margin: "auto 8px",
          }}
        >
          <Typography
            variant="h4"
            color="primary"
            style={{ marginBottom: "12px" }}
          >
            <Text objectName="grantSummary" page="donation" />
          </Typography>
          {/* <div className={classes.finishCard}> */}
          <Grid container>
            <Grid item xs={5}>
              <Typography variant="body2" color="primary">
                <Text objectName="organization" page="donation" />
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography variant="h5" style={{ color: "#EA7B7E" }}>
                {language === "en" && ngoEngName ? ngoEngName : ngoName}
              </Typography>
            </Grid>
          </Grid>
          {/* </div> */}
          <div
            style={{ border: "1px dashed #E4E4E4", margin: "12px auto" }}
          ></div>

          <Grid container>
            <Grid item xs={5}>
              <Typography variant="body2" color="primary">
                <Text objectName="summaryGoal" page="donation" />
              </Typography>
            </Grid>
            <Grid item xs={7}>
              {/* {formData.grantGoal === "אחר" && (
                <Typography variant="body2">{formData.otherGoal}</Typography>
              )}
              {formData.grantGoal === "תמיכה בתוכנית" && (
                <Typography variant="body2">{formData.programName}</Typography>
              )}
              {formData.grantGoal === "תמיכה כללית" && (
                <Typography variant="body2">
                  <Text objectName="generalSupport" page="donation" />
                </Typography>
              )} */}
              {aaa}
            </Grid>
          </Grid>
          <div
            style={{ border: "1px dashed #E4E4E4", margin: "12px auto" }}
          ></div>

          <Grid container>
            <Grid item xs={5}>
              <Typography variant="body2" color="primary">
                <Text objectName="sum" page="donation" />
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography variant="h5" style={{ wordWrap: "break-word" }}>
                {formData?.grantCurrency === "NIS" ? "₪" : "$"}
                {numberWithCommas(formData?.grantSum)}
              </Typography>
            </Grid>
          </Grid>
          <div
            style={{ border: "1px dashed #E4E4E4", margin: "12px auto" }}
          ></div>

          <Grid container>
            <Grid item xs={5}>
              <Typography variant="body2" color="primary">
                <Text objectName="donor" page="donation" />
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography variant="body2">
                {formData?.keshetName === "אחר"
                  ? formData?.founder
                  : formData?.keshetName ||
                    Text({ page: "donation", objectName: "anonymous" })}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
        <Button
          variant="contained"
          color="primary"
          disabled={loading}
          style={{ margin: "24px auto", padding: "8px 24px" }}
          //endIcon={<RadioB style={{ marginRight: "12px" }} />}
          onClick={async () => {
            // let donor;
            // if (formData === "אחר") {
            //   donor = formData.founder;
            // } else {
            //   donor = formData.keshetName;
            // }
            // setFormData((prev) => {
            //   return {
            //     ...prev,
            //     grant_acknowledgement: donor,
            //     userData: userCtx?.user,
            //   };
            // });
            setLoading(true);
            const res = await openNewDonation(formData);
            if (!res.err) {
              handleStep(step);
            } else {
              alert("Error from server, try again.");
            }
            setLoading(false);
          }}
          //endIcon={<RadioB style={{ marginRight: "12px" }} />}
        >
          <Text objectName="submitBtn" page="donation" />
          <RadioB
            style={{
              marginInlineStart: "auto",
              stroke: "none",
            }}
          />
        </Button>
      </React.Fragment>
    );
  }

  if (step === 5) {
    screen = (
      <React.Fragment>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "40px",
            width: "100%",
          }}
        >
          <Fireworks />
        </div>
        <div
          style={{
            display: "flex",
            textAlign: "center",
            justifyContent: "center",
            marginBottom: "24px",
            width: "100%",
          }}
        >
          <Typography variant="h2" style={{ color: "#21C082" }}>
            <Text objectName="submittedTitle" page="donation" />
          </Typography>
        </div>

        <div
          style={{
            textAlign: "center",

            backgroundColor: "#FFFFFF",
            borderRadius: "4px",
            margin: "auto 25px",
            padding: "24px",
          }}
        >
          <Typography variant="h5" color="primary">
            <Text objectName="submittedSubTitle" page="donation" />
          </Typography>
          <Typography
            variant="h1"
            color="primary"
            style={{ wordWrap: "break-word" }}
          >
            {formData.grantCurrency === "NIS" ? "₪" : "$"}
            {numberWithCommas(formData?.grantSum)}
          </Typography>
          <Typography
            variant="h4"
            color="primary"
            display="inline"
            style={{ fontWeight: 400 }}
          >
            {language === "en" && ngoEngName ? "For " : "לעמותת "}
          </Typography>
          <Typography
            variant="h4"
            display="inline"
            style={{ color: "#EA7B7E" }}
          >
            {language === "en" && ngoEngName ? ngoEngName : ngoName}
          </Typography>
        </div>

        <div
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            textAlign: "center",
            margin: "15px 5px",
            width: "100%",
          }}
        >
          <RadioB style={{ fill: "#004E5E", marginInlineEnd: "8px" }} />
          <Typography variant="h3" color="primary">
            <Text objectName="submittedApp" page="donation" />
          </Typography>
        </div>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            history.push("/status");
          }}
          style={{ margin: "24px auto", padding: "auto 16px" }}
          //endIcon={<SideArrow style={{ marginRight: "auto", fill: "none" }} />}
        >
          <span>
            <Text objectName="finishBtn" page="donation" />
          </span>
          <SideArrow
            style={{
              marginInlineStart: "auto",

              fill: "none",
              transform: language === "en" ? "rotate(180deg)" : null,
            }}
          />
        </Button>
      </React.Fragment>
    );
  }

  const validateContinue = () => {
    if (step === 1) {
      const noGoalName =
        formData.grantGoal === "In Support of a Program" &&
        !formData.programName;
      const noOtherGoalDescription =
        formData.grantGoal === "Other" && !formData.otherGoal;
      console.log(formData.grantGoal, formData.grantSum);
      console.log({
        noGoalName,
        noOtherGoalDescription,
      });
      if (
        !formData.grantGoal ||
        !formData.grantSum ||
        noGoalName ||
        noOtherGoalDescription
      ) {
        return true;
      } else {
        return false;
      }
    }
    if (step === 2) {
      if (!formData.checkNotAnon) {
        return false;
      }
      if (
        formData.keshetName === "" ||
        (formData.keshetName === "אחר" && !formData.founder)
      ) {
        return true;
      }
    }
  };
  return (
    <>
      {/* <div style={{ border: "0.5px dashed #E4E4E4" }}></div> */}
      {step < 5 && (
        <Grid
          container
          alignItems="baseline"
          alignContent="center"
          justifyContent="space-around"
          spacing={2}
          style={{ marginBottom: "24px" }}
        >
          <Grid item>
            <Typography
              variant="h3"
              style={{ marginTop: "16px", marginRight: "4px" }}
            >
              <Text objectName="title" page="donation" />
            </Typography>
          </Grid>
          <Grid item>
            {skipStep && step === 4 ? (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  setStep(1);
                }}
                style={{
                  height: 43,
                  width: 151,
                  backgroundColor: "white",
                }}
              >
                <EditIcon style={{ marginInlineEnd: "10px" }} />
                <Text objectName="editBtn" page="donation" />
              </Button>
            ) : (
              <KStepper step={step} setExStep={setStep} skipStep={skipStep} />
            )}
          </Grid>
        </Grid>
      )}
      {screen}

      <Grid container>
        <Grid item xs={7}></Grid>
        <Grid item xs={5}>
          {step < 3 && (
            <Button
              variant="contained"
              color="primary"
              disabled={validateContinue()}
              onClick={() => handleStep(step)}
              style={{ height: "44px" }}
            >
              <Text objectName="nextBtn" page="donation" />
            </Button>
          )}
        </Grid>
      </Grid>
    </>
  );
}
