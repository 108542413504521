import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import { ReactComponent as ClosedIcon } from "../img/AccordionClosed.svg";
import { ReactComponent as OpenIcon } from "../img/AccordionOpen.svg";
import NotificationCard from "./NotificationCard";
import HistoryCard from "./HistoryCard";
import { Card, CardContent } from "@material-ui/core";
import { UserData } from "../Contexts";
import Text from "../utilities/Text";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    margin: "6px auto",
    borderRadius: "16px",
    padding: "8px 0px 4px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    display: "block",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  column: {
    flexBasis: "100%",
    width: "100%",

    // display: "block",
  },
  accordionSummary: {
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-root.Mui-expanded": {
      minHeight: "40px",
      height: "50px",
    },
  },
  // expanded: {},
  // content: {
  //   "&$expanded": {
  //     marginBottom: 0,
  //   },
  // },
  cardDesk: {
    border: "1px solid #3B8CA8",
    [theme.breakpoints.up("md")]: {
      maxWidth: "384px",
    },
  },
}));

export default function SimpleAccordion() {
  const [userCtx, setUserCtx] = React.useContext(UserData);
  const [requests, setRequests] = React.useState([]);
  const [grants, setGrants] = React.useState([]);
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const classes = useStyles();
  React.useEffect(() => {
    async function fetchRequests() {
      const response = userCtx?.user?.requests;
      //const response = [];
      setRequests(response);
    }

    fetchRequests();
  }, [userCtx]);

  React.useEffect(() => {
    async function fetchGrants() {
      const response = await userCtx?.user?.grants;
      //const response = [];
      setGrants(response);
    }

    fetchGrants();
  }, [userCtx]);

  let requests_cards;
  if (requests?.length === 0) {
    requests_cards = (
      <div className={classes.column}>
        <Card>
          <CardContent style={{ width: "100%" }}>
            <Typography variant="h4" color="primary">
              --------- <Text page="status" objectName="noResults" /> ---------
            </Typography>
          </CardContent>
        </Card>
      </div>
    );
  } else {
    requests_cards = requests?.map((item, index) => {
      return (
        <div className={classes.column} key={index}>
          <NotificationCard cardData={item} />
        </div>
      );
    });
  }
  return (
    <div style={{ margin: "auto 12px" }}>
      <Accordion
        elevation={0}
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          className={classes.accordionSummary}
          expandIcon={expanded === "panel1" ? <OpenIcon /> : <ClosedIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h4" color="primary">
            <Text objectName="requestsAcc" page="mainpage" />
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.root}>
          {requests_cards}
        </AccordionDetails>
      </Accordion>
      <Accordion
        elevation={0}
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          className={classes.accordionSummary}
          expandIcon={expanded === "panel2" ? <OpenIcon /> : <ClosedIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography variant="h4" color="primary">
            <Text objectName="grantsAcc" page="mainpage" />
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.root}>
          {grants?.length !== 0 && expanded === "panel2" ? (
            grants?.map((grant) => {
              return (
                <Card className={classes.cardDesk}>
                  <HistoryCard cardData={grant} />
                </Card>
              );
            })
          ) : (
            <Typography variant="h4" color="primary">
              <Text page="history" objectName="noHistory" />
            </Typography>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
