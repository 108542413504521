import React from "react";
import logo from '../img/keshet_logo.jpg'
import {Button, TextField} from '@material-ui/core/';
import Search from "./Search"
// import Display from "./Box"
import Box from '@material-ui/core/Box';


export default function GrantRequest() {
     const [filter, setFilter] = React.useState("")
    let filteredRequests
    if (filter.length>0){
    filteredRequests = grantRequests.filter(grant => {
        return grant.title.includes(filter)

    })}
    else{
        filteredRequests = grantRequests
    }

// TODO: add the filter like ngo page insted of hard coding
    
    return (<div>
        <Search name = "מענק" options = {grantRequests} setFilter={setFilter}/>
        <Box bgcolor="gray" width="fit-content"  margin= "auto">פה נתונים של בקשות</Box>
        <Box bgcolor="gray" width="fit-content"  margin= "auto">פה עוד נתונים של בקשות</Box>
        <Button variant="outlined" color="secondary">הצג עוד</Button>
        <br/>
        <Button variant="outlined" color="secondary">לביצוע מענק חדש</Button>
        <Box>פעולות נוספות:</Box>
        <Button variant="outlined" color="secondary">לביצוע הפקדה חדשה</Button>
        <Button variant="outlined" color="secondary">פניה בנושא השקעות</Button>
    </div>)
}

const grantRequests = [
    {title: "בקשה אחת"},
    {title: "בקשה שתיים"}
]