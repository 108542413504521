import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useLanguage } from "../hooks/";
import { ReactComponent as ExpandIcon } from "../img/arrowExpand.svg";
import { ReactComponent as CloseIcon } from "../img/arrowClose.svg";
import CardContent from "@material-ui/core/CardContent";
import Ngo from "./Ngo";
import DonationProcess from "./DonationProcess";
import HistoryCardInfo from "./HistoryCardInfo";
import Text from "../utilities/Text";
import { Collapse } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    margin: "6px 2px",
    borderRadius: "16px",
  },
  notificationstatus: {
    color: "#21C082",
    fill: "#21C082",
    display: "inline",
    margin: "6px",
  },

  noPadding: {
    marginTop: 0,
    marginBottom: 0,
    paddingTop: 0,
  },

  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  column: {
    flexBasis: "100%",
    width: "100%",

    // display: "block",
  },
}));

function numberWithCommas(x) {
  if (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return;
  }
}

export default function HistoryCard(props) {
  const classes = useStyles();
  const language = useLanguage();
  const [cardData, setCardData] = React.useState({});
  const [redonate, setRedonate] = React.useState(false);
  const [moreInfo, setMoreInfo] = React.useState(false);
  React.useEffect(() => {
    setCardData(props?.cardData);
  }, [props]);

  const months = [
    "לינואר",
    "לפברואר",
    "למרץ",
    "לאפריל",
    "למאי",
    "ליוני",
    "ליולי",
    "לאוגוסט",
    "לספטמבר",
    "לאוקטובר",
    "לנובמבר",
    "לדצמבר",
  ];

  function convertDate(date_str) {
    let temptemp = date_str.split("T");
    let temp_date = temptemp[0]?.split("-");
    if (language === "en") {
      const engdate = new Date(date_str).toDateString().substring(4).split(" ");
      return engdate[0] + " " + engdate[1] + ", " + engdate[2];
    }
    return (
      Number(temp_date[2]) +
      " " +
      months[Number(temp_date[1]) - 1] +
      " " +
      temp_date[0]
    );
  }
  const currency = cardData?.currency === "שקל חדש" || "ILS" ? "₪" : "$";

  const handleClick = () => {
    setRedonate(true);
  };

  const iconStyle = {
    position: "relative",
    height: 12,
    width: 12,
    marginLeft: 6,
  };

  const cardStyle = {
    background: "#FFFFFF",
    borderRadius: "25px",
    display: "inline-block",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 8,
    padding: "4px 12px",
  };

  const cubeStyle = {
    background: "#FFFFFF",
    border: "1px solid #D1E4EC",
    boxSizing: "border-box",
    borderRadius: "6px",
    width: "fit-content",
    padding: "2px 10px",
    height: 28,
  };

  return (
    <React.Fragment>
      <CardContent>
        {cardData?.ngo && cardData?.name ? (
          <Ngo ngo={cardData?.ngo} shortCard={true} />
        ) : (
          <Typography variant="h3">
            <Text objectName="noNgoData" page="history" />
          </Typography>
        )}
        {!redonate ? (
          <React.Fragment>
            <div style={{ borderTop: "1px dashed #161616" }}></div>
            <Typography
              variant="body1"
              style={{
                color: "#8C8C8C",
                fontSize: "12px",
                marginTop: "24px",
                marginBottom: "6px",
              }}
            >
              <Text objectName="lastGrant" page="history" />
              {cardData?.pass_date ? convertDate(cardData?.pass_date) : null}
              <Text objectName="onSum" page="history" />
            </Typography>

            <Typography variant="h2">
              {cardData?.amount === "0"
                ? ""
                : currency + numberWithCommas(cardData?.amount)}
            </Typography>
            <Collapse in={moreInfo} timeout="auto">
              <HistoryCardInfo cardData={cardData}></HistoryCardInfo>
            </Collapse>
            <Grid container>
              <Grid item xs={6}>
                <Button
                  style={{
                    height: 44,
                    width: 130,
                    border: "none",
                    backgroundColor: "#F1F1F1",
                  }}
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setMoreInfo((prev) => !prev);
                  }}
                >
                  {moreInfo ? (
                    <Text objectName="closeBtn" page="history" />
                  ) : (
                    <Text objectName="moreInfo" page="ngo" />
                  )}

                  {moreInfo ? (
                    <CloseIcon style={{ marginInlineStart: 13 }} />
                  ) : (
                    <ExpandIcon style={{ marginInlineStart: 13 }} />
                  )}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  onClick={handleClick}
                  variant="contained"
                  color="primary"
                  style={{ height: "44px" }}
                >
                  <Text objectName="regrantbutton" page="history" />
                </Button>
              </Grid>
            </Grid>
          </React.Fragment>
        ) : (
          <DonationProcess
            ngoData={cardData?.ngo}
            skipStep={true}
            formData={{
              grantSum: cardData?.amount,
              grantGoal: "Other",
              otherGoal: cardData?.DonationGoal,
              grantCurrency: cardData?.currency === "USD" ? "USD" : "NIS",
              keshetName: "אחר",
              founder: cardData?.grant_acknowledgement,
              originalDonation: cardData?.id,
            }}
          />
        )}
      </CardContent>
    </React.Fragment>
  );
}
