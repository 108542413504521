import React from "react";
import { ReactComponent as FavoriteBadgeReg } from "../img/favoriteBadgeReg.svg";
import { ReactComponent as LikedNgoIcon } from "../img/likedNgo.svg";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Badge from "@material-ui/core/Badge";
import { ReactComponent as Chapter46 } from "../img/chapter46.svg";
import { ReactComponent as Vi } from "../img/vi.svg";
import { ReactComponent as ProperManagement } from "../img/properManagement.svg";
import { ReactComponent as Donors } from "../img/donors.svg";
import { ReactComponent as Location } from "../img/location.svg";
import { ReactComponent as KeshetApproved } from "../img/keshetApproved.svg";
import { ReactComponent as TavMidot } from "../img/tavMidot.svg";
import DonationProcess from "./DonationProcess";
import Collapse from "@material-ui/core/Collapse";
import { ReactComponent as ExpandIcon } from "../img/arrowExpand.svg";
import Button from "@material-ui/core/Button";
import Text from "../utilities/Text";
import { GlobalData, UserData, UserFavoritesNgos } from "../Contexts";
import { addFavNgo } from "../utilities/api";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  badge: {
    "& .MuiBadge-badge": {
      backgroundColor: "#21C082",
      color: "white",
      height: "16px",
      minWidth: "16px",
      width: "16px",
      padding: "0 2px",
      position: "relative",
      paddingLeft: 3,
      paddingTop: 1,
    },
  },
  nobadge: {
    "& .MuiBadge-badge": {
      display: "none",
    },
  },
}));

export default function Ngo(props) {
  const { language } = React.useContext(GlobalData);
  const badgeSide = language === "en" ? "left" : "right";
  const [userCtx, setUserCtx] = React.useContext(UserData);
  const [userFavsNgos, setUserFavsNgos] = React.useContext(UserFavoritesNgos);
  const [fav, setFav] = React.useState(false);
  const [collapseNgo, setCollapseNgo] = React.useState();
  const [donationProposal, setDonationProposal] = React.useState();
  const classes = useStyles();
  const handleCollapse = (id) => {
    setCollapseNgo(id);
  };

  const ngo = props.ngo;
  const saif46 = ngo["saif46"];
  const keshetApprove = ngo["keshetApprove"];
  const numberOfDonors = ngo["number_of_keshet_donors"] || 0;
  const nihulTakin = ngo["nihulTakin"];
  const tavMidot = ngo["tavMidot"];
  const shortCard = props?.shortCard;
  const ngoWebSite = ngo["website"];

  const handleLike = (ngo) => {
    setFav((prev) => {
      return !prev;
    });

    let likedNgos;

    if (!userFavsNgos) {
      likedNgos = [];
    } else {
      likedNgos = userFavsNgos;
    }

    if (likedNgos.includes(ngo.NgoNumber)) {
      const index = likedNgos.indexOf(ngo.NgoNumber);
      if (index > -1) {
        likedNgos.splice(index, 1);

        setUserFavsNgos(likedNgos);

        addFavNgo(userCtx.user.account_id, userFavsNgos);
      }
    } else {
      likedNgos.push(ngo["NgoNumber"]);

      setUserFavsNgos(likedNgos);

      addFavNgo(userCtx.user.account_id, userFavsNgos);
    }
  };

  const Midot = (
    <Badge
      badgeContent={<Vi />}
      anchorOrigin={{ horizontal: badgeSide, vertical: "top" }}
      className={tavMidot ? classes.badge : classes.nobadge}
    >
      <div style={cubeStyle(tavMidot)}>
        <Typography variant="body2" style={textFill(tavMidot)}>
          <TavMidot style={{ ...iconStyle, top: 3 }}></TavMidot>
          <Text objectName="midotSeal" page="ngo" />
        </Typography>
      </div>
    </Badge>
  );
  const Saif46 = (
    <Badge
      badgeContent={<Vi />}
      anchorOrigin={{ horizontal: badgeSide, vertical: "top" }}
      className={saif46 ? classes.badge : classes.nobadge}
    >
      <div style={cubeStyle(saif46)}>
        <Typography variant="body2" style={textFill(saif46)}>
          <Chapter46 style={{ ...iconStyle, top: 4 }}></Chapter46>
          <Text objectName="saif46" page="ngo" />
        </Typography>
      </div>
    </Badge>
  );
  const NihulTakin = (
    <Badge
      badgeContent={<Vi />}
      anchorOrigin={{ horizontal: badgeSide, vertical: "top" }}
      className={nihulTakin ? classes.badge : classes.nobadge}
    >
      <div style={cubeStyle(nihulTakin)}>
        <Typography variant="body2" style={textFill(nihulTakin)}>
          <ProperManagement style={{ ...iconStyle, top: 4 }}></ProperManagement>
          <Text objectName="nihulTakin" page="ngo" />
        </Typography>
      </div>
    </Badge>
  );
  const KeshetApprove = (
    <Badge
      badgeContent={<Vi />}
      anchorOrigin={{ horizontal: badgeSide, vertical: "top" }}
      className={keshetApprove ? classes.badge : classes.nobadge}
    >
      <div style={cubeStyle(keshetApprove)}>
        <Typography variant="body2" style={textFill(keshetApprove)}>
          {language === "en" ? (
            <>
              <div
                style={{ display: "flex", gap: "2px", alignItems: "center" }}
              >
                <KeshetApproved
                  style={{ ...iconStyle, height: "20px", width: "20px" }}
                ></KeshetApproved>
                <Text objectName="approved" page="ngo" />
              </div>
            </>
          ) : (
            <>
              <div
                style={{ display: "flex", gap: "4px", alignItems: "center" }}
              >
                <Text objectName="approved" page="ngo" />
                <KeshetApproved
                  style={{ ...iconStyle, height: "20px", width: "20px" }}
                ></KeshetApproved>
              </div>
            </>
          )}
        </Typography>
      </div>
    </Badge>
  );
  const NumberOfDonors = (
    <Badge
      badgeContent={<Vi />}
      anchorOrigin={{ horizontal: badgeSide, vertical: "top" }}
      className={numberOfDonors > 0 ? classes.badge : classes.nobadge}
    >
      <div style={cubeStyle(numberOfDonors > 0)}>
        <Typography variant="body2" style={textFill(numberOfDonors > 0)}>
          <Donors style={iconStyle}></Donors>
          {"(" + numberOfDonors + ") "}
          <Text objectName="keshetDonors" page="ngo" />
        </Typography>
      </div>
    </Badge>
  );
  const LocationComp = (
    <Badge
      badgeContent={<Vi />}
      className={classes.badge}
      anchorOrigin={{ horizontal: badgeSide, vertical: "top" }}
    >
      <div style={cubeStyle(true)}>
        <Typography variant="body2" style={textFill(true)}>
          <Location style={iconStyle}></Location>
          {ngo?.district && ngo?.district.join(" ")}
        </Typography>
      </div>
    </Badge>
  );
  return (
    <>
      <CardTop>
        <NgoActivityWrapper>
          <NgoActivity variant="body2">
            {" "}
            {language === "en" ? ngo["mainactivityENG"] : ngo["mainactivity"]}
          </NgoActivity>
        </NgoActivityWrapper>
        <FavWrapper>
          <Button
            onClick={() => {
              handleLike(ngo);
            }}
          >
            {userFavsNgos.includes(ngo["NgoNumber"]) ? (
              <LikedNgoIcon />
            ) : (
              <FavoriteBadgeReg />
            )}
          </Button>
        </FavWrapper>
      </CardTop>
      <Grid container style={{ marginBottom: 42 }}>
        <Grid item xs={12} style={{ marginBottom: 8 }}>
          <Typography variant={"h2"}>
            {language === "en" && ngo?.eng_name ? ngo?.eng_name : ngo?.name}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <NgoProperties>
            {[
              { component: Saif46, disabled: saif46 === false },
              { component: NihulTakin, disabled: nihulTakin === false },
              { component: KeshetApprove, disabled: keshetApprove === false },
              { component: NumberOfDonors, disabled: numberOfDonors === 0 },
              { component: Midot, disabled: tavMidot === false },
              // { component: LocationComp, disabled: true },
            ]
              .sort((a, b) => {
                return b.disabled ? -1 : 1;
              })
              .map((item) => item.component)}
          </NgoProperties>
        </Grid>
        {!shortCard && <Divider />}
      </Grid>

      {!shortCard &&
        (donationProposal === ngo.NgoNumber ? (
          <DonationProcess ngoData={ngo} />
        ) : (
          <Collapse in={collapseNgo === ngo.NgoNumber}>
            <Grid container>
              <Grid item xs={12} style={{ marginBottom: 4 }}>
                <Typography color="textSecondary">
                  <Text objectName="orgMission" page="ngo" />
                </Typography>
                {language === "he"
                  ? ngo["about"]
                  : ngo["aboutENG"]
                  ? ngo["aboutENG"] //hebrew is a fallback here in case there is no about in english
                  : ngo["about"]}
              </Grid>
              <Grid item xs={12} style={{ marginBottom: 4 }}>
                <Typography color="textSecondary">
                  <Text objectName="orgInfo" page="ngo" />
                </Typography>
              </Grid>
              {/*<Grid item xs={6}>*/}
              {/*  <Typography variant="h5" color="textPrimary">*/}
              {/*    <Text objectName="orgProfile" page="ngo" />*/}
              {/*    {ngo["type"]}*/}
              {/*  </Typography>*/}
              {/*</Grid>*/}
              <Grid item xs={6}>
                <Typography variant="h5" color="textPrimary">
                  <Text objectName="employees" page="ngo" />
                  {ngo["employees"]}
                </Typography>
              </Grid>
              {/*<Grid item xs={6}>*/}
              {/*  <Typography variant="h5" color="textPrimary">*/}
              {/*    <Text objectName="status" page="ngo" />*/}
              {/*    {ngo["status"]}*/}
              {/*  </Typography>*/}
              {/*</Grid>*/}
              <Grid item xs={6}>
                <Typography variant="h5" color="textPrimary">
                  <Text objectName="members" page="ngo" />
                  {ngo["havriAmuta"]}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h5" color="textPrimary">
                  <Text objectName="orgNo" page="ngo" />
                  {ngo["NgoNumber"]}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="h5" color="textPrimary">
                  <Text objectName="volunteers" page="ngo" />
                  {ngo["volunteers"]}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="h5"
                  color="textPrimary"
                  style={{ marginBottom: "20px" }}
                >
                  <Text objectName="estYear" page="ngo" />
                  {ngo["foundyear"]}
                </Typography>
              </Grid>
            </Grid>
          </Collapse>
        ))}
      {!shortCard && donationProposal !== ngo.NgoNumber && (
        <Grid
          style={{ width: "100%" }}
          container
          alignItems="center"
          alignContent="center"
          justify="space-between"
        >
          <Grid item xs={6}>
            {collapseNgo === ngo.NgoNumber ? (
              <MoreInfoButton
                disabled={!ngoWebSite}
                variant="outlined"
                color="primary"
                onClick={() => {
                  window.open(ngoWebSite);
                }}
              >
                <Text objectName="orgWebsite" page="ngo" />{" "}
              </MoreInfoButton>
            ) : (
              <MoreInfoButton
                variant="outlined"
                color="primary"
                onClick={() => {
                  handleCollapse(ngo.NgoNumber);
                }}
              >
                <Text objectName="moreInfo" page="ngo" />
                <ExpandIcon style={{ marginInlineStart: 13 }} />
              </MoreInfoButton>
            )}
          </Grid>
          <Grid item xs={5}>
            <DonationProposalButton
              onClick={() => {
                setDonationProposal(ngo.NgoNumber);
              }}
              variant="contained"
              color="primary"
            >
              <Text objectName="grantRecommendation" page="ngo" />
            </DonationProposalButton>
          </Grid>
        </Grid>
      )}
    </>
  );
}

const textFill = (isActive) => {
  return {
    color: isActive ? "#3B8CA8" : "grey",
    fill: isActive ? "#3B8CA8" : "grey",
  };
};

const cubeStyle = (isActive) => {
  return {
    background: !isActive ? "transparent" : "white",
    opacity: !isActive ? "0.4" : "1",
    border: "1px solid " + (!isActive ? "rgba(0,0,0,0.5)" : "#3B8CA8"),
    boxSizing: "border-box",
    borderRadius: "6px",
    width: "fit-content",
    padding: "2px 10px",
    cursor: "pointer",
    height: 28,
  };
};

const iconStyle = {
  position: "relative",
  height: 14,
  width: 14,
  marginInlineEnd: 6,
  fill: "rgb(59, 140, 168)",
};

const Divider = styled.div`
  border-top: 1px dashed #e4e4e4;
  margin-top: 24px;
  margin-bottom: 16px;
  width: 100%;
`;

const CardTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
`;
const NgoActivityWrapper = styled.div`
  background: #ffffff;
  border-radius: 25px;
  display: inline-block;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  padding: 8px 12px;
`;
const NgoActivity = styled(Typography)`
  color: #8c8c8c;
`;

const FavWrapper = styled.div``;

const MoreInfoButton = styled(Button)`
  height: 44px;
  width: 130px;
  border: none;
  background-color: #f1f1f1;
`;

const DonationProposalButton = styled(Button)`
  height: 44px;
  width: 130px;
`;

const NgoProperties = styled.span`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;
