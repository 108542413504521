import { Typography } from "@material-ui/core";
import React from "react";
import { useLanguage } from "../hooks";
import { ReactComponent as KeshetApproved } from "../img/keshetApproved.svg";

export default function KSwitch({
  switchTitle,
  switchTextWhenOn,
  switchTextWhenOff,
  switchWidth = 75,
  switchValue,
  startValue,
}) {
  const [on, setOn] = React.useState(startValue || false);
  const language = useLanguage();
  React.useEffect(() => {
    switchValue(on);
  }, [on]);
  const container = {
    width: switchWidth,
    height: 36,
    borderRadius: 32,
    position: "relative",
    cursor: "pointer",
    direction: "ltr",
    WebkitTapHighlightColor: "transparent",
    outline: "none",
  };

  const ellipseOn = {
    width: 30,
    height: 30,
    borderRadius: "50%",
    position: "relative",
    top: 3,
    background: `#FFFFFF`,
    transition: "left 0.2s",
  };

  const text = {
    // fontSize: 14,
    // fontWeight: 400,

    position: "absolute",
    color: "white",
    top: 0.5,
    transition: "left 0.2s",
    userSelect: "none",
    WebkitTapHighlightColor: "transparent",
    outline: "none",
  };
  const textOn = (
    <div
      style={{
        display: "flex",
        flexDirection: [language === "he" ? "row" : "row-reverse"],
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
      }}
    >
      <KeshetApproved
        style={{
          fill: "#FFF",

          marginInline: "6px",
          top: "0.5em",
          width: "36px",
          height: "36px",
        }}
      />
      <Typography variant="body2" noWrap="true">
        {switchTextWhenOn}
      </Typography>
    </div>
  );

  const textOff = (
    <div
      style={{
        display: "flex",
        flexDirection: [language === "he" ? "row" : "row-reverse"],
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
      }}
    >
      <KeshetApproved
        style={{
          fill: "#FFF",

          marginInline: "6px",
          top: "0.5em",
          width: "36px",
          height: "36px",
        }}
      />
      <Typography variant="body2" noWrap="true">
        {switchTextWhenOff}
      </Typography>
    </div>
  );

  return (
    <div
      style={{
        display: "flex",
        WebkitTapHighlightColor: "transparent",
        outline: "none",
      }}
    >
      <span
        style={{
          fontSize: 14,
          marginRight: 16,
          lineHeight: 2,
        }}
      >
        {" "}
        {switchTitle}
      </span>
      <div
        style={{ ...container, background: on ? `#004E5E` : "#C7C7C7" }}
        onClick={() => {
          setOn((prev) => !prev);
        }}
      >
        <div style={{ ...ellipseOn, left: on ? 2 : switchWidth - 32 }}></div>
        <span
          style={{
            ...text,
            top: on ? 0.5 : 0.5,
            left: on ? 45 : 10,
            marginLeft: on ? "" : "8px",

            color: !on ? "white" : "white",
          }}
        >
          {" "}
          <Typography variant="body2">{on ? textOn : textOff}</Typography>
        </span>
      </div>
    </div>
  );
}
