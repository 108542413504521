import axios from "axios";
import {doc, getDoc, getFirestore, setDoc} from "firebase/firestore";
import React from "react";


/**
 * this is the function that calls the api. all other functions in this file calls it
 *
 * @param {string} url the endpoint to go to
 * @param {string} method the method to use (get,post,delete,put...)
 * @param {Object} body if it is a post - what is the body
 * @param {Object} params params for axios get
 * @returns {Object}
 */
async function queryAPI(url, method, body, params) {
    let headers;
    const token = localStorage.getItem("userJWT");
    if (token) {
        headers = {authorization: token};
    }

    try {
        const response = await axios({
            url: process.env.REACT_APP_API_URL + url,
            method,
            data: body,
            params,
            headers,
        });
        return response;
    } catch (err) {
        return {err};
    }
}

export async function getUserData() {
    const res = await queryAPI("/salesforce/user");
    return res;
}

export async function getUserAccounts() {
    const res = await queryAPI("/salesforce/user/get-accounts");
    return res;
}

export async function isPhoneInDB(phone) {
    const res = await queryAPI("/salesforce/phone-check?phone=" + phone);
    return res;
}

export async function getUserFiles(account_number) {
    const res = await queryAPI("/firebase/storage/" + account_number);
    return res;
}

/**
 * POST new donation request
 *
 */
export async function openNewDonation(formData) {
    delete formData.founder;
    delete formData.checkNotAnon;
    delete formData.contactDetails;

    const res = await queryAPI("/salesforce/open-ticket", "POST", formData);
    console.log(res);
    return res;
}

/**
 * Open new ticket from Actions screen
 *
 */
export async function openNewTicket(formData) {
    const res = await queryAPI("/salesforce/open-ticket", "POST", formData);
    console.log(res);
    return res;
}

/**
 * get the list of Ngos
 *
 */
export async function getNgos(page) {
    const response = await queryAPI("/salesforce/ngo?page=" + page, "get");

    return response;
}

/**
 * get the list of Unread Notifications
 *
 */
export async function getNotifications() {
    const response = await queryAPI("/notifications?unread_status=true", "get");
    return response;
}

/**
 * get the count of unread Notifications
 *
 */
export async function getUnreadNotificationsCount() {
    const response = await queryAPI(
        "/notifications/count?unread_status=true",
        "get"
    );
    return response;
}

/**
 * put unread Notifications as read
 *
 */
export async function putNotificationsStatus(id) {
    const response = await queryAPI("/notifications" + id, "put", {
        unread_status: false,
    });
    return response;
}

/**
 * put read Notifications as unread (for testing only)
 *
 */
export async function resetNotifications(id) {
    const response = await queryAPI("/notifications" + id, "put", {
        unread_status: true,
    });
    return response;
}

/**
 * get the list of Requests
 *
 */
export async function getRequests() {
    const response = await queryAPI("/salesforce/requests", "get");
    return response;
}

/**
 * get the list of Grants history
 *
 */
export async function getGrants() {
    const response = await queryAPI("/new-grants", "get");
    return response;
}

/**
 * post ngo rating and feedback
 *
 */
export async function postFeedback(ngoId, userRating, userFeedback) {
    const response = await queryAPI("/salesforce/ratengo", "post", {
        ngo: ngoId,
        newRating: userRating,
        newFeedback: userFeedback,
    });
    return response;
}

/**
 * post new grant request
 *
 */
export async function postNewGrant(newGrantData) {
    const response = await queryAPI(
        "/salesforce/new-grants",
        "post",
        newGrantData
    );
    return response;
}

/**
 * this is a function to post a new request
 *
 * @param {Object} form a form with 3 keys: request, department, details
 * @returns {Promise<*|{err: *}>}
 */
export async function sendNewRequest(form) {
    const data = await queryAPI("/salesforce/newrequest", "post", form);
    console.log(data);
    if (data.status === 200) {
        return data;
    } else {
        console.log("server is shitty");
    }
}

/**
 * get the list of districts
 *
 */

export async function getDistrictData() {
    const res = await queryAPI("/salesforce/districts");
    return res;
}

/**
 * get the list of classification
 *
 */

export async function getClassificationData() {
    const res = await queryAPI("/salesforce/classification");
    return res;
}

/**
 * get the list of Ngos by name
 *
 */
export async function getNgosByName(ngoName, page) {
    const response = await queryAPI(
        "/salesforce/ngo/name/" + ngoName + "?page=" + page,
        "get"
    );

    return response;
}

export async function getNgosByParams(params) {
    const response = await queryAPI("/salesforce/ngo", "get", {}, params);
    return response;
}

/**
 * get the list of employees-lvl
 *
 */

export async function getEmployeesLvl() {
    const res = await queryAPI("/salesforce/employees-lvl");
    return res;
}

/**
 * get the list of yearlycycle-lvl
 *
 */

export async function getYearlycycleLvl() {
    const res = await queryAPI("/salesforce/yearlycycle-lvl");
    return res;
}

export async function getFavNgosFromSrvr(favList) {

    const dataToSend = favList.join("&")

    const response = await queryAPI("/salesforce/ngos/favorite/" + dataToSend);
    return response;


}


export async function getFavsNgos(user) {

    const firestore = getFirestore()
    const collection = "favoriteNgos"
    const userId = user

    const path = `${collection}/${userId}`
    const docRef = doc(firestore, path);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        return (docSnap.data().favoriteNGOs)
    } else {

        console.log("No such document!");
        return ([])
    }
}

export async function addFavNgo(user, ngos) {
    const firestore = getFirestore()
    const collection = "favoriteNgos"
    const userId = user
    const path = `${collection}/${userId}`
    const data = doc(firestore, path)
    const docData = {

        favoriteNGOs: ngos
    }
    await setDoc(data, docData)

}