/* eslint-disable no-use-before-define */
import React, { useCallback } from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { ReactComponent as SearchIcon } from "../img/search.svg";
import debounce from "lodash.debounce";

export default function Search(props) {
  const debouncedSearch = useCallback(
    debounce((nextValue) => {
      props.setPage(1);
      props.setFilter(nextValue);
    }, 700),
    [] // will be created only once initially
  );

  return (
    <div>
      <TextField
        variant="outlined"
        label={props.name}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon style={{ marginRight: "23px" }} />
            </InputAdornment>
          ),
        }}
        onChange={(e) =>
          debouncedSearch(e.target.value.replace("-", " ").replace(",", " "))
        }
        placeholder={props.placeholder}
      />
    </div>
  );
}
