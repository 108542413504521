import { Box, Fade, Typography } from "@material-ui/core";
import React from "react";
import { listStorage, getFileA } from "../utilities/storage";
import { ReactComponent as FolderIcon } from "../img/FolderOpen.svg";
import { ReactComponent as FileIcon } from "../img/PDFfiles.svg";
import { ReactComponent as DownloadIcon } from "../img/ReviewDoc.svg";

import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Text from "../utilities/Text";
import { GlobalData, UserData } from "../Contexts";
import { getUserFiles } from "../utilities/api";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "80%",
    },
  },
  heading: {
    marginInlineStart: "8px",
  },
}));

export default function Files() {
  const globalData = React.useContext(GlobalData);
  const [userCtx, setUserCtx] = React.useContext(UserData);
  const screenSize = globalData.screenSize;
  const classes = useStyles();
  const [files, setFiles] = React.useState();
  const [loading, setLoading] = React.useState(true);
  // React.useEffect(() => {
  //   async function fetchFiles() {
  //     const res = await listStorage();
  //     console.log(res);
  //     setLoading(false);
  //     setFiles((prev) => {
  //       return res;
  //     });
  //   }

  //   fetchFiles();
  // }, []);

  // React.useEffect(() => {
  //   async function fetchFiles2() {
  //     const res = await getUserFiles();
  //     console.log(res);

  //   }

  //   fetchFiles2();
  // }, []);

  React.useEffect(() => {
    async function fetchFiles2() {
      const res = await getUserFiles(userCtx?.user?.account_number);

      setLoading(false);
      setFiles((prev) => {
        return res?.data;
      });
    }

    fetchFiles2();
  }, [userCtx]);

  return (
    //<Fade in={true} timeout={800} unmountOnExit>
    <Box px={screenSize ? 14 : 2}>
      <div style={{ textAlign: "start" }}>
        <Typography
          variant="h2"
          style={{ marginTop: "18px", marginBottom: "6px" }}
        >
          <Text objectName="files" page="navbar" />
        </Typography>
      </div>
      {loading && <CircularProgress />}
      <div className={classes.root}>
        {files && Object.keys(files).length > 0 ? (
          Object.keys(files)?.map((folder, idx) => {
            return (
              <Accordion key={idx} elevation={0} defaultExpanded>
                <AccordionSummary
                  //expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={{ padding: 0 }}
                >
                  <FolderIcon />
                  <Typography
                    color="primary"
                    variant="h5"
                    className={classes.heading}
                  >
                    {folder}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  style={{
                    flexDirection: "column",
                    textAlign: "start",
                    padding: "8px 16px 16px !important",
                  }}
                >
                  {files[folder].length > 0 ? (
                    files[folder]?.map((file, idx2) => {
                      //console.log(file);
                      return (
                        <>
                          <div
                            key={idx2}
                            style={{
                              display: "flex",
                              width: "100%",
                              padding: "5px",
                            }}
                          >
                            <FileIcon
                              style={{ marginInlineEnd: "10px" }}
                              onClick={() =>
                                //getFileA(file[Object.keys(file)[0]])

                                window.open(file[1])
                              }
                            />{" "}
                            <Typography
                              color="primary"
                              variant="body2"
                              onClick={() =>
                                //getFileA(file[Object.keys(file)[0]])
                                window.open(file[1])
                              }
                            >
                              {/* {(Object.keys(file) &&
                                Object.keys(file)[0]
                                  .split(".")
                                  .slice(0, -1)
                                  .join(".")) ||
                                Object.keys(file) ||
                                "File"} */}
                              {file[0]}
                            </Typography>
                            <DownloadIcon
                              onClick={() =>
                                //getFileA(file[Object.keys(file)[0]])
                                window.open(file[1])
                              }
                              style={{
                                marginInlineStart: "auto",
                                backgroundColor: "#F1F1F1",
                                borderRadius: "30px",
                                padding: "3px",
                                marginInlineEnd: "15px",
                                cursor: "pointer",
                              }}
                            />
                          </div>
                        </>
                      );
                    })
                  ) : (
                    <Typography variant="body2" color="primary">
                      אין קבצים להצגה
                    </Typography>
                  )}
                </AccordionDetails>
              </Accordion>
            );
          })
        ) : (
          <Typography variant="body2" color="primary">
            אין קבצים להצגה
          </Typography>
        )}
      </div>
    </Box>
    //</Fade>
  );
}
