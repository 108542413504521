import React, { useContext } from "react";
import { GlobalData } from "../Contexts";

export default function Text({ page, objectName }) {
  const context = useContext(GlobalData);
  const language = context.language;
  if (
    !page ||
    !objectName ||
    !dictionary[page] ||
    !dictionary[page][objectName]
  ) {
    return "UNKNOW KEYWORD";
  }
  return dictionary[page][objectName][language];
}

export const dictionary = {
  header: { hello: { en: "Hello", he: "שלום" } },
  mainpage: {
    balance: { en: "Account Balance", he: "יתרה בחשבון" },
    grantBtn: { en: "Make a grant", he: "המלצה למענק" },
    sendAccount: {
      en: "Send me my account details",
      he: "שלחו לי את פרטי תיק ההשקעות שלי",
    },
    sentAccount: {
      en: "Request sent successfully",
      he: "בקשה נשלחה בהצלחה",
    },
    depositBtn: { en: "Deposit request", he: "בקשת הפקדה" },
    grantHistory: { en: "Grant history", he: "היסטוריית מענקים" },
    totalGrantsFromStart: {
      en: "Total grants for ",
      he: 'סה"כ מענקים לשנת ',
    },
    fiscalYear: { en: "Fiscal Year ", he: "לפי שנת המס " },
    itrot: { en: "Transactions", he: "תנועות בחשבון" },
    download: { en: "Download report to Excel", he: 'להורדת הדו"ח לאקסל' },
    addToAccount: { en: "Add to account", he: "בקשת הפקדה" },
    GrantRecommendation: { en: "Grant recommendation", he: "המלצה למענק" },
    investmentDetails: {
      en: "Investment info",
      he: "פירוט תיק השקעות",
    },
    requestsAcc: { en: "Recent requests", he: "סטטוס בקשות אחרונות" },
    grantsAcc: { en: "Recent grants", he: "היסטוריית מענקים" },
    foreignExchange_percents: {
      en: "Foreign Currency",
      he: 'מטבע חוץ',
    },
    cash_percents: { en: "Cash", he: "מזומן" },
    corbonds_percents: { en: "Corp. bonds", he: ' אג״ח קונצרניות' },
    govbond_percents: { en: "Govt. bonds", he: 'אג"ח ממשלתי' },
    stock_percents: { en: "Shares", he: "מניות" },
    my_invests: { en: "Portfolio", he: "תיק השקעות" },
    total_grants: { en: "Total grants", he: 'סה"כ מענקים לחשבון' },
    yieldFromStart: { en: "Cumulative yield:", he: "תשואה מתחילת פעילות:" },
    yieldOfThisYear: { en: "YTD yield:", he: "תשואה מתחילת שנה:" },
  },
  ngos: {
    title: { en: "text here...", he: "טקסט פה" },
    searchBar: { en: "text here...", he: "טקסט פה" },
    filter: { en: "filter", he: "סינון" },
    recommendBtn: { en: "Recommendation for a grant", he: "המלצה למענק" },
    moreInfoBtn: { en: "More Info", he: "למידע נוסף" },
    filterOrg: { en: "Filter organizations", he: "סינון עמותות" },
    CharitableActivity: { en: "Charitable activity", he: "תחום פעילות" },
    location: { en: "Location", he: "איזור פעילות" },
    sizeFin: { en: "Size - financials", he: "גודל - מחזור שנתי" },
    sizeEmp: { en: "Size - number of employees", he: "גודל - מס' עובדים" },
    filterThis: { en: "Filter", he: "לביצוע סינון" },
    listOfOrg: { en: "List of organizations", he: "רשימת עמותות" },
    status: { en: "Status", he: "סטטוס עמותה" },
    searchNgos: { en: "Find an organization", he: "חיפוש עמותות" },
    searchAll: { en: "All", he: "כל העמותות" },
    approved: { en: "Approved", he: "מאושר" },
    noResults: { en: "No results found", he: "לא נמצאו תוצאות חיפוש" },
  },
  status: {
    title: { en: "Requests in progress", he: "סטטוס ומעקב בקשות" },
    searchBar: { en: "Search request history", he: "חיפוש בקשות" },
    showMoreButton: { en: "Show more...", he: "הצג עוד..." },
    grantbutton: { en: "Grant recommendation", he: "המלצה למענק" },
    accountbutton: { en: "Add to account", he: "הפקדה לחשבון" },
    questionbutton: { en: "Question about account", he: "פנייה בנושא הפקדות" },
    genralbutton: { en: "General request", he: "פנייה כללית / בנושא אחר" },
    secondtitle: { en: "Additional requests", he: "פעולות נוספות" },
    noResults: { en: "No requests in progress", he: "אין בקשות להצגה" },
    for: { en: " for ", he: " עבור " },
    contactName: { en: "Full name:", he: "שם מלא:" },
    contactPhone: { en: "Phone:", he: "מס' נייד:" },
    contactMail: { en: "Mail:", he: "כתובת מייל:" },
    contactDetails: { en: "NGO Contact info", he: "פרטי איש קשר בעמותה" },
    details: { en: "Details:", he: "מהות הבקשה:" },
  },
  history: {
    title: { en: "Grant history", he: "היסטורית מענקים" },
    noNgoData: { en: "No NGO data", he: "אין מידע עמותה" },
    searchBar: { en: "Search grant history", he: "חיפוש בהיסטורית מענקים" },
    openFilterButton: { en: "Filter", he: "סינון" },
    regrantbutton: { en: "Repeat grant", he: "ביצוע מענק חוזר" },
    filtertitle: { en: "Filter grant history", he: "סינון היסטורית מענקים" },
    filteryear: { en: "Year", he: "שנה" },
    filterngo: { en: "Organization", he: "לפי עמותה" },
    filtersubmit: { en: "Filter", he: "לביצוע סינון" },
    noHistory: { en: "No history to show", he: "אין היסטוריה להצגה" },
    lastGrant: { en: "Last grant made on ", he: "מענק אחרון בוצע ב-" },
    onSum: { en: " in the amount of:", he: ", בסך" },
    noInfo: { en: "No information", he: "אין מידע" },
    moreInfo: { en: "More information", he: "מידע נוסף" },
    closeBtn: { en: "Close", he: "סגירה" },
  },
  newrequest: {
    error: {
      en: "There has been an error sending the request",
      he: "שגיאה בשליחת הבקשה",
    },
    general: { en: "General", he: "כללי" },
    deposits: { en: "Deposits", he: "הפקדות" },
    newDeposit: { en: "New deposit", he: "הפקדה חדשה" },

    title: { en: "New request", he: "בקשה חדשה" },
    sentMsg: {
      en: "Your message was sent successfully!",
      he: "הודעתך נשלחה בהצלחה!",
    },
    submitButton: { en: "Submit request", he: "לשליחת הבקשה" },
    departmentDrop: { en: "Request subject", he: "בקשה בנושא" },
    cancel: { en: "Cancel Request", he: "בטל הפקדה" },
    change: { en: "Change Request", he: "שנה הפקדה" },
    investments: { en: "Deposits", he: "השקעות" },

    requestTypeDrop: { en: "Type of request", he: "סוג בקשה" },
    requestContent: {
      en: "Please describe your request here",
      he: "פרטו את מהות הבקשה כאן",
    },
  },
  navbar: {
    dashboard: { en: "Dashboard", he: "דף פירוט ראשי" },
    itrot: { en: "Transactions", he: "תנועות בחשבון" },
    requests: { en: "Recent requests", he: "סטטוס ומעקב בקשות" },
    ngos: { en: "Find an organization", he: "חיפוש עמותות" },
    history: { en: "Search grants history", he: "היסטורית מענקים" },
    messages: { en: "Messages", he: "הודעות מקשת" },
    logout: { en: "Logout", he: "יציאה" },
    files: { en: "Reports and receipts", he: "דוחות וקבלות" },
  },
  login: {
    or: { en: "Or", he: "או" },
    "mail success": {
      en: "Check your e-mail inbox for the link to log in.",
      he: "יש לבדוק את תיבת המייל ולפתוח את לינק ההתחברות ",
    },
    error: {
      en: "There is a problem with this phone number, please contact customer support.",
      he: "יש בעיה עם מספר הטלפון - אנא פנה לתמיכה",
    },
    title: { en: "Log in", he: "כניסה למערכת" },
    phoneSubTitle: {
      en: "Type in your mobile number or Email and a verification code will be sent to you",
      he: "הקלידו מס׳ טלפון או כתובת מייל ותשלח אליכם סיסמא לאימות",
    },

    phoneBtn: {
      en: "Send me a verification code",
      he: "המשך לקבלת סיסמה",
    },
    phonePlaceholder: {
      en: "Enter your mobile",
      he: "הקלידו מס' טלפון",
    },
    smsPlacholder: {
      en: "Enter the verification code",
      he: "סיסמה שנשלחה ב-SMS",
    },
    emailPlacholder: {
      en: "Email",
      he: "אימייל",
    },
    smsBtn: { en: "Login", he: "לכניסה" },
    smsResend: { en: "Resend code", he: "שלחו לי SMS בשנית" },
  },
  multi: {
    error: {
      en: "There is a problem with this phone number, please contact customer support.",
      he: "יש בעיה עם מספר הטלפון - אנא פנה לתמיכה",
    },
    title: { en: "Hello, ", he: "שלום, " },
    select: {
      en: "Select",
      he: "לבחירה",
    },
    current: {
      en: "Current",
      he: "חשבון נוכחי",
    },
    choose: {
      en: "Please choose an account:",
      he: "רשימת חשבונות לבחירה",
    },
    phonePlaceholder: {
      en: "Enter your mobile",
      he: "הקלידו מס' טלפון",
    },
    smsPlacholder: {
      en: "Enter the verification code",
      he: "סיסמה שנשלחה ב-SMS",
    },
    smsBtn: { en: "Login", he: "לכניסה" },
    smsResend: { en: "Resend code", he: "שלחו לי SMS בשנית" },
  },
  donation: {
    title: { en: "Grant Recommendation", he: "המלצה למענק" },
    editBtn: { en: "Edit grant", he: "עריכת מענק" },
    addToAccount: { en: "Add to Account", he: "הפקדה לחשבון" },
    QuestionAccount: { en: "Question about Account", he: "פנייה בנושא הפקדות" },
    GeneralRequest: { en: "general request", he: "פנייה כללית" },
    amount: { en: "Amount", he: "סכום המענק" },
    programPHolder: { en: "Program name", he: "שם התוכנית" },
    otherGoal: { en: "Please fill grant goal", he: "פרטו את מטרת המענק" },
    ils: { en: "ILS", he: 'ש"ח' },
    generalSupport: { en: "General support", he: "תמיכה כללית" },
    other: { en: "Other", he: "אחר" },
    donorDetails: {
      en: "Enter name of donor(s)",
      he: "אחר - פרטי התורמים כאן",
    },
    chooseDonors: { en: "Choose donor details", he: "בחירת פרטי התורמים" },
    supportProgram: { en: "In support of a program", he: "תמיכה בתכנית" },

    goal: { en: "Grant purpose", he: "מטרת המענק" },
    nextBtn: { en: "Continue", he: "המשך" },
    notification: { en: "Grant notification", he: "המענק יינתן בשם" },
    notifictionSubTitle: {
      en: "A grant award letter will be sent to the organization with the following info:",
      he: "הודעה לעמותה תשלח עם הפרטים שלך:",
    },
    anonSwitch: {
      en: "Click for anonymous grant",
      he: "לחצו כאן למענק אנונימי",
    },
    anonSwitchOn: {
      en: "Click here to choose donor name(s)",
      he: "לחצו כאן לבחירת שם התורם/ת",
    },
    founder: { en: "Princial Account Founder", he: "שם מייסד עיקרי" },
    secFounder: { en: "Secondary Account Founder", he: "שם מייסד המשני" },
    accountName: { en: "Keshet DAF Account Name", he: "שם החשבון בקשת" },
    anonymous: { en: "Anonymous", he: "אנונימי" },
    comments: { en: "Comments", he: "הערות לצוות קשת" },
    contactTitle: {
      en: "Do you have contact details of an employee at the organization?",
      he: "האם יש לך פרטי איש קשר בעמותה?",
    },
    contactSubTitle: {
      en: "Contact person at the organization",
      he: "פרטי איש  אשת הקשר בעמותה:",
    },
    yes: { en: "Yes", he: "כן" },
    no: { en: "No", he: "לא" },
    fullName: { en: "Full name", he: "שם מלא" },
    mobileNum: { en: "Mobile number", he: "מס' נייד" },
    email: { en: "Email address", he: "כתובת מייל" },
    termsTitle: { en: "Terms of grant transfer", he: "תנאי העברת מענק" },
    terms: {
      en: "I hereby confirm that I am aware that this recommendation will be examined according to the Fund’s practices, including its compliance with the Fund’s criteria as specified in the Fund’s Guide for Contribution Account Holders, and the final decision is entrusted to the Fund’s grant committee team.\nI confirm that I, and anyone related to the contribution I have made to the Fund and/or to the management thereof, receive no personal benefit from this grant. Furthermore, as far as I am aware, the grant I am recommending is not intended to support a specific person, and the organization and/or the cause that I am recommending are neither engaged nor involved in political-partisan activity, and neither have nor will have a direct or indirect connection to a candidate, electoral list, political faction, party and other suchlike, in Israel or abroad and/or to terrorism.\n After the grant is approved by the Fund, I request that my specific consent be obtained for the public disclosure of my name and the name of anyone related to the donation and/or the management thereof, including the method,form and language of the disclosure.",
      he: "הריני לאשר שידוע לי שהמלצה זו תיבחן על פי המקובל בקרן, לרבות עמידתה בקריטריונים של הקרן כמפורט במדריך למחזיקי חשבונות תרומה בקרן, וההחלטה הסופית נתונה לצוות וועדת המענקים של הקרן.\n אני מאשר/ת שאני, וכל מי שקשור לתרומה שתרמתי לקרן ו/או לניהולה, לא מקבלים טובת הנאה אישית ממענק זה. כמו כן, ככל הידוע לי, המענק עליו אני ממליץ/צה אינו מיועד לתמיכה באדם ספציפי, והארגון ו/או המטרה עליהם אני ממליץ/צה אינו עוסק ואינו מעורב בפעילות פוליטית - מפלגתית ואינו ולא יהיה בעל זיקה ישירה או עקיפה למועמד, רשימה, סיעה, מפלגה וכיוב', בארץ או בחו\"ל. ו/או לפעילות טרור. ככל שהמענק יאושר על ידי הקרן אני מבקש/ת לקבל, בנפרד, את אישורי לפרסום שמי ושם כל מי שקשור לתרומה ו/או לניהולה, לרבות דרך, צורת וניסוח הפרסום.",
    },
    agreeBtn: { en: "I agree", he: "קראתי ואני מסכימ/ה" },
    submitBtn: { en: "Submit", he: "אישור המלצה למענק" },
    grantSummary: { en: "Grant Summary", he: "סיכום המלצה למענק" },
    organization: { en: "Organization:", he: "שם העמותה:" },
    summaryGoal: { en: "Grant purpose:", he: "מטרת המענק:" },
    sum: { en: "Sum:", he: "סכום:" },
    donor: { en: "Donor:", he: "התורמים:" },
    submittedTitle: {
      en: "Your grant recommendation has been submitted!",
      he: "המלצתך התקבלה בהצלחה!",
    },
    submittedSubTitle: {
      en: "Your grant recommendation in the amount of",
      he: "המלצתך למענק בסך",
    },
    submittedApp: {
      en: "was sent to the Keshet team",
      he: "הועברה לטיפול צוות קשת",
    },
    finishBtn: { en: "Go to requests history", he: "מעבר לסטטוס המלצות" },
    logout: { en: "Logout", he: "יציאה" },
  },
  ngo: {
    saif46: { en: "Saif 46", he: "סעיף 46" },
    nihulTakin: { en: "Nihul Takin", he: "ניהול תקין" },
    keshetDonors: { en: "Keshet Donors", he: "תורמי קשת" },
    approved: { en: "Approved", he: "מאושר" },
    midotSeal: { en: "Midot Seal", he: "תו מידות" },
    orgMission: { en: "Organization mission", he: "מטרות העמותה" },
    orgProfile: { en: "Organization profile: ", he: "סוג ארגון: " },
    employees: { en: "Employees: ", he: "מס עובדים: " },
    members: { en: "Members: ", he: "מס חברים: " },
    volunteers: { en: "Volunteers: ", he: "מס מתנדבים: " },
    orgWebsite: { en: "Website", he: "לאתר העמותה" },
    moreInfo: { en: "More info", he: "למידע נוסף" },
    grantRecommendation: { en: "Grant", he: "המלצה למענק" },
    status: { en: "Status: ", he: "סטטוס עמותה: " },
    orgNo: { en: "Organization No.: ", he: "מספר ארגון: " },
    estYear: { en: "Year of establishment: ", he: "שנת יסוד: " },
    orgInfo: { en: "Organization Info", he: "מידע על הארגון" },
  },
  itrot: {
    GeneralAccount: { en: "General account", he: "מזומן למענקים" },
    ManagedPortfolio: { en: "Managed portfolio", he: "תיק מנוהל" },
    PendingGrants: { en: "Pending grants", he: "מענקים עתידיים" },
    AccountBalance: { en: "Account balance", he: "יתרה בחשבון" },
    cash: { en: "General account", he: "מזומן" },
    Managed: { en: "Managed portfolio", he: "מנוהל" },
  },
  notifications: {
    notifications: { en: "Keshet Notifications", he: "הודעות מקשת" },
  },
};

// <Text objectName="grantBtn" page="mainpage" />
