import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import FormControl from "@material-ui/core/FormControl";
import CloseIcon from "@material-ui/icons/Close";
import { Button, TextField, Box } from "@material-ui/core/";
import { openNewTicket } from "../utilities/api";
import Typography from "@material-ui/core/Typography";
import { ReactComponent as SendIcon } from "../img/send.svg";
import { useLocation } from "react-router-dom";
import { Grid, IconButton } from "@material-ui/core";
import { Dialog } from "@material-ui/core";
import { DialogContent } from "@material-ui/core/";
import { DialogTitle } from "@material-ui/core";

import { ReactComponent as RadioB } from "../img/radio-checked.svg";
import { UserData } from "../Contexts";
import Text from "../utilities/Text";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { useLanguage } from "../hooks";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    width: "100%",
    display: "inline-block",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  icon: {
    left: 0,
    right: "auto",
  },
  iconOpen: {
    transform: "none",
  },
  formControlLabel: {
    left: 24,
  },
  selectSelect: {
    paddingLeft: "14px",
  },
  desktop: {
    [theme.breakpoints.up("md")]: {
      marginInlineStart: "22%",
      marginInlineEnd: "20%",
      maxWidth: "1140px",
    },
  },
  cardfield: {
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      paddingRight: "15px",
    },
  },
  // classes for Safari select icon polyfill
  iconHeb: {
    right: "auto",
  },
  iconEng: {
    left: "auto",
  },
}));

export default function NewRequest() {
  const [userCtx, setUserCtx] = React.useContext(UserData);
  const location = useLocation();
  const language = useLanguage();
  const classes = useStyles();
  const [formDetails, setFormDetails] = React.useState({
    ticketType: [
      location?.state?.page === "Add to Account"
        ? "Add to Account"
        : "General request",
    ],
    request: "None",
    details: "",
    userData: userCtx?.user,
  });
  const [page, setPage] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [error, setError] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleFormDetails = (event) => {
    setFormDetails((currentState) => {
      return { ...currentState, [event.target.name]: event.target.value };
    });
  };
  React.useEffect(() => {
    setPage(location?.state?.page);

    setFormDetails((prev) => {
      return {
        ...prev,
        ticketType:
          location?.state?.page === "Add to Account"
            ? "Add to Account"
            : "General request",
      };
    });
  }, [location, useLocation]);

  const resetForm = () => {
    setFormDetails({
      userData: userCtx?.user,
      ticketType: [
        location?.state?.page === "Add to Account"
          ? "Add to Account"
          : "General request",
      ],
      request: "",
      details: "",
    });
  };

  let dropOptions;

  if (location?.state?.page === "Add to Account") {
    dropOptions = (
      <React.Fragment>
        <option key="0" value="Add to Account">
          {Text({ objectName: "newDeposit", page: "newrequest" })}
        </option>
      </React.Fragment>
    );
  }
  if (location?.state?.page === "General request") {
    dropOptions = (
      <React.Fragment>
        <option key="0" value="General request">
          {Text({ objectName: "general", page: "newrequest" })}
        </option>
      </React.Fragment>
    );
  }
  if (location?.state?.page === "" || !location?.state?.page) {
    dropOptions = (
      <React.Fragment>
        <option key="0" value="General request">
          {Text({ objectName: "general", page: "newrequest" })}
        </option>
        <option key="2" value="Add to Account">
          {Text({ objectName: "title", page: "newrequest" })}
        </option>
      </React.Fragment>
    );
  }
  if (location?.state?.page === "Question about Account") {
    dropOptions = (
      <React.Fragment>
        <option key="0" value="None" disabled>
          {Text({
            page: "newrequest",
            objectName: "departmentDrop",
          })}
        </option>
        <option key="1" value="Change Request">
          {Text({
            page: "newrequest",
            objectName: "change",
          })}
        </option>
        <option key="2" value="Cancel Request">
          {Text({
            page: "newrequest",
            objectName: "cancel",
          })}
        </option>
        <option key="3" value="Deposits">
          {Text({
            page: "newrequest",
            objectName: "deposits",
          })}
        </option>
      </React.Fragment>
    );
  }

  const rightTitle = React.useCallback(() => {
    if (location?.state?.page === "Add to Account") {
      return "newDeposit";
    }

    if (location?.state?.page === "General request") {
      return "general";
    }

    if (location?.state?.page === "" || !location?.state?.page) {
      return "general";
    }

    if (location?.state?.page === "Question about Account") {
      return "deposits";
    }
  }, [location]);

  return (
    <React.Fragment>
      <Box px={2} style={{ textAlign: "start" }} className={classes.desktop}>
        <Typography variant="h2" style={{ margin: "16px auto" }}>
          <Text objectName="title" page="newrequest" /> -{" "}
          {Text({ page: "newrequest", objectName: rightTitle() })}
        </Typography>
        <FormControl
          className={classes.formControl}
          style={{
            marginBottom: "8px",
            maxWidth: "382px",
            marginInlineEnd: "15px",
          }}
        >
          <TextField
            name="request"
            color="primary"
            variant="outlined"
            value={formDetails.request}
            select
            className={classes.cardfield}
            SelectProps={{
              native: true,
              IconComponent: KeyboardArrowDownIcon,
              classes: {
                icon: language === "he" ? classes.iconHeb : classes.iconEng,
              },
            }}
            onChange={handleFormDetails}
          >
            {dropOptions}
          </TextField>
        </FormControl>

        {/* This is the extra field that was required and then canceled - Here for future changes or requests
        <FormControl
          className={classes.formControl}
          style={{ marginBottom: "8px", maxWidth: "382px" }}
        >
          <InputLabel style={{ left: 24 }}>סוג הבקשה</InputLabel>
          <TextField
            name="request"
            color="primary"
            variant="outlined"
            value={formDetails.request}
            select
            SelectProps={{
              native: true,
              IconComponent: KeyboardArrowDownIcon,
            }}
            onChange={handleFormDetails}
          >
            <option key="" value="" disabled>
              {Text({
                page: "newrequest",
                objectName: "requestTypeDrop",
              })}
            </option>
            <option key="0" value="1">
              סוג 1
            </option>
            <option key="1" value="2">
              סוג 2
            </option>
          </TextField>
        </FormControl> */}

        <TextField
          style={{ width: "100%", maxWidth: "790px" }}
          multiline={true}
          rows={6}
          id="outlined-basic"
          placeholder={Text({
            page: "newrequest",
            objectName: "requestContent",
          })}
          variant="outlined"
          color="primary"
          value={formDetails.details}
          name="details"
          onChange={handleFormDetails}
          size="large"
        />

        <Button
          style={{ marginTop: "24px", maxWidth: "382px" }}
          disabled={isSubmitting}
          onClick={async (e) => {
            e.preventDefault();
            setIsSubmitting(true);
            const res = await openNewTicket(formDetails);
            if (!res.err) {
              handleClickOpen();
              setError(false);
              resetForm();
            } else {
              setError(true);
            }
            setIsSubmitting(false);
          }}
          variant="contained"
          color="primary"
          startIcon={<SendIcon style={{ marginLeft: 12, stroke: "none" }} />}
        >
          <Text objectName="submitButton" page="newrequest" />
        </Button>
        <div style={{ textAlign: "center" }}>
          {error && (
            <Typography variant="body1" color="error">
              {Text({
                page: "newrequest",
                objectName: "error",
              })}
            </Typography>
          )}
        </div>
      </Box>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          style={{ backgroundColor: "#F8F8F8" }}
          id="form-dialog-title"
        >
          <Grid container alignItems="center">
            <Grid item xs={10}></Grid>
            <Grid item xs={2}>
              <IconButton
                onClick={handleClose}
                style={{ left: 0, position: "absolute" }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <div style={{ marginTop: "28px" }}></div>
        </DialogTitle>
        <DialogContent
          style={{ backgroundColor: "#F8F8F8", textAlign: "center" }}
          onClick={handleClose}
        >
          <RadioB
            style={{
              fill: "#21C082",
              width: "56px",
              height: "56px",
              marginTop: "13vh",
            }}
          />
          <Typography
            style={{
              color: "#21C082",
              fontSize: "32px",
              fontWeight: 700,
              marginTop: "32px",
            }}
          >
            <Text objectName="sentMsg" page="newrequest" />
          </Typography>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
