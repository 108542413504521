import React from "react";
import { useLanguage } from "../../hooks";
import { ReactComponent as LogoIcon } from "../../img/IntroLogo.svg";
import { ReactComponent as LogoIconEng } from "../../img/IntroLogoEng.svg";
import styles from "./Login.module.css";

export default function SplashScreen(props) {
  const language = useLanguage();
  const [fadeCounter, setFadeCounter] = React.useState(props.fade);
  React.useEffect(() => {
    if (props.fade === "hide") {
      setFadeCounter("hide");
      return;
    }
    const timeOut = setTimeout(() => {
      setFadeCounter("hide");
    }, 2000);

    return () => {
      clearTimeout(timeOut);
    };
  }, []);

  return (
    <div className={fadeCounter === "hide" ? styles.hide : styles.splash}>
      {language === "he" ? <LogoIcon /> : <LogoIconEng />}
    </div>
  );
}
