import React from "react";
import { Box, Button, Paper, TextField } from "@material-ui/core/";
import { GlobalData, UserData } from "../Contexts";
import { Typography } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import SimpleAccordion from "./SimpleAccordion";
import { ReactComponent as GiveGrantIcon } from "../img/giveGrant.svg";
import Text from "../utilities/Text";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Workbook } from "exceljs";

export default function Itrot() {
  const globalData = React.useContext(GlobalData);
  const { language } = React.useContext(GlobalData);
  const screenSize = globalData.screenSize;

  const [userCtx, setUserCtx] = React.useContext(UserData);
  const cash = userCtx.user["total_cash"];
  const totalManage = userCtx.user["total_manage"];
  const totalFutureGrant = userCtx.user["total_future_grant"];
  const totalBalance = userCtx.user["total_balance"];
  const cashTransactions = userCtx.user["cash_transactions"];
  const managedTransactions = userCtx.user["managed_trnsaction"];
  let cashColor;
  let totalMangeColor;
  let totalFutureGrantColor;

  if (cash > 0) {
    cashColor = positiveAmount;
  } else {
    cashColor = negativeAmount;
  }

  if (totalManage > 0) {
    totalMangeColor = positiveAmount;
  } else {
    totalMangeColor = negativeAmount;
  }

  if (totalFutureGrant > 0) {
    totalFutureGrantColor = positiveAmount;
  } else {
    totalFutureGrantColor = negativeAmount;
  }

  return (
    <>
      <Wrapper>
        <div>
          <CardWrapper>
            <Card>
              <TittleInCard>
                <Text objectName="GeneralAccount" page="itrot" />
              </TittleInCard>
              <div style={cashColor}>
                {Math.round(cash).toLocaleString("he")} ₪{" "}
              </div>
            </Card>
            {userCtx.user["managed_trnsaction"].length > 0 ? (
              <Card>
                <TittleInCard>
                  <Text objectName="ManagedPortfolio" page="itrot" />
                </TittleInCard>
                <div style={totalMangeColor}>
                  {Math.round(totalManage).toLocaleString("he")} ₪{" "}
                </div>
              </Card>
            ) : (
              <div style={{ display: "none" }}></div>
            )}
            <Card>
              <TittleInCard>
                <Text objectName="PendingGrants" page="itrot" />
              </TittleInCard>
              <div style={totalFutureGrantColor}>
                {Math.round(totalFutureGrant).toLocaleString("he")} ₪{" "}
              </div>
            </Card>
          </CardWrapper>
          <BalanceCard>
            <Typography variant="h4">
              <Text objectName="AccountBalance" page="itrot" />
            </Typography>
            <Typography variant="h1">
              {Math.round(totalBalance).toLocaleString("he")} ₪{" "}
            </Typography>
          </BalanceCard>

          <DetailsCard>
            <DetailsCardHeaderWrapper>
              <DetailsHeader>
                <Text objectName="cash" page="itrot" />
              </DetailsHeader>
              <DetailsHeader>
                {Math.round(cash).toLocaleString("he")} ₪{" "}
              </DetailsHeader>
            </DetailsCardHeaderWrapper>
            <Divider style={{ marginTop: "12px", marginBottom: "12px" }} />

            {cashTransactions
              .sort((a, b) => {
                const [aday, amonth, ayear] = a.date.split(".");
                const [bday, bmonth, byear] = b.date.split(".");
                const aDate = new Date(ayear, amonth - 1, aday);
                const bDate = new Date(byear, bmonth - 1, bday);
                if (aDate < bDate) {
                  return 1;
                } else {
                  return -1;
                }
              })
              .map((cashTransaction) => {
                let sumColor;
                if (cashTransaction.sum > 0) {
                  sumColor = sumColorPositive;
                } else {
                  sumColor = sumColorNegative;
                }
                return (
                  <>
                    <DetailsRowsWrapper>
                      <DetailsRowsDates>
                        {cashTransaction.date}
                      </DetailsRowsDates>
                      {language === "en" ? (
                        <DetailsRowsText>
                          {cashTransaction.translate_eng}
                        </DetailsRowsText>
                      ) : (
                        <DetailsRowsText>
                          {cashTransaction.translate_heb}
                        </DetailsRowsText>
                      )}
                      <DetailsRowsAmount style={sumColor}>
                        {Math.round(cashTransaction.sum).toLocaleString("he")} ₪{" "}
                      </DetailsRowsAmount>
                    </DetailsRowsWrapper>
                  </>
                );
              })}
          </DetailsCard>
          {userCtx.user["managed_trnsaction"].length > 0 ? (
            <DetailsCard>
              <DetailsCardHeaderWrapper>
                <DetailsHeader>
                  <Text objectName="Managed" page="itrot" />
                </DetailsHeader>
                <DetailsHeader>
                  {Math.round(totalManage).toLocaleString("he")} ₪{" "}
                </DetailsHeader>
              </DetailsCardHeaderWrapper>
              <Divider style={{ marginTop: "12px", marginBottom: "12px" }} />

              {managedTransactions
                .sort((a, b) => {
                  const [aday, amonth, ayear] = a.date.split(".");
                  const [bday, bmonth, byear] = b.date.split(".");
                  const aDate = new Date(ayear, amonth - 1, aday);
                  const bDate = new Date(byear, bmonth - 1, bday);
                  if (aDate < bDate) {
                    return 1;
                  } else {
                    return -1;
                  }
                })
                .map((managedTransaction) => {
                  let sumColor;
                  if (managedTransaction.sum > 0) {
                    sumColor = sumColorPositive;
                  } else {
                    sumColor = sumColorNegative;
                  }

                  return (
                    <>
                      <DetailsRowsWrapper>
                        <DetailsRowsDates>
                          {managedTransaction.date}
                        </DetailsRowsDates>
                        {language === "en" ? (
                          <DetailsRowsText>
                            {managedTransaction.translate_eng}
                          </DetailsRowsText>
                        ) : (
                          <DetailsRowsText>
                            {managedTransaction.translate_heb}
                          </DetailsRowsText>
                        )}

                        <DetailsRowsAmount style={sumColor}>
                          {Math.round(managedTransaction.sum).toLocaleString(
                            "he"
                          )}{" "}
                          ₪{" "}
                        </DetailsRowsAmount>
                      </DetailsRowsWrapper>
                    </>
                  );
                })}
            </DetailsCard>
          ) : (
            <div style={{ display: "none" }}></div>
          )}
          <Button
            onClick={async () => {
              const workbook = new Workbook();
              const managedWorksheet = workbook.addWorksheet(
                "Managed Transactions"
              );

              const schema = [
                {
                  header: "Transaction",
                  key: language === "he" ? "translate_heb" : "translate_eng",
                },
                { header: "Date", key: "date" },
                { header: "Box", key: "box" },
                {
                  header: "Credit",
                  key: "credit",
                  style: { numFmt: "#,##0.00" },
                },
                {
                  header: "Debit",
                  key: "debit",
                  style: { numFmt: "#,##0.00" },
                },
                {
                  header: "Balance",
                  key: "calculated_sum",
                  style: { numFmt: "#,##0.00" },
                },
              ];
              const englishDict = { מנוהל: "Managed", מזומן: "Cash" };
              managedWorksheet.columns = schema;
              managedTransactions.forEach((transaction) => {
                if (language === "en") {
                  transaction.box = englishDict[transaction.box];
                }
                managedWorksheet.addRow(transaction);
              });
              const cashWorksheet = workbook.addWorksheet("Cash Transactions");
              cashWorksheet.columns = schema;
              cashTransactions.forEach((transaction) => {
                if (language === "en") {
                  transaction.box = englishDict[transaction.box];
                }

                cashWorksheet.addRow(transaction);
              });
              workbook.xlsx
                .writeBuffer()
                .then((buffer) => {
                  const blob = new Blob([buffer], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                  });
                  const url = window.URL.createObjectURL(blob);
                  const anchor = document.createElement("a");
                  anchor.href = url;
                  anchor.download = "Transactions.xlsx";
                  anchor.click();
                })
                .catch((err) => {
                  console.error(err);
                });
            }}
            style={{ width: 200, marginBottom: 12, marginTop: 12 }}
            variant="contained"
            color="primary"
          >
            <Text page={"mainpage"} objectName={"download"} />
          </Button>
        </div>

        {screenSize ? (
          <div>
            <Link to="/ngos" style={{ textDecoration: "none", width: "165px" }}>
              {" "}
              <Button variant="contained" color="primary">
                <GiveGrantIcon style={{ marginInlineEnd: "11px" }} />{" "}
                <Text objectName="grantBtn" page="mainpage" />
              </Button>
            </Link>{" "}
            <SimpleAccordion />
          </div>
        ) : (
          <div></div>
        )}
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 49px;
  justify-content: space-around;
`;

const CardWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 2%;
`;

const Card = styled.div`
  background: #f8f8f8;
  border-radius: 24px;
  display: inline-block;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  padding: 8px 12px;
  width: 28%;
  height: 9%;
`;

const TittleInCard = styled.div`
  font-family: Ploni;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: center;
`;

const BalanceCard = styled.div`
  background: #f8f8f8;
  border-radius: 24px;
  display: inline-block;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  padding: 8px 12px;
  width: 92%;
  direction: rtl;
`;

const DetailsCard = styled.div`
  display: inline-block;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  padding: 8px 12px;
  width: 92%;
  border-radius: 16px;
  border: 1px solid #d1e4ec;
`;

const DetailsCardHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DetailsHeader = styled.div`
  font-family: Ploni;
  font-size: 20px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0.01em;
  text-align: right;
  color: #004e5e;
  direction: rtl;
`;

const DetailsRowsWrapper = styled.div`
  display: flex;
`;
const DetailsRowsDates = styled.div`
  width: 25%;
  text-align: start;
`;
const DetailsRowsText = styled.div`
  width: 50%;
  text-align: start;
`;
const DetailsRowsAmount = styled.div``;

const positiveAmount = {
  color: "#21C082",
  fontFamily: "Ploni ",
  fontSize: "18px",
  fontWeight: "700",
  lineHeight: "20px",
  letterSpacing: "0.01em",
  textAlign: "center",
  direction: "rtl",
};
const negativeAmount = {
  color: "#000000",
  fontFamily: "Ploni ",
  fontSize: "18px",
  fontWeight: "700",
  lineHeight: "20px",
  letterSpacing: "0.01em",
  textAlign: "center",
  direction: "rtl",
};

const sumColorPositive = {
  color: "#21C082",
  width: "25%",
  textAlign: "right",
  direction: "rtl",
};

const sumColorNegative = {
  color: "#000000",
  width: "25%",
  textAlign: "right",
  direction: "rtl",
};
