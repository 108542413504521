import React from "react";
import { Doughnut } from "react-chartjs-2";
import colorPallete, { numFormatter } from "../utilities/utils";
import { Typography } from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import Text from "../utilities/Text";
import GrantsCarousel from "./GrantsCarousel";
import { useLanguage } from "../hooks";
import styled from "styled-components";

const DoughnutChartDesktop = (props) => {
  const language = useLanguage();
  const labels = [];
  const amounts = [];
  const toCarousel = [];

  if (language === "he") {
    props.userData &&
      props.userData.user.grants_sum_table.forEach((item) => {
        labels.push(item[0]);
        amounts.push(item[1]);
      });
  } else {
    props.userData &&
      props.userData.user.grants_sum_table_ENG.forEach((item) => {
        labels.push(item[0]);
        amounts.push(item[1]);
      });
  }
  const textToCarousel = Text({
    objectName: "totalGrantsFromStart",
    page: "mainpage",
  });
  const dataToCarousel = props.userData.user.grants_sum_by_year;

  dataToCarousel.map((item) => {
    toCarousel.push({
      name: textToCarousel + item.year + ":",
      description: item.sum,
    });
  });
  let sum = props.userData.user.total_grant;
  const colors = colorPallete;

  const data = {
    labels: labels,
    datasets: [
      {
        data: amounts,
        backgroundColor: colors,
        borderColor: colors,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    cutout: "85%",
    afterBodyFontColor: "red",

    layout: {
      padding: {
        top: 50,
      },
    },
    plugins: {
      tooltip: {
        position: "nearest",
        yAlign: "center",
        xAlign: "center",
        backgroundColor: "#FFFFFF",
        bodyColor: "#161616",
        displayColors: false,
        bodyAlign: "center",
        titleAlign: "center",
        bodyFont: {
          size: 16,
          weight: "700",
          family: "Ploni",
        },
        rtl: true,

        callbacks: {
          label: function (context) {
            return context.formattedValue + " ₪ ";
          },
          afterBody: function (context) {
            return context[0].label;
          },
        },
      },
      legend: {
        maxWidth: 50,

        display: false,
        position: "bottom",
        labels: {
          color: "#161616",
          boxWidth: 8,
          boxHeight: 8,

          font: {
            family: "ploni",
            size: "14px",
            weight: "normal",
          },
        },
      },
    },
  };

  return (
    <>
      <Wrapper>
        <CarouselWrapper>
          <GrantsCarousel labels={toCarousel} />
          <div className="links"></div>
        </CarouselWrapper>
        <DoughnutContainer>
          <DoughnutArea>
            <Doughnut data={data} options={options} />
            <TotalSumInDoughnutWrapper>
              <Fade in={true} timeout={1000}>
                <TotalSumInDoughnut>
                  <AmountInDoughnut>{numFormatter(sum)}</AmountInDoughnut>
                  <TextUnderAmountInDoughnut>
                    <Text objectName="total_grants" page="mainpage" />
                  </TextUnderAmountInDoughnut>
                </TotalSumInDoughnut>
              </Fade>
            </TotalSumInDoughnutWrapper>
          </DoughnutArea>

          <LegendContainer>
            {labels.map((label, index) => {
              return (
                <LegendItem>
                  <LegendItemDot
                    style={{ backgroundColor: colors[index] }}
                  ></LegendItemDot>
                  <LegendItemText>{label}</LegendItemText>
                </LegendItem>
              );
            })}

            {labels.length % 3 !== 0 && <LegendItem></LegendItem>}
          </LegendContainer>
        </DoughnutContainer>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  width: 690px;
  margin: auto;
  display: flex;
  flex-direction: column;
`;
const CarouselWrapper = styled.div`
  padding: 50px 200px 0;
`;
const DoughnutContainer = styled.div`
  width: 690px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-bottom: 50px;
`;
const DoughnutArea = styled.div`
  position: relative;
  display: flex;
`;
const TotalSumInDoughnutWrapper = styled.div`
  position: absolute;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;
const TotalSumInDoughnut = styled.div`
  display: flex;
  flex-direction: column;
`;
const AmountInDoughnut = styled(Typography)`
  position: relative;
  margin: auto;
  font-family: Ploni;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
`;
const TextUnderAmountInDoughnut = styled(Typography)`
  position: relative;
  margin: auto;
  font-family: Ploni;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
`;
const LegendContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
  flex-direction: column;
  justify-content: center;
`;
const LegendItem = styled.div`
  min-width: 176px;
  width: fit-content;
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 25px;
`;

const LegendItemDot = styled.div`
  position: relative;
  width: 8px;
  height: 8px;
  top: 8px;
  inset-inline-start: -6px;
  border-radius: 2px;
`;
const LegendItemText = styled.div`
  text-align: start;
  margin-right: 12px;
  position: relative;
`;

export default DoughnutChartDesktop;
