import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

export default function KStepper(props) {
  const [step, setStep] = React.useState(props?.step);
  const setExStep = props?.setExStep;
  const skipStep = props?.skipStep;

  React.useEffect(() => {
    setStep(props?.step);
  }, [props?.step]);

  const [stepColor, setStepColor] = React.useState({
    1: "#EA7B7E",
    2: "#E4E4E4",
    3: "#E4E4E4",
  });

  function handleStep(step) {
    switch (step) {
      case 1:
        setStepColor({ 1: "#EA7B7E", 2: "#E4E4E4", 3: "#E4E4E4" });
        break;
      case 2:
        setStepColor({ 1: "#8C8C8C", 2: "#EA7B7E", 3: "#E4E4E4" });
        break;
      case 3:
        setStepColor({ 1: "#8C8C8C", 2: "#8C8C8C", 3: "#EA7B7E" });
        break;
      case 4:
        setStepColor({ 1: "#8C8C8C", 2: "#8C8C8C", 3: "#EA7B7E" });
        break;
    }
  }
  React.useEffect(() => {
    handleStep(step);
  }, [step]);
  return (
    <Grid container style={{ textAlign: "center" }}>
      <Grid item>
        <div>
          <Typography
            variant="h5"
            style={{ color: [stepColor[1]] }}
            onClick={step >= 1 && !skipStep ? () => setExStep(1) : null}
          >
            1
          </Typography>
          <div
            style={{
              backgroundColor: [stepColor[1]],
              borderRadius: "8px",
              height: "2px",
              width: "50px",
            }}
          ></div>
        </div>
      </Grid>
      <Grid item>
        <div>
          <Typography
            variant="h5"
            style={{ color: [stepColor[2]] }}
            onClick={step >= 2 && !skipStep ? () => setExStep(2) : null}
          >
            2
          </Typography>
          <div
            style={{
              backgroundColor: [stepColor[2]],
              borderRadius: "8px",
              height: "2px",
              width: "50px",
            }}
          ></div>
        </div>
      </Grid>
      <Grid item>
        <div>
          <Typography variant="h5" style={{ color: [stepColor[3]] }}>
            3
          </Typography>
          <div
            style={{
              backgroundColor: [stepColor[3]],
              borderRadius: "8px",
              height: "2px",
              width: "50px",
            }}
          ></div>
        </div>
      </Grid>
    </Grid>
  );
}
