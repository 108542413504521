import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, TextField } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Text from "../../utilities/Text";
import { useLanguage } from "../../hooks";

const useStyles = makeStyles((theme) => ({
  iconHeb: {
    right: "auto",
  },
  iconEng: {
    left: "auto",
  },
  errorSelect: {
    "& .MuiOutlinedInput-input": {
      color: "red",
    },
    "& .MuiOutlinedInput-root": {
      marginBottom: "24px",
      backgroundColor: "#FFFFFF",
    },
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      paddingRight: "10px",
    },
    "& .MuiSelect-select": {
      "&:focus": {
        backgroundColor: "#FFF",
      },
    },
  },
  cardfield: {
    "& .MuiOutlinedInput-root": {
      marginBottom: "24px",
      backgroundColor: "#FFFFFF",
    },
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      paddingRight: "10px",
    },
    "& .MuiSelect-select": {
      "&:focus": {
        backgroundColor: "#FFF",
      },
    },
  },

  currency: {
    backgroundColor: "#FFF",
    borderRadius: "16px",
    width: "56px",
    height: "32px",
    color: "#8C8C8C",
    fontWeight: 400,
    fontSize: 14,
  },
  currencyActive: {
    backgroundColor: "#C2EBED",
    borderRadius: "16px",
    width: "20%",
    border: "1px solid #161616",
    height: "32px",
    fontWeight: 400,
    fontSize: 14,
  },
}));

export default function FormStep1({ sendData, data }) {
  const language = useLanguage();
  const [formData, setFormData] = React.useState({
    grantSum: data?.grantSum || "",
    grantCurrency: data?.grantCurrency || "NIS",
    grantGoal: data?.grantGoal || "",
    programName: data?.programName || "",
    otherGoal: data?.otherGoal || "",
  });
  //const [error, setError] = React.useState(true);
  React.useEffect(() => {
    sendData(formData);
  }, [formData]);
  const classes = useStyles();
  return (
    <React.Fragment>
      <TextField
        className={classes.cardfield}
        autoFocus
        id="grantSum"
        type="text"
        value={formData.grantSum}
        placeholder={Text({
          page: "donation",
          objectName: "amount",
        })}
        variant="outlined"
        required
        onChange={(e) => {
          if(isNaN(e.target.value)){
            console.log("not a number!")
            return
          }
          setFormData((prev) => {
            return { ...prev, [e.target.id]: e.target.value };
          });
        }}
      />
      <div style={{ marginBottom: "24px", display: "flex" }}>
        <Button
          id="NIS"
          className={
            formData.grantCurrency === "NIS"
              ? classes.currencyActive
              : classes.currency
          }
          onClick={() =>
            setFormData((prev) => {
              return { ...prev, grantCurrency: "NIS" };
            })
          }
        >
          <Text objectName="ils" page="donation" />
        </Button>
        <Button
          id="USD"
          style={{ marginRight: "15px" }}
          className={
            formData.grantCurrency === "USD"
              ? classes.currencyActive
              : classes.currency
          }
          onClick={() =>
            setFormData((prev) => {
              return { ...prev, grantCurrency: "USD" };
            })
          }
        >
          USD
        </Button>
      </div>
      <TextField
        //className={error ? classes.errorSelect : classes.cardfield}
        className={classes.cardfield}
        placeholder={Text({
          page: "donation",
          objectName: "goal",
        })}
        id="grantGoal"
        //error={error}
        select
        fullWidth
        value={formData.grantGoal}
        onChange={(e) => {
          setFormData((prev) => {
            return {
              ...prev,
              programName: "",
              otherGoal: "",
              [e.target.id]: e.target.value,
            };
          });
        }}
        SelectProps={{
          native: true,
          IconComponent: KeyboardArrowDownIcon,
          classes: {
            icon: language === "he" ? classes.iconHeb : classes.iconEng,
          },
        }}
        variant="outlined"
        color="primary"
      >
        <option key="" value="" disabled>
          {Text({
            page: "donation",
            objectName: "goal",
          })}
        </option>

        <option value="General Support">
          {Text({ page: "donation", objectName: "generalSupport" })}
        </option>
        <option value="In Support of a Program">
          {" "}
          {Text({ page: "donation", objectName: "supportProgram" })}
        </option>
        <option value="Other">
          {" "}
          {Text({ page: "donation", objectName: "other" })}
        </option>
      </TextField>
      {formData.grantGoal === "In Support of a Program" && (
        <TextField
          className={classes.cardfield}
          id="programName"
          type="text"
          value={formData.programName}
          placeholder={Text({
            page: "donation",
            objectName: "programPHolder",
          })}
          variant="outlined"
          onChange={(e) => {
            setFormData((prev) => {
              return { ...prev, [e.target.id]: e.target.value };
            });
          }}
        />
      )}
      {formData.grantGoal === "Other" && (
        <TextField
          className={classes.cardfield}
          id="otherGoal"
          type="text"
          value={formData.otherGoal}
          placeholder={Text({
            page: "donation",
            objectName: "otherGoal",
          })}
          variant="outlined"
          onChange={(e) => {
            setFormData((prev) => {
              return { ...prev, [e.target.id]: e.target.value };
            });
          }}
        />
      )}
    </React.Fragment>
  );
}
