import React from "react";
import { Grid, Typography, Paper } from "@material-ui/core";
import Text from "../utilities/Text";

export default function NotificationCardInfo(props) {
  let grantsGoal;
  if (props.cardData.grantGoal === "In Support of a Program") {
    grantsGoal = props?.cardData.programName;
  } else if (props.cardData.grantGoal === "Other") {
    grantsGoal = props?.cardData.otherGoal;
  } else if (props.cardData.grantGoal === "General Support") {
    grantsGoal = <Text objectName="generalSupport" page="donation" />;
  } else {
    <Text objectName="noInfo" page="history" />;
  }

  return (
    <Paper
      elevation={0}
      style={{
        border: "1px solid #D1E4EC",
        borderRadius: "16px",
        boxSizing: "border-box",
        padding: "24px",
        margin: "16px 8px",
      }}
    >
      <Typography variant="h4" color="primary" style={{ marginBottom: "12px" }}>
        {/* <Text objectName="grantSummary" page="donation" /> */}
        <Text objectName="moreInfo" page="history" />
      </Typography>
      {/* <div className={classes.finishCard}> */}
      <Grid container>
        <Grid item xs={5}>
          <Typography variant="body2" color="primary">
            {/* <Text objectName="organization" page="donation" /> */}
            <Text objectName="donor" page="donation" />
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography variant="h5" style={{ color: "#004E5E" }}>
            {props.cardData.grant_acknowledgement || (
              <Text objectName="noInfo" page="history" />
            )}
          </Typography>
        </Grid>
      </Grid>
      {/* </div> */}
      <div
        style={{ borderTop: "1px dashed #E4E4E4", margin: "12px auto" }}
      ></div>

      <Grid container>
        <Grid item xs={5}>
          <Typography variant="body2" color="primary">
            <Text objectName="summaryGoal" page="donation" />
          </Typography>
        </Grid>
        <Grid item xs={7}>
          {grantsGoal || <Text objectName="noInfo" page="history" />}
        </Grid>
      </Grid>
      <div
        style={{ borderTop: "1px dashed #E4E4E4", margin: "12px auto" }}
      ></div>

      <Grid container>
        <Grid item xs={12}>
          <Typography variant="body2" color="primary">
            <Text objectName="comments" page="donation" />:
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" style={{ wordWrap: "break-word" }}>
            {props.cardData.contactNotes || (
              <Text objectName="noInfo" page="history" />
            )}
          </Typography>
        </Grid>
      </Grid>
      <div
        style={{ borderTop: "1px dashed #E4E4E4", margin: "12px auto" }}
      ></div>
      <Typography variant="h5" color="primary" style={{ marginBottom: "12px" }}>
        {/* <Text objectName="grantSummary" page="donation" /> */}
        <Text objectName="contactDetails" page="status" />
      </Typography>
      <Grid container>
        <Grid item xs={5}>
          <Typography variant="body2" color="primary">
            {/* <Text objectName="donor" page="donation" /> */}
            <Text objectName="contactName" page="status" />
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography variant="body2">
            {props.cardData.contactName || (
              <Text objectName="noInfo" page="history" />
            )}
          </Typography>
        </Grid>
      </Grid>
      <div
        style={{ borderTop: "1px dashed #E4E4E4", margin: "12px auto" }}
      ></div>
      <Grid container>
        <Grid item xs={5}>
          <Typography variant="body2" color="primary">
            {/* <Text objectName="donor" page="donation" /> */}
            <Text objectName="contactPhone" page="status" />
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography variant="body2">
            {props.cardData.contactPhone || (
              <Text objectName="noInfo" page="history" />
            )}
          </Typography>
        </Grid>
      </Grid>
      <div
        style={{ borderTop: "1px dashed #E4E4E4", margin: "12px auto" }}
      ></div>
      <Grid container>
        <Grid item xs={5}>
          <Typography variant="body2" color="primary">
            {/* <Text objectName="donor" page="donation" /> */}
            <Text objectName="contactMail" page="status" />
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography variant="body2">
            {props.cardData.contactMail || (
              <Text objectName="noInfo" page="history" />
            )}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
