import {
  Dialog,
  Grid,
  Typography,
  Button,
  Avatar,
  DialogContent,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import Text from "../utilities/Text";
import FixedLogo from "./Login/FixedLogo";
import { AccountsData, UserData } from "../Contexts";
import { useLanguage } from "../hooks";
import { getUserData } from "../utilities/api";
import axios from "axios";
import { useHistory } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    margin: "6px 2px",
    borderRadius: "16px",
  },

  searchfield: {
    "& .MuiOutlinedInput-root.Mui-focused": {
      backgroundColor: "#F8F8F8",
      border: "none",
    },
  },
  cardfield: {
    backgroundColor: "#FFFFFF",
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#FFFFFF",
    },
  },
  dialogPaper: {
    borderRadius: "16px",
    position: "fixed",
    top: 50,
    insetInlineStart: 30,
  },

  startSide: {
    //display: "none",
    [theme.breakpoints.up("md")]: {
      // display: "inline",
      //marginInlineStart: "9.3%",
      //maxWidth: "1140px",
      width: "20%",
      minWidth: "10%",
      //flexGrow: 1,
      flexBasis: "10%",
    },
  },
  endSide: {
    //display: "none",
    [theme.breakpoints.up("md")]: {
      // display: "inline",
      //marginInlineStart: "9.3%",
      //maxWidth: "1140px",
      width: "20%",
      minWidth: "10%",
      //flexGrow: 1,
      flexBasis: "10%",
    },
  },
  cardDiv: {
    [theme.breakpoints.up("md")]: {
      width: "31%",
      marginInlineEnd: "24px",
      maxWidth: "384px",
      minWidth: "384px",
    },
  },
  desktop: {
    [theme.breakpoints.up("md")]: {
      marginInlineStart: "300px",
      marginInlineEnd: "300px",
      maxWidth: "1140px",
    },
  },
  desktopTitle: {
    [theme.breakpoints.up("md")]: {
      marginInlineStart: "300px",
      marginInlineEnd: "300px",
      maxWidth: "1140px",
      marginTop: "165px",
    },
  },
  closeBtn: {
    width: "18px",
    height: "18px",
    [theme.breakpoints.up("md")]: {
      width: "30px",
      height: "30px",
    },
  },
}));

export default function MultiDialog(props) {
  const [userCtx, setUserCtx] = React.useContext(UserData);
  const [userAccounts, setUserAccounts] = React.useContext(AccountsData);
  const [loading, setLoading] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const classes = useStyles();
  const language = useLanguage();
  const history = useHistory();
  const currentId = localStorage?.getItem("userAccount");
  async function handleClick(id) {
    axios.defaults.headers.common["keshet_account"] = id;
    localStorage.setItem("userAccount", id);
    console.log("pushed ", id);
    setLoading(id);
    setDisabled(true);
    const res = await getUserData();
    console.log(res);
    if (res.status === 200) {
      setLoading(false);
      setDisabled(false);
      setUserCtx({ user: res.data });
      props.close(false);
      history.push("/");
    } else {
      history.push("/login");
    }
  }
  const firstLetters = (name) => {
    if (name?.split(" ").length > 1) {
      return name?.split(" ")[0].charAt(0) + name?.split(" ")[1].charAt(0);
    } else {
      return "OO";
    }
  };
  return (
    // <Dialog
    //   open={props.open}
    //   PaperProps={{ classes: { root: classes.dialogPaper } }}
    // >
    <DialogContent
      style={{
        backgroundColor: "#F8F8F8",
        textAlign: "center",
      }}
    >
      <div style={{ textAlign: "start" }}>
        <Typography variant="h2">
          {" "}
          <Text objectName="title" page="multi" />
          {language === "he"
            ? userAccounts?.accounts[0]?.Content_name_HEB
            : userAccounts?.accounts[0]?.Content_name_ENG}
        </Typography>
        <Typography variant="body2" style={{ marginBottom: 24 }}>
          <Text objectName="choose" page="multi" />
        </Typography>
      </div>
      <div style={{ textAlign: "center" }}>
        {userAccounts &&
          userAccounts?.accounts?.map((item, index) => {
            return (
              <React.Fragment>
                <Grid
                  container
                  spacing={1}
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center"
                >
                  <Grid item xs={2}>
                    <Avatar
                      alt="{avatarLetters}"
                      style={{
                        backgroundColor: "#FFFFFF",
                        color:
                          currentId === item.Account_id ? "red" : "#004E5E",
                        fontWeight: 700,
                        //   marginInlineStart: "8px",
                      }}
                    >
                      {firstLetters(item.Account_name_HEB)}
                    </Avatar>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="body2"
                      color={
                        currentId === item.Account_id ? "secondary" : "primary"
                      }
                      //style={{ margin: "0px 8px" }}
                    >
                      {item.Account_name_HEB}
                    </Typography>
                  </Grid>

                  <Grid item xs={4}>
                    <Button
                      variant="outlined"
                      color="primary"
                      disabled={disabled || currentId === item.Account_id}
                      style={{
                        height: 44,
                        width: 99,
                        border: "none",
                        backgroundColor: "#FFFFFF",
                      }}
                      onClick={() => {
                        handleClick(item.Account_id);
                      }}
                    >
                      {loading === item.Account_id ? (
                        <CircularProgress />
                      ) : currentId === item.Account_id ? (
                        <Text objectName="current" page="multi" />
                      ) : (
                        <Text objectName="select" page="multi" />
                      )}
                    </Button>
                  </Grid>
                </Grid>
                <div
                  style={{
                    borderBottom: "1px solid #F1F1F1",
                    margin: "20px 0px",
                  }}
                ></div>
              </React.Fragment>
            );
          })}
      </div>
    </DialogContent>
    // </Dialog>
  );
}
