import { createMuiTheme } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#004E5E",
      light: "#3B8CA8",
      dark: "#004E5E",
    },
    done: {
      main: "#21C082",
    },
  },
  typography: {
    fontFamily: [
      "Ploni",
      "Montserrat",
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
    fontWeightRegular: 700,
    h1: {
      fontWeight: 700,
      fontSize: "48px",
    },
    h2: {
      fontWeight: 700,
      fontSize: "32px",
      lineHeight: "47px",
    },
    h3: {
      fontWeight: 400,
      fontSize: "24px",
    },
    h4: {
      fontWeight: 700,
      fontSize: "20px",
    },
    h5: {
      fontWeight: 700,
      fontSize: "16px",
    },
    button: {
      fontWeight: 700,
      fontSize: "16px",
    },
    body1: {
      fontWeight: 400,
      fontSize: "14px",
    },
    body2: {
      fontWeight: 400,
      fontSize: "16px",
    },
  },
});

theme.overrides = {
  MuiTextField: {
    root: {
      width: "100%",
    },
  },
  MuiSelect: {
    iconOutlined: {
      insetInlineStart: "auto",
    },
    root: {
      //paddingLeft: "24px",
      //flexDirection: "row-reverse",
    },
    nativeInput: {
      //left: 0,
      // flexDirection: "row-reverse",
      // justifyContent: "flex-end",
      margin: "auto",
    },
    icon: {
      left: 5,
      right: "auto",
      // height: "15px",
      // width: "15px",
      // flexDirection: "row-reverse",
      // alignItems: "center",
      // textAlign: "center",

      fill: "#004E5E",
    },
    iconOpen: {
      transform: "none",
    },

    select: {
      paddingRight: "0px",
      fontSize: "16px",
      //right: 0,
    },
    outlined: {
      paddingRight: "0px",
    },
  },
  MuiBadge: {
    badge: {
      backgroundColor: "#EA7B7E",
      color: "white",
    },
  },
  MuiCard: {
    root: {
      display: "block",
      margin: "8px auto",
      boxShadow: "none",
      textAlign: "start",
      borderRadius: "24px",
      width: "100%",
    },
  },
  MuiCardContent: {
    root: {
      backgroundColor: "#F8F8F8",
    },
  },
  MuiFormHelperText: {
    root: {
      "&$error": {
        color: "#FF4243",
        fontSize: "12px",
        fontWeight: 400,
        textAlign: "right",
      },
    },
  },
  MuiOutlinedInput: {
    input: {
      color: "#004E5E",

      "&::placeholder": {
        color: "#004E5E",
        opacity: 1,
        fontWeight: 400,
        fontSize: "16px",
      },
      "&:hover::placeholder": {
        color: "#3B8CA8",
      },
    },
    root: {
      width: "100%",
      fontSize: "16px",
      backgroundColor: "#F8F8F8",
      borderRadius: "8px",
      color: "#004E5E",
      "&$error": {
        color: "#FF4243",
        border: "1px solid #FF4243",
      },
      "& $notchedOutline": {
        borderWidth: 0,
      },
      "&:hover $notchedOutline": {
        borderWidth: 0,
      },
      "&$focused $notchedOutline": {
        borderWidth: 1,
        borderColor: "#004E5E",
        // backgroundColor: "#FFFFFF",
      },
      "&$focused:hover $notchedOutline": {
        borderWidth: 1,
        borderColor: "#3B8CA8",
        // backgroundColor: "#FFFFFF",
      },
    },
    focused: {
      backgroundColor: "#FFFFFF",
      "&input::placeholder": {
        color: "#004E5E",
      },
    },
    notchedOutline: {},
    error: {
      color: "red",
      "&::placeholder": {
        color: "red",
      },
    },
  },

  MuiButton: {
    root: {
      // width: "183px",
      textTransform: "none",
      marign: "auto",
      fontWeight: 700,
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    outlinedPrimary: {
      width: "100%",
      fill: "#004E5E",
      height: "64px",
      border: "1px solid #004E5E",
      boxSizing: "border-box",
      borderRadius: 16,
      transition: "border 0.2s, color 0.2s",
      fontWeight: 700,
      fontSize: "16px",
      padding: theme.spacing(1),
      // margin: theme.spacing(1),
      "&:hover": {
        transition: "border 0.2s, color 0.2s",
        border: "1px solid #3B8CA8",
        backgroundColor: "#FFFFFF",
        color: "#3B8CA8",
        fill: "#3B8CA8",
      },
    },
    containedPrimary: {
      width: "100%",
      backgroundColor: "#004E5E",
      color: "#FFFFFF",
      fill: "#FFFFFF",
      stroke: "#FFFFFF",
      height: "64px",
      border: "1px solid #004E5E",
      boxSizing: "border-box",
      borderRadius: 16,
      transition: "border 0.2s, color 0.2s, fill: 0.2s",
      fontWeight: 700,
      fontSize: "16px",
      //padding: theme.spacing(1),
      boxShadow: "none",
      // margin: theme.spacing(1),
      "&:hover": {
        transition: "border 0.1s, color 0.1s, fill: 0.1s",
        border: "1px solid #004E5E",
        backgroundColor: "#FFFFFF",
        color: "#004E5E",
        fill: "#004E5E",
        stroke: "#004E5E",
        boxShadow: "none",
        "@media (hover: none)": {
          backgroundColor: "#004E5E",
          color: "#FFF",
          fill: "#FFF",
          stroke: "#FFF",
          "&:active": {
            backgroundColor: "#004E5E",
            color: "#FFF",
          },
        },
      },
      "&:active": {
        backgroundColor: "#004E5E",
      },
    },
    containedSecondary: {
      width: "100%",
      backgroundColor: "#FFFFFF",
      color: "#004E5E",
      fill: "#004E5E",
      height: "64px",
      border: "1px solid #004E5E",
      boxSizing: "border-box",
      borderRadius: 16,
      transition: "border 0.1s, color 0.1s, fill: 0.1s",
      fontWeight: 700,
      fontSize: "16px",
      boxShadow: "none",
      padding: theme.spacing(1),
      // margin: theme.spacing(1),
      "&:hover": {
        boxShadow: "none",
        transition: "border 0.1s, color 0.1s, fill 0.1s",
        border: "1px solid #3B8CA8",
        backgroundColor: "#FFFFFF",
        color: "#3B8CA8",
        fill: "#3B8CA8",
      },
    },
    disabled: {
      width: "100%",
      color: "#C7C7C7",
      fill: "#C7C7C7",
      border: "1px solid #C7C7C7",
    },
  },
};

export default theme;
