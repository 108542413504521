import React from "react";

export function useLanguageFromQuery(setLanguage) {
  React.useEffect(() => {
    const query = new URLSearchParams(window.location.search).get("lang");
    if (query === "en") {
      setLanguage("en");
      window.localStorage.setItem("keshetLang", "en");
      return;
    }
    if (query === "he") {
      setLanguage("he");
      window.localStorage.setItem("keshetLang", "he");

      return;
    }
    const userLang = window.localStorage.getItem("keshetLang") || "he";
    setLanguage(userLang);
  }, [setLanguage]);
}
