import { Button, InputAdornment, TextField } from "@material-ui/core/";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { useHistory } from "react-router-dom";
import { globalState } from "../../App";
import {
  AccountsData,
  GlobalData,
  UserData,
  UserFavoritesNgos,
} from "../../Contexts";
import { useLanguage } from "../../hooks";
import { ReactComponent as EyeIcon } from "../../img/eye.svg";
import { ReactComponent as Logo } from "../../img/logo.svg";
import { ReactComponent as SMS } from "../../img/sms.svg";
import { getFavsNgos, getUserAccounts, getUserData } from "../../utilities/api";
import Text from "../../utilities/Text";
import "./CustomCss.css";
import FixedLogo from "./FixedLogo";

import styles from "./Login.module.css";
import SplashScreen from "./SplashScreen";
import { ReactComponent as Xbtn } from "./Xbutton.svg";
import {
  RecaptchaVerifier,
  getAuth,
  isSignInWithEmailLink,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  signInWithPhoneNumber,
} from "firebase/auth";
import initFb from "../../utilities/firebaseConfig";

export default function Login() {
  const [email, setEmail] = useState("");
  const [phone, setPhone] = React.useState("+972");
  const [userCtx, setUserCtx] = React.useContext(UserData);
  const [userAccounts, setUserAccounts] = React.useContext(AccountsData);
  const [twoFactor, setTwoFactor] = React.useState(false);
  const history = useHistory();
  const [error, setError] = React.useState("");
  const [phoneCode, setPhoneCode] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [favsNgos, setFavsNgos] = React.useContext(UserFavoritesNgos);
initFb()
  if (userAccounts) {
    history.push("/multi");
  }
  if (userCtx) {
    history.push("/");
  }

  function recaptchaResend() {
    const auth = getAuth();
    window.recaptchaVerifierResend = RecaptchaVerifier(auth, "resend", {
      size: "invisible",
    });
  }
  const [mailSuccess, setMailSuccess] = useState(false);
  const auth = getAuth();
  async function login() {
    setLoading(true);
    setError("");

    const auth = getAuth();


    if (email) {
      await sendSignInLinkToEmail(auth, email, {
        handleCodeInApp: true,
        url: (process.env.NODE_ENV==="development"?"http://localhost:3000/login?email=":"https://portal.keshet-il.org/login?email=") + email,
      });
      localStorage.setItem("mail-sign-in", email);
      setMailSuccess(true);
    }

    window.recaptchaVerifier = new RecaptchaVerifier(auth, "recaptchSignin", {
      size: "invisible",
    });

    signInWithPhoneNumber(auth, "+" + phone, window.recaptchaVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setTwoFactor(true);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setError("Invalid Phone Number");
        setLoading(false);
      });
  }

  function reSend() {
    setLoading(true);

    const zeroLessPhone = phone.substring(1);
    const fixedPhoneNumber = "+972" + zeroLessPhone;
    const auth = getAuth();
    signInWithPhoneNumber(
      auth,
      fixedPhoneNumber,
      window.recaptchaVerifierResend
    )
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setTwoFactor(true);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);

        window.recaptchaVerifierResend.reset("resend");
      });
  }

  const language = useLanguage();
  const desktopContainer = {
    width: 406,
    margin: [language === "he" ? "22vh 28vw" : "22vh 45vw"],
  };

  const { screenSize } = React.useContext(GlobalData);
  const desktopView = screenSize;
  const mobileView = !screenSize;

  const recaptchRef = useRef();

  const authenticateWithFireBase=fbAuth(setLoading, setUserAccounts, setUserCtx, setFavsNgos, setError)
  const SignInWithPhone = fbPhoneAuth(setLoading, phoneCode, authenticateWithFireBase, setError);
  useAuthWithMail(auth, authenticateWithFireBase, setError, setLoading);


  return (
    <div
      className="loginContainer"
      style={desktopView ? desktopContainer : null}
    >
      <div style={{ margin: "0px 24px" }}>
        <div>
          <div className={styles.svgLogo}>{mobileView && <Logo />}</div>
          {mobileView && <SplashScreen />}
          {desktopView && <FixedLogo />}

          <div className="divide" style={{ height: 48 }}></div>
          <Typography variant="h2" className={styles.centered}>
            {" "}
            <Text objectName="title" page="login" />
          </Typography>
        </div>
        {mailSuccess ? (
          <Typography variant="body2">
            <Text objectName={"mail success"} page="login" />
          </Typography>
        ) : !twoFactor ? (
          <>
            <div style={{ margin: "16px 70px 35px 70px" }}>
              <Typography variant="body2">
                <Text objectName={"phoneSubTitle"} page="login" />
              </Typography>
            </div>
            <div className={styles.centered}>
              <>
                <PhoneInput
                  autoFormat={true}
                  value={phone}
                  preferredCountries={["il", "us", "gb"]}
                  placeholder={Text({
                    page: "login",
                    objectName: "phonePlaceholder",
                  })}
                  buttonStyle={{ insetInlineStart: 0, display: "block" }}
                  specialLabel=""
                  onChange={(value, details, more) => {
                    if (email) {
                      setEmail("");
                    }
                    setPhone(value);
                  }}
                  inputStyle={{
                    width: "100%",
                    paddingLeft: 100,
                    direction: "ltr",
                    color: "#004E5E",
                    fontFamily: "inherit",
                    backgroundColor: "#F8F8F8",
                    borderRadius: 5,
                    border: "none",
                  }}
                  dropdownStyle={{ left: 0, top: -27 }}
                  containerStyle={{
                    direction: "ltr",
                    width: "90%",
                    margin: "auto",
                  }}
                />
                <div
                  onClick={() => {
                    setPhone("+972");
                  }}
                  style={{
                    position: "relative",
                    top: -40,
                    left: language === "he" ? -30 : 300,
                    cursor: "pointer",
                    textAlign: "right",
                    width: 1,
                  }}
                >
                  {" "}
                  <Xbtn />
                </div>
              </>
              <div style={{ fontWeight: "bold", paddingBottom: 24 }}>
                <strong>
                  <Text page="login" objectName={"or"}></Text>
                </strong>
              </div>
              <div
                style={{ display: "flex", flexDirection: "column", gap: 12 }}
              >
                <TextField
                  onChange={(e) => {
                    if (phone.length > 4) {
                      setPhone("+972");
                    }
                    setEmail(e.target.value);
                  }}
                  placeholder={Text({
                    page: "login",
                    objectName: "emailPlacholder",
                  })}
                  value={email}
                  variant="outlined"
                  color="primary"
                  type="email"
                />
              </div>
            </div>
            <div style={{ marginTop: 24 }}>
              <Button
                ref={recaptchRef}
                disabled={(phone.length < 6 && !email) || loading}
                variant="contained"
                id="recaptchSignin"
                color="primary"
                startIcon={
                  !loading && <SMS style={{ marginLeft: 12, color: "white" }} />
                }
                onClick={() => {
                  login();
                }}
              >
                {!loading ? (
                  <Text objectName="phoneBtn" page="login" />
                ) : (
                  <CircularProgress />
                )}
              </Button>
            </div>
          </>
        ) : (
          <div>
            <TextField
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {" "}
                    <EyeIcon />
                  </InputAdornment>
                ),
              }}
              style={{ width: "90%" }}
              placeholder={Text({
                page: "login",
                objectName: "smsPlacholder",
              })}
              variant="outlined"
              color="primary"
              id="twofa"
              onChange={(e) => {
                setPhoneCode(e.target.value);
              }}
            />

            <div style={{ marginTop: 24, marginBottom: 24 }}>
              <Button
                disabled={loading || phoneCode.length <= 1}
                variant="contained"
                color="primary"
                onClick={SignInWithPhone}
              >
                {!loading ? (
                  <Text objectName="smsBtn" page="login" />
                ) : (
                  <CircularProgress />
                )}
              </Button>
            </div>
            <Button
              id="resend"
              onClick={() => {
                recaptchaResend();

                reSend();
              }}
            >
              {" "}
              <Text objectName="smsResend" page="login" />
            </Button>
          </div>
        )}
        <div>
          {" "}
          <Typography style={{ color: "red" }}>{error}</Typography>
        </div>
      </div>
    </div>
  );
}


function fbPhoneAuth(setLoading, phoneCode, authenticateWithFireBase, setError) {
  return () => {
    setLoading(true);
    window.confirmationResult
      .confirm(phoneCode)
      .then(authenticateWithFireBase)
      .catch((error) => {
        console.log({ error });
        setError("סיסמא לא נכונה - אנא נסה שנית");
        setLoading(false);

        window.recaptchaVerifier.render().then(function (widgetId) {
          window.grecaptcha.reset(widgetId);
        });
      });
  };
}

function fbAuth(setLoading, setUserAccounts, setUserCtx, setFavsNgos, setError) {
  return (result) => {
    setLoading(true);
    globalState.id = result.user.uid;
    // User signed in successfully.
    localStorage.setItem("userJWT", result._tokenResponse.idToken);
    setTimeout(async () => {
      const userAccountsList = await getUserAccounts();
      if (userAccountsList?.data?.length > 1 &&
        userAccountsList.status === 200) {
        setUserAccounts({ accounts: userAccountsList.data });
      } else {
        const res = await getUserData();
        const favs = await getFavsNgos(res.data?.account_id);

        if (res.data !== "No account") {
          axios.defaults.headers.common["keshet_account"] =
            userAccountsList.data[0].Account_id;
          setUserCtx({ user: { ...res.data } });
          setFavsNgos(favs);
          setLoading(false);
        } else {
          setError("הייתה בעיה בחיבור לשרת");
          setLoading(false);
        }
      }
    }, 800);
  };
}

function useAuthWithMail(auth, authenticateWithFireBase, setError, setLoading) {
  useEffect(() => {

    const isSigninWithEmail = isSignInWithEmailLink(auth, window.location.href);

    if (isSigninWithEmail) {

      const savedMail = localStorage.getItem("mail-sign-in");
      signInWithEmailLink(auth, savedMail, window.location.href)
        .then(authenticateWithFireBase)
        .catch((error) => {
          console.log({ error });
          setError("סיסמא לא נכונה - אנא נסה שנית");
          setLoading(false);
          window.recaptchaVerifier.render().then(function (widgetId) {
            window.grecaptcha.reset(widgetId);
          });
        });
    }
  }, []);
}

