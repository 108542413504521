import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Search from "./Search";
import { Button } from "@material-ui/core/";
import {
  getClassificationData,
  getDistrictData,
  getEmployeesLvl,
  getFavNgosFromSrvr,
  getNgos,
  getNgosByName,
  getNgosByParams,
  getYearlycycleLvl,
} from "../utilities/api";
import { GlobalData, UserData, UserFavoritesNgos } from "../Contexts";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { ReactComponent as FilterIcon } from "../img/filter.svg";
import { ReactComponent as FilterIconWhite } from "../img/filterWhite.svg";
import Card from "@material-ui/core/Card";
import { CardContent, Fade, IconButton } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { ReactComponent as OpenIcon } from "../img/AccordionOpen.svg";
import { ReactComponent as ClosedIcon } from "../img/AccordionClosed.svg";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import KRadioButtons from "./KRadioButtons";
import { makeStyles } from "@material-ui/core/styles";
import Ngo from "./Ngo";
import KSwitch from "./KSwitch";
import Text from "../utilities/Text";
import Spinner from "./Spinner";
import KRadioButtonsMultiPick from "./KRadioButtonsMultiPick";
import { useLanguage } from "../hooks";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

export default function Ngos() {
  const globalData = React.useContext(GlobalData);

  const [userCtx, setUserCtx] = React.useContext(UserData);
  const [filter, setFilter] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [ngos, setNgos] = React.useState([]);
  const [originalNgos, setOriginalNgos] = React.useState([]);
  const [districts, setDistricts] = React.useState([]);
  const [classification, setClassification] = React.useState([]);
  const [employeesLvl, setEmployeesLvl] = React.useState([]);
  const [yearlyCycleLvl, setYearlyCycleLvl] = React.useState([]);
  const [selection, setSelection] = React.useState({});
  const [update, setUpdate] = React.useState(false);
  const [hasMore, setHasMore] = React.useState(true);
  const [userFavsNgos] = React.useContext(UserFavoritesNgos);
  const [favNgos, setFavNgos] = React.useState();
  const language = useLanguage();
  const history = useHistory();
  const [expanded, setExpanded] = React.useState("");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleSelect = (select) => {
    setSelection((currentState) => {
      return { ...currentState, ...select };
    });
  };

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
    setFilter("");
    setPage(1);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    async function fetchFavNgo() {
      const response = await getFavNgosFromSrvr(userFavsNgos);

      setFavNgos(response.data);
    }

    async function fetchNgos() {
      const response = await getNgos(page);
      if (response?.err?.response?.status === 401) {
        setUserCtx(null);
        history.push("/login");
      } else {
        if (!userFavsNgos) {
          setNgos(response.data);
          setOriginalNgos(response.data);
        } else {
          let likedNgo = userFavsNgos;

          const sortedData = response.data;

          sortedData.sort((a, b) => {
            if (likedNgo.includes(b.NgoNumber)) {
              return 1;
            } else {
              return -1;
            }
          });
          setNgos(sortedData);
          setOriginalNgos(sortedData);
        }
      }
    }

    async function fetchDistricts() {
      const res = await getDistrictData();
      const data = res.data
        .filter((item) => {
          return item !== "" && item !== "None";
        })
        .map((item) => {
          return { text: item, value: item };
        });
      setDistricts(data);
    }

    async function fetchClassifications() {
      const res = await getClassificationData();
      const data = res.data
        .filter((item) => {
          return item !== "" && item !== "None";
        })
        .map((item) => {
          return { text: item, value: item };
        });
      setClassification(data);
    }

    async function fetchEmployeesLvl() {
      const res = await getEmployeesLvl();
      const data = res.data.map((item) => {
        return { text: item.text, value: item.value };
      });

      setEmployeesLvl(data);
    }

    async function fetchYearlyCycleLvl() {
      const res = await getYearlycycleLvl();

      const data = res.data.map((item) => {
        return { text: item.text, value: item.value };
      });

      setYearlyCycleLvl(data);
    }

    fetchFavNgo();
    fetchNgos();

    fetchDistricts();
    fetchClassifications();
    fetchEmployeesLvl();
    fetchYearlyCycleLvl();
  }, []);

  React.useEffect(() => {
    if (filter.length < 3) {
      setNgos(originalNgos);
    } else {
      async function getFilteredNgos() {
        const response = await getNgosByName(filter, page);
        setNgos(response.data);
      }

      getFilteredNgos();
    }
  }, [filter]);

  const fetchMoreNgos = async () => {
    if (ngos.length < 10) {
      setHasMore(false);
      return;
    }

    const noNgoSelected = Object.keys(selection).length === 0;
    if (!filter && noNgoSelected) {
      const response = await getNgos(page + 1);
      setPage((prev) => prev + 1);

      if (response.data.length > 0) {
        setOriginalNgos((prev) => [...prev, ...response.data]);
        setNgos((prev) => [...prev, ...response.data]);
      } else {
        setHasMore(false);
      }
      return;
    } else if (filter !== "") {
      const currentPage = page;
      setPage((prev) => prev + 1);
      const response = await getNgosByName(filter, currentPage + 1);
      if (response.data.length > 0) {
        setNgos((prev) => [...prev, ...response.data]);
      } else {
        setHasMore(false);
      }
      return;
    } else if (Object.keys(selection).length > 0) {
      const currentPage = page;
      setPage((prev) => prev + 1);
      const currentSelection = { ...selection };
      currentSelection.page = currentPage + 1;
      const response = await getNgosByParams(currentSelection);
      if (response.data.length > 0) {
        setNgos((prev) => [...prev, ...response.data]);
      } else {
        setHasMore(false);
      }
      return;
    }
  };

  const handleUpdate = () => {
    setUpdate((prev) => {
      return !prev;
    });
  };

  React.useEffect(() => {
    if (ngos.length < 1) {
      return;
    }

    async function getSelectedNgos() {
      setPage(1);
      const response = await getNgosByParams(selection);
      setNgos(response.data);
    }

    getSelectedNgos();
  }, [update]);

  const noSearchNoFilter = () => {
    const isFiltersSelected = Object.keys(selection).length === 0;
    const isNameFilterGiven = filter.length === 0;
    if (isFiltersSelected && isNameFilterGiven) {
      return favNgos?.length < 1 ? (
        <></>
      ) : (
        favNgos?.map((favNgo) => {
          return (
            <NgoWrapper key={favNgo.id}>
              <Card className={classes.cardDesk}>
                <CardContent>
                  <Ngo ngo={favNgo} />
                </CardContent>
              </Card>
            </NgoWrapper>
          );
        })
      );
    } else {
      return;
    }
  };

  return (
    <Fade in={true} timeout={1000}>
      <InfiniteScroll
        next={fetchMoreNgos}
        hasMore={hasMore}
        loader={
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Spinner style={{ margin: "auto" }}></Spinner>
          </div>
        }
        hasChildren="false"
        dataLength={ngos?.length}
        style={{
          overflow: "hidden",
        }}
      >
        <Wrapper>
          <Tittle>
            {" "}
            <Typography variant="h2">
              <Text objectName="listOfOrg" page="ngos" />
            </Typography>
          </Tittle>

          <SearchAndFilterWrapper>
            <SearchWrapper>
              {" "}
              <Search
                setFilter={setFilter}
                setPage={setPage}
                placeholder={Text({ page: "ngos", objectName: "searchNgos" })}
              />
            </SearchWrapper>
            <FilterWrapper>
              <GoToFilterButton
                onClick={handleClickOpen}
                fullWidth
                variant="outlined"
              >
                <FilterIcon />
                <FilterText variant="body2">
                  <Text objectName="filter" page="ngos" />
                </FilterText>
              </GoToFilterButton>
            </FilterWrapper>
          </SearchAndFilterWrapper>
          <NgosWrapper>
            {noSearchNoFilter()}
            {originalNgos?.length < 1 && <></>}
            {ngos?.length < 1 && (
              <NoResultsWrapper variant="body2">
                <Text objectName="noResults" page="ngos" />
              </NoResultsWrapper>
            )}

            {ngos?.map((ngo) => {
              const noFilters =
                Object.keys(selection).length === 0 && filter.length === 0;
              if (noFilters) {
                const isFavouriteNgo = userFavsNgos?.includes(ngo.NgoNumber);
                if (isFavouriteNgo) {
                  return null;
                }
                if (!isFavouriteNgo) {
                  return (
                    <NgoWrapper key={ngo.id}>
                      <Card className={classes.cardDesk}>
                        <CardContent>
                          <Ngo ngo={ngo} />
                        </CardContent>
                      </Card>
                    </NgoWrapper>
                  );
                }
              }

              if (!noFilters) {
                return (
                  <NgoWrapper key={ngo.id}>
                    <Card className={classes.cardDesk}>
                      <CardContent>
                        <Ngo ngo={ngo} />
                      </CardContent>
                    </Card>
                  </NgoWrapper>
                );
              }
            })}
          </NgosWrapper>
          <Dialog
            PaperProps={{ style: { backgroundColor: "#F8F8F8" } }}
            fullScreen
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogWrapper>
              <DialogTitle id="form-dialog-title">
                <Grid container alignItems="center">
                  <Grid item xs={10}></Grid>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={handleClose}
                      style={{ insetInlineEnd: 0, position: "absolute" }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>

                <FilterDialogTittle variant="h2">
                  <Text objectName="filterOrg" page="ngos" />
                </FilterDialogTittle>
              </DialogTitle>

              <FilterOptionsWrapper>
                <KeshetApproveSwitchWrapper>
                  <KSwitch
                    switchTextWhenOn={Text({
                      page: "ngos",
                      objectName: "approved",
                    })}
                    switchTextWhenOff={Text({
                      page: "ngos",
                      objectName: "approved",
                    })}
                    switchWidth={language === "he" ? 146 : 166}
                    switchValue={(val) => {
                      handleSelect({ keshetApprove: val });
                    }}
                    startValue={selection.keshetApprove}
                    style={{ direction: "ltr" }}
                  />
                </KeshetApproveSwitchWrapper>

                <Collapsible
                  expanded={expanded === "panel1"}
                  onChange={handleChange("panel1")}
                >
                  <AccordionSummary
                    className={classes.accordionSummary}
                    expandIcon={
                      expanded === "panel1" ? <OpenIcon /> : <ClosedIcon />
                    }
                  >
                    <CollapsibleTittleWrapper variant="h4">
                      <Text objectName="CharitableActivity" page="ngos" />
                    </CollapsibleTittleWrapper>
                  </AccordionSummary>
                  <AccordionDetails className={classes.root}>
                    <KRadioButtonsMultiPick
                      buttonsData={classification}
                      handleValue={(value) => {
                        if (value.length === 0) {
                          handleSelect({ classification: undefined });
                        } else {
                          handleSelect({ classification: value.join() });
                        }
                      }}
                      value={
                        selection.classification
                          ? selection.classification.split(",")
                          : []
                      }
                    />
                  </AccordionDetails>
                </Collapsible>

                <Collapsible
                  expanded={expanded === "panel3"}
                  onChange={handleChange("panel3")}
                >
                  <AccordionSummary
                    className={classes.accordionSummary}
                    expandIcon={
                      expanded === "panel3" ? <OpenIcon /> : <ClosedIcon />
                    }
                  >
                    <CollapsibleTittleWrapper variant="h4">
                      <Text objectName="location" page="ngos" />
                    </CollapsibleTittleWrapper>
                  </AccordionSummary>
                  <AccordionDetails>
                    <KRadioButtonsMultiPick
                      buttonsData={districts}
                      handleValue={(value) => {
                        if (value.length === 0) {
                          handleSelect({ district: undefined });
                        } else {
                          handleSelect({ district: value.join() });
                        }
                      }}
                      value={
                        selection.district ? selection.district.split(",") : []
                      }
                    />
                  </AccordionDetails>
                </Collapsible>
                <Collapsible
                  expanded={expanded === "panel4"}
                  onChange={handleChange("panel4")}
                >
                  <AccordionSummary
                    className={classes.accordionSummary}
                    expandIcon={
                      expanded === "panel4" ? <OpenIcon /> : <ClosedIcon />
                    }
                  >
                    <CollapsibleTittleWrapper variant="h4">
                      <Text objectName="sizeFin" page="ngos" />
                    </CollapsibleTittleWrapper>
                  </AccordionSummary>
                  <AccordionDetails>
                    <KRadioButtons
                      buttonsData={yearlyCycleLvl}
                      handleValue={(value) => {
                        if (value === "") {
                          handleSelect({ yearlycycleLvl: undefined });
                        } else {
                          handleSelect({ yearlycycleLvl: value });
                        }
                      }}
                      value={
                        selection.yearlycycleLvl ? selection.yearlycycleLvl : ""
                      }
                    />
                  </AccordionDetails>
                </Collapsible>
                <Collapsible
                  expanded={expanded === "panel5"}
                  onChange={handleChange("panel5")}
                >
                  <AccordionSummary
                    className={classes.accordionSummary}
                    expandIcon={
                      expanded === "panel5" ? <OpenIcon /> : <ClosedIcon />
                    }
                  >
                    <CollapsibleTittleWrapper variant="h4">
                      <Text objectName="sizeEmp" page="ngos" />
                    </CollapsibleTittleWrapper>
                  </AccordionSummary>
                  <AccordionDetails>
                    <KRadioButtons
                      buttonsData={employeesLvl}
                      handleValue={(value) => {
                        if (value === "") {
                          handleSelect({ employeesLvl: undefined });
                        } else {
                          handleSelect({ employeesLvl: value });
                        }
                      }}
                      value={
                        selection.employeesLvl ? selection.employeesLvl : ""
                      }
                    />
                  </AccordionDetails>
                </Collapsible>
                <FilterButton
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleUpdate();
                    handleClose();
                  }}
                >
                  <FilterIconWhite
                    style={{ marginLeft: "12px" }}
                  ></FilterIconWhite>
                  <Text objectName="filterThis" page="ngos" />
                </FilterButton>
              </FilterOptionsWrapper>
            </DialogWrapper>
          </Dialog>
        </Wrapper>
      </InfiniteScroll>
    </Fade>
  );
}
const Wrapper = styled.div`
  margin: 0px 16px;
`;
const Tittle = styled.div`
  text-align: start;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const SearchAndFilterWrapper = styled.div`
  display: flex;
  gap: 10px;
  @media only screen and (min-width: 600px) {
    display: flex;
    gap: 16px;
    max-width: 1200px;
    margin-right: 8.3%;
    margin-left: 10%;
  }
`;

const SearchWrapper = styled.div`
  width: 75%;
  @media only screen and (min-width: 600px) {
    width: 89.5%;
  }
`;
const FilterWrapper = styled.div`
  width: 25%;
`;

const GoToFilterButton = styled(Button)`
  height: 54px;
  background-color: #f8f8f8;
  border: none;
`;

const FilterText = styled(Typography)`
  color: #004e5e;
  margin-inline-start: 8px;
`;

const NgosWrapper = styled.div`
  @media only screen and (min-width: 600px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: 8.3%;
    margin-left: 8.3%;
  }
`;
const NoResultsWrapper = styled(Typography)`
  color: #004e5e;
`;

const NgoWrapper = styled.div`
  @media only screen and (min-width: 600px) {
    width: 31%;
    margin-left: 24px;
    max-width: 384px;
  }
`;
const DialogWrapper = styled.div`
  @media only screen and (min-width: 600px) {
    max-width: 600px;
    margin-right: 30%;
    margin-left: 30%;
    margin-top: 150px;
  }
`;

const FilterDialogTittle = styled(Typography)`
  margin-top: 28px;
`;

const FilterOptionsWrapper = styled(DialogContent)`
  background-color: #f8f8f8;
  width: 100%;
  padding: 0px;
`;

const KeshetApproveSwitchWrapper = styled.div`
  margin-bottom: 30px;
`;

const Collapsible = styled(Accordion)`
  width: 100%;
  background-color: #f8f8f8;
`;

const CollapsibleTittleWrapper = styled(Typography)`
  color: #004e5e;
`;

const FilterButton = styled(Button)`
  width: 92%;
  margin-right: 4%;
  margin-top: 2.6%;
`;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    margin: "6px 2px",
    borderRadius: "16px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    display: "block",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  column: {
    flexBasis: "100%",
    width: "100%",
  },
  accordionSummary: {
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-root.Mui-expanded": {
      minHeight: "40px",
      height: "50px",
    },
  },
  cardDesk: {
    border: "1px solid #3B8CA8",
  },
}));
