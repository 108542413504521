import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { TextField, Typography } from "@material-ui/core";

import { Collapse } from "@material-ui/core";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import Switch from "../Switch";
import Text from "../../utilities/Text";
import { useLanguage } from "../../hooks";
import { UserData } from "../../Contexts";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    margin: "12px 2px",
    borderRadius: "16px",
  },

  finishCard: {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "space-between",
  },
  selectA: {
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      paddingRight: "0px",
    },
  },
  iconHeb: {
    right: "auto",
  },
  iconEng: {
    left: "auto",
  },
  cardfield: {
    "& .MuiOutlinedInput-root": {
      marginBottom: "24px",
      backgroundColor: "#FFFFFF",
    },
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      paddingRight: "15px",
    },
    "& .MuiSelect-select": {
      "&:focus": {
        backgroundColor: "#FFF",
      },
    },
  },

  currency: {
    backgroundColor: "#FFF",
    borderRadius: "16px",
    width: "56px",
    height: "32px",
    color: "#8C8C8C",
    fontWeight: 400,
    fontSize: 14,
  },
  currencyActive: {
    backgroundColor: "#C2EBED",
    borderRadius: "16px",
    width: "20%",
    border: "1px solid #161616",
    height: "32px",
    fontWeight: 400,
    fontSize: 14,
  },
}));

export default function FormStep2({ sendData, data }) {
  const language = useLanguage();
  const [userCtx, setUserCtx] = React.useContext(UserData);
  const [formData, setFormData] = React.useState({
    checkNotAnon: data?.keshetName !== "אנונימי",
  });

  React.useEffect(() => {
    setFormData({
      //checkFounder: false,
      //checkSecondFounder: false,
      checkNotAnon: data?.keshetName !== "אנונימי",
      contactDetails: data?.contactDetails ? true : false,
      //addAcountName: true,
      founder: data?.founder || "",
      //secondFounder: "",
      keshetName: data?.keshetName,
      contactName: data?.contactName || "",
      contactPhone: data?.contactPhone || "",
      contactMail: data?.contactMail || "",
      contactNotes: data?.contactNotes || "",
    });
  }, []);

  const [accountData, setAccountData] = React.useState({
    keshetname: "",
    PrimContact: "",
    secondContact: "",
  });

  React.useEffect(() => {
    sendData(formData);
  }, [formData]);

  React.useEffect(() => {
    if (formData.keshetName === "אחר") {
      return;
    }
    if (accountData.keshetname === "") {
      return;
    }
    if (formData.keshetName === "אנונימי") {
      return;
    }
    if (
      [
        accountData.PrimContact,
        accountData.secondContact,
        accountData.keshetname,
      ].includes(formData.keshetName)
    ) {
      return;
    } else {
      setFormData((prev) => {
        return { ...prev, keshetName: "אחר" };
      });
    }
  }, [accountData, formData]);

  React.useEffect(() => {
    setAccountData({
      keshetname: userCtx?.user?.keshetname,
      PrimContact:
        language === "he"
          ? userCtx?.user?.PrimContact
          : userCtx?.user?.PrimContactENG
          ? userCtx?.user?.PrimContactENG //fallback to hebrew
          : userCtx?.user?.PrimContact,
      secondContact:
        language === "he"
          ? userCtx?.user?.secondContact
          : userCtx?.user?.secondContactENG
          ? userCtx?.user?.secondContactENG //fallback to hebrew
          : userCtx?.user?.secondContact,
    });
  }, [userCtx, language]);

  const classes = useStyles();

  return (
    <React.Fragment>
      <Typography variant="h4" color="primary">
        <Text objectName="notification" page="donation" />
      </Typography>
      <Typography variant="body2">
        <Text objectName="notifictionSubTitle" page="donation" />
      </Typography>
      <div className={classes.root}>
        <Switch
          switchTextWhenOff={Text({
            page: "donation",
            objectName: "anonSwitchOn",
          })}
          switchTextWhenOn={Text({
            page: "donation",
            objectName: "anonSwitch",
          })}
          switchWidth={language === "he" ? 250 : 300}
          switchDefault={formData.checkNotAnon}
          switchLang={language === "he" ? "he" : "eng"}
          switchValue={(checked) => {
            if (checked) {
              setFormData((prev) => {
                return {
                  ...prev,
                  checkNotAnon: checked,
                  keshetName: "",
                  //checkFounder: false,
                  //checkSecondFounder: false,
                  //addAcountName: true,
                  //founder: "",
                  //secondFounder: "",
                };
              });
            } else {
              setFormData((prev) => {
                return {
                  ...prev,
                  checkNotAnon: checked,
                  keshetName: "אנונימי",
                  founder: "",
                  //secondFounder: "אנונימי",
                };
              });
            }
          }}
          style={{ direction: "ltr" }}
        />
      </div>

      <Collapse in={formData.checkNotAnon} style={{ width: "100%" }}>
        <TextField
          name="ticketType"
          color="primary"
          variant="outlined"
          className={classes.cardfield}
          value={formData.keshetName}
          select
          SelectProps={{
            native: true,
            IconComponent: KeyboardArrowDownIcon,
            classes: {
              icon: language === "he" ? classes.iconHeb : classes.iconEng,
            },
          }}
          onChange={(e) => {
            setFormData((prev) => {
              return { ...prev, keshetName: e.target.value };
            });
          }}
        >
          <option key="" value="" disabled>
            {Text({ page: "donation", objectName: "chooseDonors" })}
          </option>
          <option key="0" value={accountData.PrimContact}>
            {accountData.PrimContact}
          </option>
          <option key="1" value={accountData.secondContact}>
            {accountData.secondContact}
          </option>
          <option key="2" value={accountData.keshetname}>
            {accountData.keshetname}
          </option>
          <option key="3" value="אחר">
            {Text({ page: "donation", objectName: "other" })}
          </option>
        </TextField>
        {formData.keshetName === "אחר" && (
          <TextField
            className={classes.cardfield}
            id="donor"
            type="text"
            placeholder={Text({ page: "donation", objectName: "donorDetails" })}
            variant="outlined"
            value={formData.founder}
            onChange={(e) => {
              setFormData((prev) => {
                return { ...prev, founder: e.target.value };
              });
            }}
          />
        )}
      </Collapse>
      <TextField
        className={classes.cardfield}
        id="contactNotes"
        type="text"
        value={formData.contactNotes}
        placeholder={Text({
          page: "donation",
          objectName: "comments",
        })}
        rows="4"
        multiline
        variant="outlined"
        required
        onChange={(e) => {
          setFormData((prev) => {
            return { ...prev, [e.target.id]: e.target.value };
          });
        }}
      />
      <div
        style={{
          border: "1px dashed #E4E4E4",
          margin: "12px",
          width: "100%",
        }}
      ></div>
      <Typography variant="h4">
        <Text objectName="contactTitle" page="donation" />
      </Typography>
      <div className={classes.root}>
        <Switch
          switchTextWhenOn={Text({
            page: "donation",
            objectName: "yes",
          })}
          switchTextWhenOff={Text({
            page: "donation",
            objectName: "no",
          })}
          switchWidth={74}
          switchDefault={formData.contactDetails}
          switchValue={(checked) =>
            setFormData((prev) => {
              return { ...prev, contactDetails: checked };
            })
          }
          style={{ direction: "ltr" }}
        />
      </div>
      <Collapse in={formData.contactDetails}>
        <Typography style={{ marginBottom: "24px" }} variant="body1">
          <Text objectName="contactSubTitle" page="donation" />
        </Typography>
        <TextField
          className={classes.cardfield}
          id="contactName"
          type="text"
          placeholder={Text({
            page: "donation",
            objectName: "fullName",
          })}
          variant="outlined"
          value={formData.contactName}
          onChange={(e) => {
            setFormData((prev) => {
              return { ...prev, [e.target.id]: e.target.value };
            });
          }}
        />
        <TextField
          className={classes.cardfield}
          id="contactPhone"
          type="text"
          placeholder={Text({
            page: "donation",
            objectName: "mobileNum",
          })}
          variant="outlined"
          value={formData.contactPhone}
          required
          onChange={(e) => {
            setFormData((prev) => {
              return { ...prev, [e.target.id]: e.target.value };
            });
          }}
        />
        <TextField
          className={classes.cardfield}
          id="contactMail"
          type="email"
          placeholder={Text({
            page: "donation",
            objectName: "email",
          })}
          variant="outlined"
          value={formData.contactMail}
          required
          onChange={(e) => {
            setFormData((prev) => {
              return { ...prev, [e.target.id]: e.target.value };
            });
          }}
        />
      </Collapse>
    </React.Fragment>
  );
}
