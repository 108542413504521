import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Button, Typography, Card, Collapse } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import { ReactComponent as ExpandIcon } from "../img/arrowExpand.svg";
import { ReactComponent as CloseIcon } from "../img/arrowClose.svg";
import { ReactComponent as NewIcon } from "../img/NewMailIcon.svg";
import { useLanguage } from "../hooks";
import Text, { dictionary } from "../utilities/Text";
import NotificationCardInfo from "./NotificationCardInfo";
import NotificationCardInfoGeneral from "./NotificationCardInfoGeneral";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    margin: "6px 2px",
    borderRadius: "16px",
  },
  notificationstatus: {
    color: "#21C082",
    fill: "#21C082",
    display: "inline",
    margin: "6px",
  },
  heading: {
    /* יתרה בחשבון */

    position: "static",
    width: "135px",
    height: "47px",
    left: "calc(50% - 135px/2 + 53.5px)",
    top: "28px",

    /* Headings / H2 */
    fontFamily: "Ploni DL 1.1 AAA",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "32px",
    lineHeight: "47px",

    /* identical to box height */
    textAlign: "right",
    letterSpacing: "0.005em",

    /* Neutral / N-100 */
    color: "#161616",

    /* Inside Auto Layout */
    flex: "none",
    order: "1",
    flexGrow: "0",
    margin: "6px 0px",
  },
  card: {
    position: "absolute",
    left: "6.74%",
    right: "66.74%",
    top: "28.78%",
    bottom: "47.11%",

    /* Neutral / N-20 */
    background: "#F8F8F8",
    borderRadius: "24px",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  column: {
    flexBasis: "100%",
    width: "100%",

    // display: "block",
  },
  cardDesk: {
    border: "1px solid #3B8CA8",
    [theme.breakpoints.up("md")]: {
      maxWidth: "384px",
    },
  },
}));
function days_between(date1, date2) {
  // The number of milliseconds in one day
  const ONE_DAY = 1000 * 60 * 60 * 24;

  // Calculate the difference in milliseconds
  const differenceMs = Math.abs(date1 - date2);

  // Convert back to days and return
  return Math.round(differenceMs / ONE_DAY);
}
function numberWithCommas(x) {
  if (!x) {
    return "";
  }
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
function daysString(days, language) {
  if (language === "he") {
    if (days === 0) {
      return "היום";
    } else if (days === 1) {
      return "לפני יום אחד";
    } else {
      return "לפני " + days + " ימים";
    }
  } else {
    if (days === 0) {
      return "Today";
    } else if (days === 1) {
      return "1 day ago";
    } else {
      return days + " days ago";
    }
  }
}

export default function NotificationCard(props) {
  const language = useLanguage();
  const classes = useStyles();
  const [moreInfo, setMoreInfo] = React.useState(false);
  const item_date = new Date(props.cardData.CreatedDate);
  const todays = new Date();
  const days = days_between(todays, item_date);
  const currency = props?.cardData?.grant_curr === "NIS" ? "₪" : "$";
  let orgName;
  if (language === "he") {
    orgName = props.cardData?.org;
  } else {
    orgName = props.cardData?.orgENG;
  }

  const translateFromHebrew = React.useCallback(() => {
    if (language === "en") {
      return props.cardData?.Subject;
    } else {
      const terms = Object.keys(dictionary.donation);
      const term = terms.filter((key) => {
        return dictionary.donation[key]["en"] === props.cardData?.Subject;
      })[0];

      return dictionary.donation[term]?.["he"];
    }
  }, [language, props.cardData?.Subject]);
  return (
    <div>
      <Card className={classes.cardDesk}>
        <CardContent style={{ padding: "24px" }}>
          <Typography variant="body2" style={{ color: "#8C8C8C" }}>
            {/* {language === "he"
                ? "לפני " + days + " ימים"
                : days + " days ago"} */}
            {item_date.toLocaleDateString("he-IL")}
          </Typography>

          <Typography variant="h2">
            {props.cardData?.amount === "0" || !props.cardData?.amount ? (
              <div style={{ margin: "10px" }}></div>
            ) : (
              currency + numberWithCommas(props.cardData.amount)
            )}
          </Typography>

          <Typography variant="body2" display="inline">
            {translateFromHebrew()}
          </Typography>
          <Typography variant="body2" display="inline">
            {!props.cardData?.org ? null : (
              <Text objectName="for" page="status" />
            )}
          </Typography>
          <Typography variant="h5" display="inline">
            {language === "he" && props.cardData.org
              ? props.cardData.org
              : null}
            {language === "en" && props.cardData.orgENG
              ? props.cardData.orgENG
              : null}
            {language === "en" && !props.cardData.orgENG
              ? props.cardData.org
              : null}
          </Typography>
          <Typography variant="h6">
            <div
              style={{ borderTop: "1px dashed #161616", margin: "16px auto" }}
            ></div>
          </Typography>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Typography variant="h5" className={classes.notificationstatus}>
              <NewIcon />
            </Typography>
            <Typography variant="h5" className={classes.notificationstatus}>
              {props.cardData?.Status}
            </Typography>
          </div>
          <Collapse in={moreInfo} timeout="auto">
            {props.cardData.Subject === "Grant Recommendation" ? (
              <NotificationCardInfo cardData={props.cardData} />
            ) : (
              <NotificationCardInfoGeneral cardData={props.cardData} />
            )}
          </Collapse>
          <div style={{ textAlign: "center" }}>
            <Button
              style={{
                height: 44,
                width: 130,
                border: "none",
                // backgroundColor: "#F1F1F1",
              }}
              variant="outlined"
              color="primary"
              onClick={() => {
                setMoreInfo((prev) => !prev);
              }}
            >
              {moreInfo ? (
                <Text objectName="closeBtn" page="history" />
              ) : (
                <Text objectName="moreInfo" page="ngo" />
              )}

              {moreInfo ? (
                <CloseIcon style={{ marginInlineStart: 13 }} />
              ) : (
                <ExpandIcon style={{ marginInlineStart: 13 }} />
              )}
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
