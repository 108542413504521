import React from "react";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Text from "../utilities/Text";

export default function Balance(props) {
  let totalBalance = 0;
  // let totalGrants = 0;
  let totalDeposits = 0;
  if (!props.userData) {
    return null;
  }

  if (props?.userData.user?.deposits.length < 1) {
    return null;
  }
  // totalDeposits = props?.userData.user.deposits[0].amount
  //   console.log("this is totoal " +props?.userData.user.deposits[0].amount)
  // props.userData.user?.deposits.forEach((item) => {
  //   totalDeposits = totalDeposits + parseInt(item.amount);
  //   console.log(totalDeposits);
  // });
  // props.userData.user?.grants.forEach((item) => {
  //   totalGrants = totalGrants + item.amount;
  // });
  totalBalance = props?.userData.user.total_balance;

  return (
    <Box>
      <Typography style={{}} variant="h4">
        {" "}
        <Text objectName="balance" page="mainpage" />
      </Typography>
      <Typography style={{ direction: "ltr" }} variant="h1">
        ₪ {Math.round(totalBalance).toLocaleString()}{" "}
      </Typography>
    </Box>
  );
}
