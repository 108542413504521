import "./App.css";
import React, { useLayoutEffect } from "react";
import Login from "./comp/Login/Login";
import MainPage from "./comp/MainPage";
import GrantRequest from "./comp/GrantRequest";
import ButtonAppBar from "./comp/ButtonAppBar";
import Ngos from "./comp/Ngos";
import Notifications from "./comp/Notifications";
import Status from "./comp/Status";
import GrantHistory from "./comp/GrantHistory";
import Testin from "./comp/Testin";
import Auth from "./utilities/Auth";
import { BrowserRouter as Router, Route, Switch,useHistory } from "react-router-dom";
import {
  AccountsData,
  GlobalData,
  UserData,
  UserFavoritesNgos,
} from "./Contexts";
import NewRequest from "./comp/NewRequest";
import { ThemeProvider, useMediaQuery } from "@material-ui/core";
import theme from "./comp/themes";
import { useLanguageFromQuery } from "./useLanguageFromQuery";
import MultiUser from "./comp/MultiUser";
import Files from "./comp/Files";
import { proxy } from "valtio";
import Itrot from "./comp/Itrot";
import initFb from "./utilities/firebaseConfig";

export const globalState = proxy({ id: null, fireBaseApp: null });
// window.axios = axios;
// require("./mirage");

window.appVersion = "1.0";
function App() {
  const screenSize = useMediaQuery("(min-width:600px)");
  const [language, setLanguage] = React.useState("he");
  const [userData, setUserData] = React.useState();
  const [userFavoritesNgos, setUserFavoritesNgos] = React.useState();
  const [userAccounts, setUserAccounts] = React.useState();
  const history=useHistory()
  
  useLayoutEffect(() => {
    initFb();
  }, []);

  useLanguageFromQuery(setLanguage, language);
  const dir = language === "he" ? "rtl" : "ltr";
  document.documentElement.dir = dir;

  return (
    <GlobalData.Provider value={{ screenSize, language, setLanguage }}>
      <AccountsData.Provider value={[userAccounts, setUserAccounts]}>
        <UserData.Provider value={[userData, setUserData]}>
          <UserFavoritesNgos.Provider
            value={[userFavoritesNgos, setUserFavoritesNgos]}
          >
            <ThemeProvider theme={theme}>
              <Router>
                <div className="App">
                  <Route path="/landing-mail">
                    <MailLanding/>
                  </Route>
                  {userData && <ButtonAppBar></ButtonAppBar>}
                  <Switch>
                    <Route path="/login">
                      <Login setUserData={setUserData} setUserFavoritesNgos={setUserFavoritesNgos}/>
                    </Route>

                    <Route path="/multi">
                      <MultiUser />
                    </Route>

                    <Route exact path="/grantrequest">
                      <Auth>
                        {" "}
                        <GrantRequest />
                      </Auth>
                    </Route>
                    <Route exact path="/ngos">
                      <Auth>
                        {" "}
                        <Ngos />
                      </Auth>
                    </Route>
                    <Route exact path="/files">
                      <Auth>
                        {" "}
                        <Files />
                      </Auth>
                    </Route>
                    <Route exact path="/itrot">
                      <Auth>
                        {" "}
                        <Itrot />
                      </Auth>
                    </Route>
                    <Route exact path="/notifications">
                      <Auth>
                        {" "}
                        <Notifications />
                      </Auth>
                    </Route>
                    <Route exact path="/status">
                      <Auth>
                        {" "}
                        <Status />
                      </Auth>
                    </Route>
                    <Route exact path="/granthistory">
                      <Auth>
                        {" "}
                        <GrantHistory />
                      </Auth>
                    </Route>
                    <Route exact path="/testing">
                      {" "}
                      <Testin />
                    </Route>

                    <Route exact path="/">
                      <Auth>
                        {" "}
                        <MainPage />
                      </Auth>
                    </Route>
                    <Route exact path="/newrequest">
                      <Auth>
                        {" "}
                        <NewRequest />
                      </Auth>
                    </Route>
                  </Switch>
                </div>
              </Router>
            </ThemeProvider>
          </UserFavoritesNgos.Provider>
        </UserData.Provider>
      </AccountsData.Provider>
    </GlobalData.Provider>
  );
}

export default App;

const MailLanding = ()=>{
  return <div></div>
}