import React from "react";

import { Button, Box, Typography, Grid, Fade } from "@material-ui/core";
import { TextField } from "@material-ui/core";

import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import { GlobalData, UserData } from "../Contexts";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core/styles";
import NotificationCard from "./NotificationCard";
import { getUserData } from "../utilities/api";
import { ReactComponent as DepositIcon } from "../img/deposit.svg";
import { ReactComponent as GiveGrantIcon } from "../img/giveGrant.svg";
import { ReactComponent as EmailDeposite } from "../img/emailDeposit.svg";
import { ReactComponent as NewMeassage } from "../img/emailReceived.svg";
import { useHistory } from "react-router-dom";
import Text from "../utilities/Text";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    margin: "6px 2px",
    borderRadius: "16px",
  },
  heading: {
    marginTop: "32px",
    marginBottom: "24px",
  },
  column: {
    flexBasis: "100%",
    width: "100%",
  },
  buttons: {
    marginBottom: "16px",
  },
  readMore: {
    padding: "10px 32px",
    marginBottom: "16px",
    marginTop: "8px",
  },
  searchfield: {
    "& .MuiOutlinedInput-root.Mui-focused": {
      backgroundColor: "#F8F8F8",
      border: "none",
    },
  },
  cardfield: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#FFFFFF",
    },
  },
  main: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      //width: "100%",
      //gap: "3px",
      marginRight: "8.3%",
      marginLeft: "8.3%",
      // justifyContent: "center",
    },
  },
  mainDiv: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "column",
      //flexWrap: "wrap",
      //marginRight: "8.3%",
      //marginLeft: "8.3%",
      //justifyContent: "center",
      width: "60%",
      flex: 1,
      flexBasis: "65%",
    },
    [theme.breakpoints.up("xl")]: {
      flexBasis: "70%",
      width: "70%",
    },
  },
  buttonsDiv: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "column",
      //flexWrap: "wrap",
      //marginRight: "8.3%",
      //marginLeft: "8.3%",
      //justifyContent: "center",
      width: "20%",
      flex: 1,
      flexBasis: "25%",
    },
    [theme.breakpoints.up("xl")]: {
      flexBasis: "30%",
      width: "30%",
    },
  },
  cardsDiv: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      gap: "12px",
      //marginRight: "8.3%",
      //marginLeft: "8.3%",
      //justifyContent: "center",
    },
  },
  cardDesktop: {
    [theme.breakpoints.up("md")]: {
      minWidth: "382px",
    },
  },
  subTitle: {
    marginTop: "32px",
    marginBottom: "24px",
    [theme.breakpoints.up("md")]: {
      marginTop: "16px",
    },
  },
}));

export default function Status() {
  const [userCtx, setUserCtx] = React.useContext(UserData);
  const globalData = React.useContext(GlobalData);
  const screenSize = globalData.screenSize;
  let CARDS_TO_SHOW = !screenSize ? 2 : 4;
  const [filteredRequests, setFilteredRequests] = React.useState([]);
  const [requestsCounter, setRequestsCounter] = React.useState(CARDS_TO_SHOW);
  const [loading, setLoading] = React.useState(<CircularProgress />);

  const requests = userCtx?.user?.requests;

  const classes = useStyles();

  React.useEffect(() => {
    async function fetchUser() {
      const response = await getUserData();
      if (response.status === 200) {
        setUserCtx({ user: response.data });
      }
    }

    setTimeout(fetchUser, 8000);
  }, []);

  React.useEffect(() => {
    setFilteredRequests(requests);
    const text = <Text page="status" objectName="noResults" />;
    setLoading(text);
  }, [requests]);
  const history = useHistory();

  function handleSearch(data) {
    setRequestsCounter(CARDS_TO_SHOW);
    let newRequests = requests.filter((term) => {
      if (term?.org?.includes(data) || term?.Subject?.includes(data)) {
        return true;
      }
      return false;
    });

    data !== ""
      ? setFilteredRequests(newRequests)
      : setFilteredRequests(requests);
  }

  let requests_cards = "";
  if (filteredRequests?.length === 0 || !filteredRequests) {
    requests_cards = (
      <div style={{ margin: "16px auto" }}>
        <Typography variant="h4">{loading}</Typography>
      </div>
    );
  } else {
    // filteredRequests ? (req = filteredRequests) : (req = requests);
    requests_cards = filteredRequests?.map((item, index) => {
      if (index < requestsCounter) {
        return (
          <div
            key={index}
            style={{ maxWidth: "382px" }}
            className={classes.cardDesktop}
          >
            <NotificationCard cardData={item} />
          </div>
        );
      }
    });
  }

  return (
    <Fade in={true} timeout={1000} unmountOnExit>
      <Box px={2}>
        <div className={classes.main}>
          <div style={{ textAlign: "start" }} className={classes.mainDiv}>
            <Typography variant="h2" style={{ marginTop: "16px" }}>
              <Text objectName="title" page="status" />
            </Typography>

            <TextField
              variant="outlined"
              color="primary"
              placeholder={Text({ page: "status", objectName: "searchBar" })}
              style={{
                width: "100%",
                margin: "8px 0px",
                maxWidth: "792px",
              }}
              className={classes.searchfield}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                handleSearch(e.target.value);
              }}
            />
            <div className={classes.cardsDiv}>{requests_cards}</div>
            <Grid container>
              <Grid item xs={7} md={0}></Grid>
              <Grid
                item
                xs={5}
                md={11}
                noWrap="false"
                style={{
                  left: 0,
                  padding: "0px",
                  margin: "0px",
                  textAlign: "start",
                }}
              >
                {requestsCounter < filteredRequests?.length && (
                  <Button
                    variant="text"
                    color="primary"
                    className={classes.readMore}
                    onClick={() => {
                      setRequestsCounter((prev) => prev + CARDS_TO_SHOW);
                    }}
                  >
                    <Text objectName="showMoreButton" page="status" />
                  </Button>
                )}
              </Grid>
            </Grid>
            <Button
              variant="contained"
              color="primary"
              style={{ maxWidth: "382px" }}
              disableRipple
              onClick={() => {
                history.push("/ngos");
              }}
              startIcon={
                <GiveGrantIcon style={{ marginLeft: 12, stroke: "none" }} />
              }
            >
              <Text objectName="grantbutton" page="status" />
            </Button>
          </div>
          {/* Buttons Part */}
          <div className={classes.buttonsDiv}>
            <Typography variant="h2" className={classes.subTitle}>
              <Text objectName="secondtitle" page="status" />
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              className={classes.buttons}
              startIcon={<DepositIcon style={{ marginLeft: 12 }} />}
              onClick={() => {
                history.push({
                  pathname: "/newrequest",
                  state: { page: "Add to Account" },
                });
              }}
            >
              <Text objectName="accountbutton" page="status" />
            </Button>
            <Button
              variant="outlined"
              color="primary"
              className={classes.buttons}
              startIcon={<EmailDeposite style={{ marginLeft: 12 }} />}
              onClick={() => {
                history.push({
                  pathname: "/newrequest",
                  state: { page: "Question about Account" },
                });
              }}
            >
              <Text objectName="questionbutton" page="status" />
            </Button>
            <Button
              variant="contained"
              color="secondary"
              className={classes.buttons}
              startIcon={<NewMeassage style={{ marginLeft: 12 }} />}
              onClick={() => {
                history.push({
                  pathname: "/newrequest",
                  state: { page: "General request" },
                });
              }}
            >
              <Text objectName="genralbutton" page="status" />
            </Button>
          </div>
        </div>
      </Box>
    </Fade>
  );
}
