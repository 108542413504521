import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    margin: "12px 2px",
    borderRadius: "16px",
  },

  kbutton: {
    backgroundColor: "#FFF",
    borderRadius: "16px",
    //width: "56px",
    height: "32px",
    //color: "#8C8C8C",

    fontWeight: 400,
    fontSize: 14,
    margin: "3px 5px",
    "&:hover": {
      backgroundColor: "#FFF",
    },
  },
  kbuttonActive: {
    backgroundColor: "#21C082",
    borderRadius: "16px",
    //width: "56px",
    border: "1px solid #161616",
    height: "31px",

    fontWeight: 400,
    fontSize: 14,
    margin: "3px 5px",
    "&:hover": {
      backgroundColor: "#21C082",

      border: "1px solid #161616",
      "@media (hover: none)": {
        backgroundColor: "#21C082",

        border: "1px solid #161616",
        "&:active": {
          backgroundColor: "#21C082",

          border: "1px solid #161616",
        },
      },
    },
    "&:active": {
      backgroundColor: "#21C082",
    },
  },
}));

export default function KRadioButtonsMultiPick(props) {
  const [value, setValue] = React.useState(props.value || []);
  const classes = useStyles();
  const buttons = props?.buttonsData;
  const handleValue = props?.handleValue;
  React.useEffect(() => {
    handleValue(value);
    // console.log("Arr2: ", value)
  }, [JSON.stringify(value)]);
  return (
    <div style={{ backgroundColor: "#F8F8F8" }}>
      {buttons.map((bdata) => {
        return (
          <React.Fragment>
            {bdata && (
              <Button
                disableFocusRipple
                disableRipple
                disableTouchRipple
                className={
                  value.includes(bdata.value) || value.includes(bdata)
                    ? classes.kbuttonActive
                    : classes.kbutton
                }
                onClick={() => {
                  if (value.includes(bdata.value) || value.includes(bdata)) {
                    const arr = value;

                    for (let i = 0; i < arr.length; i++) {
                      if (arr[i] === bdata.value || arr[i] === bdata) {
                        arr.splice(i, 1);
                        i--;
                      }
                    }

                    // console.log("Arr: ", arr)
                    setValue((prev) => {
                      return [...arr];
                    });
                  } else {
                    bdata?.value
                      ? setValue((prev) => {
                          return [...prev, bdata.value];
                        })
                      : setValue((prev) => {
                          return [...prev, bdata];
                        });
                  }
                }}
              >
                {bdata.text || bdata}
              </Button>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
}
