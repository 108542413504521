import { Avatar } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { Button } from "@material-ui/core/";
import Typography from "@material-ui/core/Typography";
//import firebase from "firebase/app";
import React from "react";
import "react-phone-input-2/lib/material.css";
import { useHistory } from "react-router-dom";
import { GlobalData, UserData, AccountsData } from "../Contexts";
import { useLanguage } from "../hooks";
import axios from "axios";
import { ReactComponent as Logo } from "../img/logo.svg";

import Text from "../utilities/Text";
import "./Login/CustomCss.css";
import FixedLogo from "./Login/FixedLogo";
import styles from "./Login/Login.module.css";

export default function MultiUser() {
  const [userCtx, setUserCtx] = React.useContext(UserData);
  const [userAccounts, setUserAccounts] = React.useContext(AccountsData);
  const history = useHistory();
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  // if (userCtx) {
  //   history.push("/");
  // }

  const language = useLanguage();
  const desktopContainer = {
    width: 406,
    margin: [language === "he" ? "22vh 28vw" : "22vh 45vw"],
  };

  const { screenSize } = React.useContext(GlobalData);
  console.log(userAccounts);
  const firstLetters = (name) => {
    if (name?.split(" ").length > 1) {
      return name?.split(" ")[0].charAt(0) + name?.split(" ")[1].charAt(0);
    } else {
      return "OO";
    }
  };
  async function handleClick(id) {
    axios.defaults.headers.common["keshet_account"] = id;
    localStorage.setItem("userAccount", id);
    //console.log("pushed ", id);
    // const res = await getUserData();
    // console.log(res);
    // if (res.status === 200) {
    history.push("/");
    // } else {
    //   history.push("/login");
    // }
  }
  const desktopView = screenSize;
  const mobileView = !screenSize;

  return (
    <div
      className="loginContainer"
      style={desktopView ? desktopContainer : null}
    >
      <div style={{ margin: "0px 24px" }}>
        <div>
          <div className={styles.svgLogo}>{mobileView && <Logo />}</div>
          {/* {mobileView && <SplashScreen />} */}
          {desktopView && <FixedLogo />}

          <div className="divide" style={{ height: 73 }}></div>
          <Typography variant="h2">
            {" "}
            <Text objectName="title" page="multi" />
            {language === "he"
              ? userAccounts?.accounts[0]?.Content_name_HEB
              : userAccounts?.accounts[0]?.Content_name_ENG}
          </Typography>
          <Typography variant="body2" style={{ marginBottom: 66 }}>
            <Text objectName="choose" page="multi" />
          </Typography>
        </div>
        {userAccounts &&
          userAccounts?.accounts?.map((item, index) => {
            console.log(item);
            return (
              <React.Fragment>
                <Grid
                  container
                  spacing={2}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={2}>
                    <Avatar
                      alt="{avatarLetters}"
                      style={{
                        backgroundColor: "#F8F8F8",
                        color: "#004E5E",
                        fontWeight: 700,
                      }}
                    >
                      {firstLetters(item.Account_name_HEB)}
                    </Avatar>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" color="primary">
                      {item.Account_name_HEB}
                    </Typography>
                  </Grid>

                  <Grid item xs={4}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        handleClick(item.Account_id);
                      }}
                      style={{
                        height: 44,
                        width: 99,
                        border: "none",
                        backgroundColor: "#F1F1F1",
                      }}
                    >
                      <Text objectName="select" page="multi" />
                    </Button>
                  </Grid>
                </Grid>
                <div
                  style={{
                    borderBottom: "1px solid #F1F1F1",
                    margin: "20px 0px",
                  }}
                ></div>
              </React.Fragment>
            );
          })}
      </div>
    </div>
  );
}
