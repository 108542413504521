import React from "react";
import { useHistory } from "react-router-dom";
import { UserData, AccountsData, UserFavoritesNgos } from "../Contexts";

import { getFavsNgos, getUserAccounts, getUserData } from "./api";
import SplashScreen from "../comp/Login/SplashScreen";
import axios from "axios";
import { globalState } from "../App";
import { getAuth } from "firebase/auth";

export default function Auth(props) {
  const [userCtx, setUserCtx] = React.useContext(UserData);
  const [userAccounts, setUserAccounts] = React.useContext(AccountsData);
  const [favsNgos, setFavsNgos] = React.useContext(UserFavoritesNgos);
  const history = useHistory();

  const getFbUserUid = () => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user !== null) {
      globalState.id = user.uid;
    }
  };

  React.useEffect(() => {
    const token = localStorage.getItem("userJWT");
    (async () => {
      try{if (!token) {
        //no token!
        history.push("/login");
        return;
      }
      if (!userAccounts) {
        const data = await getUserAccounts();
        if (data.status === 200) {
          setUserAccounts({ accounts: data.data });
          if (data.data.length === 1) {
            axios.defaults.headers.common["keshet_account"] =
              data.data[0].Account_id;
          }
        }
      }
      if (!userCtx) {
        const userAccount = localStorage.getItem("userAccount");
        if (userAccount) {
          axios.defaults.headers.common["keshet_account"] = userAccount;
        }
        const data = await getUserData();
        const favs = await getFavsNgos(data.data?.account_id);
        setFavsNgos(favs);
        if (data.status === 200) {
          const favs = await getFavsNgos(data.data.account_id);
          setFavsNgos(favs);

          setUserCtx({ user: data.data });
          getFbUserUid();
        } else {
          //invalid json token!
          localStorage.clear();
          history.push("/login");
          return null;
        }
      }}catch(err){
        console.log(err);
        history.push("/login");

      }
    })();
  }, []);

  return (
    <>
      <SplashScreen fade={userCtx && "hide"} />
      {userCtx && props.children}
    </>
  );
}
