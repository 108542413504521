import React, { useState } from "react";
import { Box, Button, Paper, TextField } from "@material-ui/core/";
import SimpleAccordion from "./SimpleAccordion";
import { GlobalData, UserData, UserFavoritesNgos } from "../Contexts";
import DoughnutChart from "./Doughnut";
import DoughnutChartDesktop from "./DoughnutDesktop";
import DoughnutChartInvests from "./DoughnutInvests";
import Balance from "./Balance";
import { ReactComponent as DepositIcon } from "../img/deposit.svg";
import { ReactComponent as GiveGrantIcon } from "../img/giveGrant.svg";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Text from "../utilities/Text";
import { Fade } from "@material-ui/core";
import DoughnutInvestsDesktop from "./DoughnutInvestsDesktop";
import styled from "styled-components";
import { openNewTicket } from "../utilities/api";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    margin: "6px 2px",
    borderRadius: "16px",
  },
  rightButton: {
    marginTop: "16px",
    height: "100%",
    borderStartStartRadius: "24px",
  },
  rightButtonOnly: {
    borderTopRightRadius: "24px",
    borderTopLeftRadius: "24px",
    marginTop: "16px",
  },
  leftButton: {
    borderStartEndRadius: "24px",
    marginTop: "16px",
    height: "100%",
  },
}));

export default function MainPage() {
  const globalData = React.useContext(GlobalData);

  const screenSize = globalData.screenSize;

  const [userCtx, setUserCtx] = React.useContext(UserData);
  const [userFavsNgos, setUserFavsNgos] = React.useContext(UserFavoritesNgos);

  const [windowToShow, setWindowToShow] = React.useState("grantHistory");

  const [selected, setSelected] = React.useState("");
  const classes = useStyles();

  const haveInvests = Object.keys(userCtx?.user?.invests).length > 0;

  const selectedTab = {
    backgroundColor: "#F8F8F8",
    color: "#161616",
  };
  const unSelectedTab = {
    backgroundColor: "#FFFFFF",
    color: "#C7C7C7",
  };
  const topButtonsStyle = {
    marginInlineEnd: "11px",
  };

  let screen;
  if (windowToShow === "grantHistory") {
    screen = (
      <DoughnutWrapper>
        {screenSize ? (
          <DoughnutChartDesktop userData={userCtx}></DoughnutChartDesktop>
        ) : (
          <DoughnutChart userData={userCtx}></DoughnutChart>
        )}
      </DoughnutWrapper>
    );
  }
  if (windowToShow === "investment") {
    screen = (
      <DoughnutWrapper>
        {screenSize ? (
          <DoughnutInvestsDesktop userData={userCtx}></DoughnutInvestsDesktop>
        ) : (
          <DoughnutChartInvests userData={userCtx}></DoughnutChartInvests>
        )}
      </DoughnutWrapper>
    );
  }

  let menuButtons;

  if (haveInvests) {
    menuButtons = (
      <>
        <MenuButtonsWrapper>
          <Button
            className={classes.rightButton}
            style={
              windowToShow === "grantHistory" ? selectedTab : unSelectedTab
            }
            fullWidth="true"
            onClick={() => {
              setWindowToShow("grantHistory");
            }}
            id="grantHistory"
          >
            <Typography variant="h4">
              <Text objectName="grantHistory" page="mainpage" />

              <br />
              <Typography style={{ fontSize: "14px" }}>
                {" "}
                <Text objectName="fiscalYear" page="mainpage" />
                {new Date().getFullYear()}
              </Typography>
            </Typography>
          </Button>

          <Button
            className={classes.leftButton}
            style={windowToShow === "investment" ? selectedTab : unSelectedTab}
            fullWidth="true"
            onClick={() => {
              setWindowToShow("investment");
            }}
            id="investment"
          >
            <Typography variant="h4">
              <Text objectName="investmentDetails" page="mainpage" />

              <br />
              <Typography style={{ fontSize: "14px" }}>
                {" "}
                <Text objectName="fiscalYear" page="mainpage" />
                {new Date().getFullYear()}
              </Typography>
            </Typography>
          </Button>
        </MenuButtonsWrapper>
      </>
    );
  } else {
    menuButtons = (
      <>
        <Grid item xs={12}>
          <Button
            className={classes.rightButtonOnly}
            style={
              windowToShow === "grantHistory" ? selectedTab : unSelectedTab
            }
            fullWidth="true"
            onClick={() => {
              setWindowToShow("grantHistory");
            }}
          >
            <Typography variant="h4">
              <Text objectName="grantHistory" page="mainpage" />
              <br />
              <Typography style={{ fontSize: "14px" }}>
                {" "}
                <Text objectName="fiscalYear" page="mainpage" />
                {new Date().getFullYear()}
              </Typography>
            </Typography>
          </Button>
        </Grid>
      </>
    );
  }

  const [sentAccount, setSentAccount] = useState(false);
  return (
    <>
      <Fade in={true} timeout={1000}>
        <MainPageWrapper>
          <BalanceWrapper>
            <Balance userData={userCtx}></Balance>
          </BalanceWrapper>
          <ButtonsWrapper>
            <Link to="/ngos" style={{ textDecoration: "none", width: "165px" }}>
              {" "}
              <Button variant="outlined" color="primary" id="grantBtn">
                <GiveGrantIcon style={topButtonsStyle} />{" "}
                <Text objectName="grantBtn" page="mainpage" />
              </Button>
            </Link>

            <Link
              to={{
                pathname: "/itrot",
                state: { page: "Add to Account" },
              }}
              style={{ textDecoration: "none", width: "165px" }}
            >
              {" "}
              <Button variant="outlined" color="primary" id="addToAccount">
                <DepositIcon style={topButtonsStyle} />
                <Text objectName="itrot" page="mainpage" />{" "}
              </Button>
            </Link>
          </ButtonsWrapper>
          <DoughnutAreaWrapper>
            {menuButtons}
            <div>
              <DoughnutAreaBackground>{screen}</DoughnutAreaBackground>

              {windowToShow === "investment" ? (
                <div>
                  <Button
                    variant="contained"
                    disabled={sentAccount}
                    color="primary"
                    onClick={async () => {
                      await openNewTicket({
                        userData: userCtx.user,
                        ticketType: "Question about Account",
                        details: "user would like his account details",
                        request: "user would like his account details",
                      });
                      setSentAccount(true);
                    }}
                    style={
                      screenSize
                        ? { margin: "16px auto 8px auto", width: "50%" }
                        : { margin: "16px auto 8px auto", width: "100%" }
                    }
                    id="sendAccount"
                  >
                    <GiveGrantIcon style={{ marginInlineEnd: "5px" }} />
                    <Text
                      objectName={sentAccount ? "sentAccount" : "sendAccount"}
                      page="mainpage"
                    />
                  </Button>
                </div>
              ) : (
                <Link to="/ngos" style={{ textDecoration: "none" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={
                      screenSize
                        ? { margin: "16px auto 8px auto", width: "50%" }
                        : { margin: "16px auto 8px auto", width: "100%" }
                    }
                    id="GrantRecommendation"
                  >
                    <GiveGrantIcon style={{ marginInlineEnd: "5px" }} />
                    <Text objectName="GrantRecommendation" page="mainpage" />
                  </Button>
                </Link>
              )}
            </div>
          </DoughnutAreaWrapper>
          <Accordion>
            <SimpleAccordion />
          </Accordion>
        </MainPageWrapper>
      </Fade>
    </>
  );
}

const DoughnutWrapper = styled.div``;

const MenuButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const MainPageWrapper = styled.div`
  margin-top: 40px;
  width: 100vw;
  display: grid;
  grid-template-columns: 2vw 53vw 43vw 2vw;
  grid-template-rows: 100px 100px 25vw;
  grid-template-areas:
    ". userBalance buttons ."
    ". doughnut accordion ."
    ". doughnut . .";
  @media only screen and (max-width: 600px) {
    width: 100vw;
    display: grid;
    grid-template-columns: 2vw auto 2vw;
    grid-template-rows: auto;
    grid-template-areas:
      ". userBalance ."
      ". buttons ."
      ". doughnut ."
      ". accordion .";
  }
`;
const BalanceWrapper = styled.div`
  padding-top: 15px;
  grid-area: userBalance;
`;

const ButtonsWrapper = styled.div`
  padding-top: 15px;
  grid-area: buttons;
  display: flex;
  flex-direction: row;
  gap: 24px;
  margin: 0 auto;
`;

const DoughnutAreaWrapper = styled.div`
  grid-area: doughnut;
  display: flex;
  flex-direction: column;
`;

const DoughnutAreaBackground = styled.div`
  background-color: #f8f8f8;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
`;

const Accordion = styled.div`
  grid-area: accordion;
  margin: auto;
`;
