import React from "react";
import {
  Box,
  Button,
  Fade,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";

import Card from "@material-ui/core/Card";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import { GlobalData, UserData } from "../Contexts";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as FilterIcon } from "../img/filter.svg";
import { useHistory } from "react-router-dom";
import HistoryCard from "./HistoryCard";
import { DialogContent } from "@material-ui/core/";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { ReactComponent as ClosedIcon } from "../img/AccordionClosed.svg";
import { ReactComponent as OpenIcon } from "../img/AccordionOpen.svg";
import KRadioButtons from "./KRadioButtons";
import Text from "../utilities/Text";
import { motion } from "framer-motion";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    margin: "6px 2px",
    borderRadius: "16px",
  },
  heading: {
    marginTop: "32px",
    marginBottom: "24px",
  },
  column: {
    flexBasis: "100%",
    width: "100%",
  },
  buttons: {
    marginBottom: "16px",
  },
  searchfield: {
    "& .MuiOutlinedInput-root.Mui-focused": {
      backgroundColor: "#F8F8F8",
      border: "none",
    },
  },
  cardfield: {
    backgroundColor: "#FFFFFF",
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#FFFFFF",
    },
  },
  accordionSummary: {
    flexDirection: "row-reverse",
  },
  mainDiv: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "row",
      //flexWrap: "wrap",
      //marginRight: "9.3%",
      //marginLeft: "9.3%",
      //justifyContent: "center",
    },
  },
  mainCardsDiv: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      //marginRight: "5.3%",
      //marginLeft: "5.3%",
      justifyContent: "center",
      alignItems: "stretch",
      flexBasis: "80%",
      //flexGrow: 2,
    },
  },
  searchDiv: {
    [theme.breakpoints.up("md")]: {
      // flexDirection: "row",
      // flexWrap: "wrap",
      //marginInlineStart: "auto",
      //marginInlineEnd: "auto",
      //maxWidth: "1140px",
      justifyContent: "center",
      width: "100%",
    },
  },
  logoDiv: {
    [theme.breakpoints.up("md")]: {
      //display: "flex",
      //marginInlineStart: "9.3%",
      //maxWidth: "1140px",
      width: "100%",
    },
  },
  startSide: {
    //display: "none",
    [theme.breakpoints.up("md")]: {
      // display: "inline",
      //marginInlineStart: "9.3%",
      //maxWidth: "1140px",
      width: "20%",
      minWidth: "10%",
      //flexGrow: 1,
      flexBasis: "10%",
    },
  },
  endSide: {
    //display: "none",
    [theme.breakpoints.up("md")]: {
      // display: "inline",
      //marginInlineStart: "9.3%",
      //maxWidth: "1140px",
      width: "20%",
      minWidth: "10%",
      //flexGrow: 1,
      flexBasis: "10%",
    },
  },
  cardDesk: {
    border: "1px solid #3B8CA8",
  },
  cardDiv: {
    [theme.breakpoints.up("md")]: {
      width: "31%",
      marginInlineEnd: "24px",
      maxWidth: "384px",
      minWidth: "384px",
    },
  },
  desktop: {
    [theme.breakpoints.up("md")]: {
      marginInlineStart: "300px",
      marginInlineEnd: "300px",
      maxWidth: "1140px",
    },
  },
  desktopTitle: {
    [theme.breakpoints.up("md")]: {
      marginInlineStart: "300px",
      marginInlineEnd: "300px",
      maxWidth: "1140px",
      marginTop: "165px",
    },
  },
  closeBtn: {
    width: "18px",
    height: "18px",
    [theme.breakpoints.up("md")]: {
      width: "30px",
      height: "30px",
    },
  },
}));

//add the filter like in the ngos
export default function GrantHistory() {
  const globalData = React.useContext(GlobalData);
  const screenSize = globalData.screenSize;
  const [userCtx, setUserCtx] = React.useContext(UserData);
  const [grants, setGrants] = React.useState([]);
  const [filteredGrants, setFilteredGrants] = React.useState([]);
  const [yearFilter, setYearFilter] = React.useState([]);
  const [ngoFilter, setNgoFilter] = React.useState([]);
  const [currentFilter, setCurrentFilter] = React.useState({
    year: "",
    ngo: "",
  });
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [loading, setLoading] = React.useState(true);
  const [isHidden, setIsHidden] = React.useState(false);

  const hiddenStyle = {
    display: "none",
  };
  const notHiddenStyle = {
    display: "flex",
    flexDirection: "column",
  };

  React.useEffect(() => {
    async function fetchGrants() {
      const response = await userCtx?.user?.grants;

      //TODO - CHANGE WITH CONTEXT
      setGrants(response);
      setFilteredGrants(response);
      setLoading(false);
      makeFilters(response);
    }

    fetchGrants();
  }, [userCtx]);

  const makeFilters = (data) => {
    const years = data?.map((grant) => {
      if (!grant.request_date) {
        return;
      } else {
        return grant?.request_date.substr(0, 4);
      }
    });

    setYearFilter(Array.from(new Set(years)));

    const ngos = data?.map((grant) => {
      return grant?.ngo.name;
    });

    setNgoFilter(Array.from(new Set(ngos)));
  };

  const handleYear = (year) => {
    setCurrentFilter((prev) => {
      return { ...prev, year: year };
    });
  };
  const handleNgo = (ngo) => {
    setCurrentFilter((prev) => {
      return { ...prev, ngo };
    });
  };

  useEffect(() => {
    handleFilter();
  }, [currentFilter]);
  const classes = useStyles();

  function handleSearch(ngo) {
    setCurrentFilter({ ...currentFilter, ngo });
  }

  function handleFilter() {
    if (currentFilter.year === "" && currentFilter.ngo === "") {
      setFilteredGrants(grants);
      return;
    }
    const newGrants = grants.filter((grant) => {
      if (
        (grant?.ngo.name.includes(currentFilter?.ngo) ||
          currentFilter?.ngo === "") &&
        (grant?.request_date.substr(0, 4) === currentFilter?.year ||
          currentFilter?.year === "")
      ) {
        return true;
      }
    });
    setFilteredGrants(newGrants);
  }

  return (
    <Fade in={true} timeout={1000} unmountOnExit>
      <React.Fragment>
        <Box mx={2}>
          <div>
            <div className={classes.mainDiv}>
              <div className={classes.startSide}></div>
              <div className={classes.mainCardsDiv}>
                <div style={{ textAlign: "start" }} className={classes.logoDiv}>
                  <Typography variant="h2" style={{ marginTop: "20px" }}>
                    <Text objectName="title" page="history" />
                  </Typography>
                </div>
                <div className={classes.searchDiv}>
                  <Grid
                    container
                    spacing={1}
                    //justifyContent="space-between"
                    alignItems="center"
                    //alignContent="space-between"
                  >
                    <Grid item xs={12} sm={12} wrap="wrap">
                      <TextField
                        variant="outlined"
                        color="primary"
                        placeholder={Text({
                          page: "history",
                          objectName: "searchBar",
                        })}
                        style={{
                          //   height: "56px",
                          width: "100%",
                          //marginRight: "16px",
                        }}
                        className={classes.searchfield}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        value={currentFilter.ngo}
                        onChange={(e) => {
                          handleSearch(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sm={2}
                      //              alignContent="flex-end"
                      //style={{ textAlign: "left" }}
                    >
                      {/*<Button*/}
                      {/*    onClick={handleClickOpen}*/}
                      {/*    style={{*/}
                      {/*        height: "54px",*/}
                      {/*        width: "100%",*/}
                      {/*        margin: "16px 0px",*/}
                      {/*        backgroundColor: "#F8F8F8",*/}
                      {/*        fontSize: "16px",*/}
                      {/*        fontWeight: "400",*/}
                      {/*        borderRadius: "8px",*/}
                      {/*        color: "#004E5E",*/}
                      {/*        fill: "#004E5E",*/}
                      {/*    }}*/}
                      {/*    startIcon={<FilterIcon style={{marginLeft: 12}}/>}*/}
                      {/*>*/}
                      {/*    <Text objectName="openFilterButton" page="history"/>*/}
                      {/*</Button>*/}
                    </Grid>
                  </Grid>
                </div>

                <div style={isHidden ? hiddenStyle : notHiddenStyle}>
                  {/*<div>*/}
                  {/*    <IconButton*/}
                  {/*        onClick={handleClose}*/}
                  {/*        style={{*/}
                  {/*            insetInlineEnd: "15px",*/}
                  {/*            top: "35px",*/}
                  {/*            position: "absolute",*/}
                  {/*        }}*/}
                  {/*    >*/}
                  {/*        <CloseIcon className={classes.closeBtn}/>*/}
                  {/*    </IconButton>*/}
                  {/*</div>*/}
                  <Accordion
                    style={{ backgroundColor: "#F8F8F8", width: "100%" }}
                    elevation={0}
                    expanded={expanded === "panel1"}
                    onChange={handleChange("panel1")}
                  >
                    <AccordionSummary
                      style={{ backgroundColor: "#F8F8F8", width: "90%" }}
                      className={classes.accordionSummary}
                      expandIcon={
                        expanded === "panel1" ? <OpenIcon /> : <ClosedIcon />
                      }
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography variant="h4" color="primary">
                        <Text objectName="filteryear" page="history" />
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{ backgroundColor: "#F8F8F8" }}
                      className={classes.root}
                    >
                      <div style={{ margin: "auto 12px" }}>
                        <KRadioButtons
                          buttonsData={yearFilter}
                          handleValue={handleYear}
                          value={currentFilter.year}
                        />
                      </div>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    style={{ backgroundColor: "#F8F8F8" }}
                    elevation={0}
                    expanded={expanded === "panel2"}
                    onChange={handleChange("panel2")}
                  >
                    <AccordionSummary
                      style={{ backgroundColor: "#F8F8F8" }}
                      className={classes.accordionSummary}
                      expandIcon={
                        expanded === "panel2" ? <OpenIcon /> : <ClosedIcon />
                      }
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography variant="h4" color="primary">
                        <Text objectName="filterngo" page="history" />
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ backgroundColor: "#F8F8F8" }}>
                      <div style={{ margin: "auto 12px" }}>
                        <KRadioButtons
                          buttonsData={ngoFilter}
                          handleValue={handleNgo}
                          value={currentFilter.ngo}
                        />
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
                {loading && <CircularProgress />}
                {/* <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "130vh",
                    width: "100"
                    flexWrap: "wrap",
                    alignContent: "center",
                  }}
                > */}
                {filteredGrants?.length !== 0 ? (
                  filteredGrants?.map((grant, idx) => {
                    return (
                      <motion.div
                        layout
                        animate={{ opacity: 1 }}
                        initial={{ opacity: 0 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                        key={idx}
                        className={classes.cardDiv}
                      >
                        <Card className={classes.cardDesk}>
                          <HistoryCard cardData={grant} />
                        </Card>
                      </motion.div>
                    );
                  })
                ) : (
                  <Typography variant="h4" color="primary">
                    <Text objectName="submittedTitle" page="history" />
                  </Typography>
                )}
                {/* </div> */}
              </div>
              <div className={classes.endSide}></div>
            </div>
          </div>
        </Box>
      </React.Fragment>
    </Fade>
  );
}
