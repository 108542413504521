import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    margin: "12px 2px",
    borderRadius: "16px",
  },

  kbutton: {
    backgroundColor: "#FFF",
    borderRadius: "16px",
    //width: "56px",
    height: "32px",
    //color: "#8C8C8C",

    fontWeight: 400,
    fontSize: 14,
    margin: "3px 5px",
    "&:hover": {
      backgroundColor: "#FFF",
    },
  },
  kbuttonActive: {
    backgroundColor: "#21C082",
    borderRadius: "16px",
    //width: "56px",
    border: "1px solid #161616",
    height: "31px",

    fontWeight: 400,
    fontSize: 14,
    margin: "3px 5px",
    "&:hover": {
      backgroundColor: "#21C082",

      border: "1px solid #161616",
      "@media (hover: none)": {
        backgroundColor: "#21C082",

        border: "1px solid #161616",
        "&:active": {
          backgroundColor: "#21C082",

          border: "1px solid #161616",
        },
      },
    },
    "&:active": {
      backgroundColor: "#21C082",
    },
  },
}));

export default function KRadioButtons(props) {
  const [value, setValue] = React.useState("");
  const classes = useStyles();
  const buttons = props?.buttonsData;
  const setNgo = props?.handleValue;
  React.useEffect(() => {
    setNgo(value);
  }, [value]);

  React.useEffect(() => {
    setValue(props.value);
  }, [props]);
  return (
    <div style={{ backgroundColor: "#F8F8F8" }}>
      {buttons.map((bdata) => {
        return (
          <React.Fragment>
            {bdata && (
              <Button
                disableFocusRipple
                disableRipple
                disableTouchRipple
                className={
                  value === bdata.value || value === bdata
                    ? classes.kbuttonActive
                    : classes.kbutton
                }
                onClick={() => {
                  if (value === bdata.value || value === bdata) {
                    setValue("");
                  } else {
                    bdata.value ? setValue(bdata.value) : setValue(bdata);
                  }
                }}
              >
                {bdata.text || bdata}
              </Button>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
}
