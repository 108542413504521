import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import { ReactComponent as DownloadIcon } from "../img/ReviewDoc.svg";
import { ReactComponent as FileIcon } from "../img/PDFfiles.svg";
import { globalState } from "../App";
import { useSnapshot } from "valtio";
import axios from "axios";
import styled from "styled-components";
import Text from "../utilities/Text";
import { getStorage } from "firebase/storage";
import { getFirestore, getDoc, doc } from "firebase/firestore";

export default function Notifications() {
  const snapshot = useSnapshot(globalState);
  const db = getFirestore();

  let notificationsRef;

  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    if (!snapshot.id) {
      return;
    }

    const docRef = doc(db, "notifications", snapshot.id);
    getDoc(docRef).then((data) => {
      if (data.exists()) {
        const res = data.data();

        setNotifications(res.notifications_list);
      }
    });
  }, [snapshot.id]);

  const NotificationCta = ({ url, isRead }) => {
    return (
      <NotificationsWrapper>
        <NotificationDot
          style={{ display: isRead ? "none" : "inline" }}
        ></NotificationDot>
        <NotificationWrapper>
          <FileIcon style={{ marginInlineEnd: "10px" }} />{" "}
          <Typography color="primary" variant="body2">
            {url.split("notifications/")[1]}
          </Typography>
          <DownloadIcon
            onClick={async () => {
              const notifications = globalState.notifications.map(
                (item) => item
              );
              const indexToChange = notifications.findIndex((item) => {
                return item.url === url;
              });
              notifications[indexToChange].isRead = true;
              notificationsRef.update({
                notifications_list: notifications,
              });

              const storage = getStorage();
              const downloadURL = await storage.ref(url).getDownloadURL();
              const keshetAccount =
                axios.defaults.headers.common["keshet_account"];
              delete axios.defaults.headers.common["keshet_account"];
              const file = await axios({
                url: downloadURL,
                method: "GET",
                responseType: "blob",
              });
              axios.defaults.headers.common["keshet_account"] = keshetAccount;
              const blob = window.URL.createObjectURL(new Blob([file.data]));

              const link = document.createElement("a");
              link.href = blob;
              link.setAttribute("download", url); //or any other extension
              document.body.appendChild(link);
              link.click();
            }}
            style={{
              marginInlineStart: "auto",
              backgroundColor: "#F1F1F1",
              borderRadius: "30px",
              padding: "3px",
              marginInlineEnd: "15px",
              cursor: "pointer",
            }}
          />
        </NotificationWrapper>
      </NotificationsWrapper>
    );
  };

  return (
    <Wrapper>
      <NotificationsHeader variant="h2">
        <Text objectName="notifications" page="notifications" />
      </NotificationsHeader>

      {notifications
        .sort((a, b) => new Date(a.createAt) > new Date(b.createAt))
        .map((notification) => {
          return (
            <NotificationCta
              key={notification.url}
              url={notification.url}
              isRead={notification.isRead}
            />
          );
        })}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: 16px;
  text-align: right;
`;
const NotificationsHeader = styled(Typography)`
  text-align: start;
`;

const NotificationsWrapper = styled.div`
  max-width: 400px;
`;
const NotificationDot = styled.div`
  position: absolute;
  right: 8px;
  margin-top: 10px;
  background-color: red;
  border-radius: 20px;
  width: 10px;
  height: 10px;
`;
const NotificationWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 5px;
`;
