import React from "react";
import { Doughnut } from "react-chartjs-2";
import colorPallete, { numFormatter } from "../utilities/utils";
import { Fade, Typography } from "@material-ui/core";
import Text from "../utilities/Text";
import { useLanguage } from "../hooks";
import styled from "styled-components";

const DoughnutChartInvests = (props) => {
  const language = useLanguage();
  const invests = props.userData.user?.invests;
  let totalBalance = props?.userData.user.total_manage;
  let totalGrants = props.userData.user.total_grant;
  let totalDeposits = props.userData.user.deposits[0].amount;
  if (!props.userData) {
    return null;
  }
  if (props?.userData.user?.deposits.length < 1) {
    return null;
  }

  const yieldFromStart = props.userData.user.Cumulative_yield;
  const yieldOfThisYear =
    props.userData.user.invests.yield_year_percent[0].present;

  const labels = [
    Text({
      objectName: "foreignExchange_percents",
      page: "mainpage",
    }),
    Text({ objectName: "cash_percents", page: "mainpage" }),
    Text({ objectName: "corbonds_percents", page: "mainpage" }),
    Text({ objectName: "govbond_percents", page: "mainpage" }),
    Text({ objectName: "stock_percents", page: "mainpage" }),

    // <Text objectName="foreignExchange_percents" page="mainpage" />,
    // <Text objectName="cash_percents" page="mainpage" />,
    // <Text objectName="corbonds_percents" page="mainpage" />,
    // <Text objectName="govbond_percents" page="mainpage" />,
    // <Text objectName="stock_percents" page="mainpage" />,
  ];
  const amounts = [
    invests.ForeignExchange_percents,
    invests.cash_percents,
    invests.corbonds_percents,
    invests.govbond_percents,
    invests.stock_percents,
  ];
  if (props.userData.user?.invests) {
    // props.userData["user"]["invests"][0]?.["invest_list"].forEach((item) => {
    //     labels.push(item.category);
    //     amounts.push(item.amount);
    // });
  }

  let sum = amounts.reduce(function (a, b) {
    return a + b;
  }, 0);
  const colors = colorPallete;

  const data = {
    labels: labels,
    datasets: [
      {
        data: amounts,
        backgroundColor: colors,
        borderColor: colors,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    cutout: "85%",
    layout: {
      padding: {
        top: 50,
      },
    },
    plugins: {
      tooltip: {
        position: "nearest",
        yAlign: "center",
        xAlign: "center",
        backgroundColor: "#FFFFFF",
        bodyColor: "#161616",
        displayColors: false,
        bodyAlign: "center",
        titleAlign: "center",
        bodyFont: {
          size: 16,
          weight: "700",
          family: "Ploni",
        },
        // rtl: true,

        callbacks: {
          label: function (context) {
            return context.formattedValue + "%";
          },
          afterBody: function (context) {
            return context[0].label;
          },
        },
      },
      legend: {
        display: false,
        position: "bottom",
        labels: {
          color: "#161616",
          boxWidth: 8,
          boxHeight: 8,
          font: {
            family: "ploni",
            size: "14px",
            weight: "normal",
          },
        },
      },
    },
  };

  return (
    <Wrapper>
      <YieldWrapper>
        <div>
          <YieldHeaders>
            <Text page="mainpage" objectName="yieldFromStart"></Text>
          </YieldHeaders>
          <Yield>{yieldFromStart}%</Yield>
        </div>
        <div>
          <YieldHeaders>
            <Text page="mainpage" objectName="yieldOfThisYear"></Text>
          </YieldHeaders>
          <Yield>{yieldOfThisYear}%</Yield>
        </div>
      </YieldWrapper>
      <Doughnut data={data} options={options} />
      <LegendContainer>
        {labels.map((label, index) => {
          return (
            <LegendItem>
              <LegendItemDot
                style={{
                  backgroundColor: colors[index],
                }}
              ></LegendItemDot>
              <LegendItemText>{label}</LegendItemText>
            </LegendItem>
          );
        })}
        {labels.length % 3 !== 0 && (
          <LegendItem>
            <div></div>
          </LegendItem>
        )}
      </LegendContainer>

      <Fade in={true} timeout={1000}>
        <>
          <AmountInDoughnut>{numFormatter(totalBalance)}</AmountInDoughnut>
          <TextUnderAmountInDoughnut>
            <Text objectName="my_invests" page="mainpage" />
          </TextUnderAmountInDoughnut>
        </>
      </Fade>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 300px;
  margin: auto;
`;

const YieldWrapper = styled.div`
  display: flex;
  padding-top: 40px;
  justify-content: space-between;
  color: #004e5e;
`;
const YieldHeaders = styled.div`
  width: 130px;
  font-size: 12px;
`;
const Yield = styled.div`
  direction: ltr;
  font-size: 24px;
`;

const LegendContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
  flex-direction: column;
  justify-content: center;
  height: 90px;
`;
const LegendItem = styled.div`
  width: 150px;
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 25px;
`;

const LegendItemDot = styled.div`
  position: relative;
  width: 8px;
  height: 8px;
  top: 8px;
  inset-inline-start: -6px;
  border-radius: 2px;
`;
const LegendItemText = styled.div`
  text-align: start;
  margin-right: 12px;
  position: relative;
`;
const AmountInDoughnut = styled(Typography)`
  position: relative;
  margin: auto;
  font-family: Ploni;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  top: -310px;
`;
const TextUnderAmountInDoughnut = styled(Typography)`
  position: relative;
  margin: auto;
  font-family: Ploni;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  top: -320px;
`;

export default DoughnutChartInvests;
