import React from "react";
import Carousel from "react-material-ui-carousel";
import { useHistory } from "react-router-dom";

export default function GrantsCarousel(props) {
  const history = useHistory();
  const items = props.labels;

  return (
    <Carousel
      fullHeightHover={false} // We want the nav buttons wrapper to only be as big as the button element is
      autoPlay={false}
      navButtonsAlwaysVisible={true}
      cycleNavigation={true}
      navButtonsProps={{
        // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
        style: {
          backgroundColor: "#F8F8F8",
          color: "#004E5E",
        },
      }}
      indicatorIconButtonProps={{
        style: {
          display: "none",
        },
      }}
    >
      {items.map((item, i) => (
        <Item key={i} item={item} />
      ))}
    </Carousel>
  );
}

function Item(props) {
  const history = useHistory();
  return (
    <div
      onClick={() => history.push("/granthistory")}
      style={{
        backgroundColor: "#F8F8F8",
        fontSize: "12px",
        fontFamily: "Ploni",
        cursor: "pointer",
      }}
      id="fromCarouselToGrantHistory"
    >
      <p style={{ color: "#004E5E", margin: "0px", fontWeight: "500" }}>
        {props.item.name}
      </p>
      <p
        style={{
          color: "#004E5E",
          fontSize: "24px",
          margin: "0px",
          fontWeight: "550",
          direction: "rtl",
        }}
      >
        {Math.round(props.item.description).toLocaleString()} ₪{" "}
      </p>
    </div>
  );
}
