import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Button, TextField, Paper } from "@material-ui/core";
import { ReactComponent as RadioB } from "../../img/radio-checked.svg";
import ScrollFade from "../fadeBox"
import Text from "../../utilities/Text";

export default function FormStep3({ sendData }) {
  return (
    <React.Fragment>
      <Typography variant="h4" color="primary">
        <Text objectName="termsTitle" page="donation" />
      </Typography>
      <div
        id="terms-scroll"
        style={{
          //direction: "ltr",
          display: "inline-block",
          height: "150px",
          //width: "90%",
          //backgroundColor: "#FFF",
          overflowY: "scroll",
          padding: "10px",
          scrollbarColor: "#004E5E #FFFFFF",
          scrollbarWidth: "thin",
          overflowScrolling: "touch",
          WebkitOverflowScrolling: "touch",
        }}
      >
        <ScrollFade />
        <Typography
          variant="body1"
          //style={{ direction: "rtl", paddingInlineStart: "5px" }}
        >
          <Text objectName="terms" page="donation" />
        </Typography>
      </div>
      <Button
        variant="contained"
        color="primary"
        style={{ margin: "24px auto", padding: "8px 24px" }}
        onClick={() => sendData()}
      >
        <Text objectName="agreeBtn" page="donation" />
        <RadioB style={{ marginInlineStart: "auto", stroke: "none" }} />
      </Button>
    </React.Fragment>
  );
}
